import GraphInteractor from "@/graph/visualize/common/interactor/GraphInteractor";
import DataRenderer from "../renderer/DataRenderer";
import { useGraphLoader } from "@/graph/visualize/providers/GraphLoaderProvider";
import { GraphEditorProvider, useGraphEditor } from "@/graph/visualize/providers/GraphEditorProvider";
import { useEffect, useState } from "react";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import DataEditorToolbar from "./DataEditorToolbar";
import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import { buildDataNodeElement, buildNavigatorElements } from "../navigator/data.navigator";
import ChangeFab from "../../../common/commit/ChangeFab";
import { buildDataChangeProps } from "../changes/data.changes";
import { TreeElement } from "@/graph/visualize/common/navigator/TreeView";

function DataEditorImpl() {
    const { t } = useTranslation()
    useTitle(t('dataGraph.editor'))

    const { dataGraph, loadDataGraph, isLoading } = useGraphLoader()
    const { setActiveType } = useGraphDisplay()
    const [navElements, setNavElements] = useState<TreeElement[]>([])

    const { openCommit, commit } = useGraphEditor()
    const [version, setVersion] = useState<number | undefined>()
    
    useEffect(() => {
        loadDataGraph("")
        setActiveType("DATA")
        openCommit('DATA')
    }, [])
    useEffect(() => {
   
    }, [dataGraph])
    useEffect(() => {
        if (dataGraph) {
            setVersion(dataGraph!.meta.version! + 1)
        }
        if (dataGraph) {
            setNavElements(buildNavigatorElements(dataGraph, dataGraph.meta.schema!))
        }
    }, [dataGraph, commit])

    const loading =  isLoading()

    return <GraphInteractor navigatorProps={{ 
        title: t('dataGraph.label'), 
        loading,
        elements: navElements,
        onExpand: async (type: string) => {
            const nodes = await dataGraph?.nodesOfType(type) || []
            setNavElements(elems => elems.map(e => {
                if (e.id === type) {
                    e.children = nodes.map(n => buildDataNodeElement(n))
                }
                return e
            }))
        },
    }}>
        <DataEditorToolbar />
        {!loading && <DataRenderer
            includeFields={[]}
            currentQuery={""}
            schemaGraph={dataGraph?.meta.schema!}
            update
        ></DataRenderer>}
        { commit !== null ? <ChangeFab version={version!} commit={commit!} buildChangeProps={buildDataChangeProps}></ChangeFab> : undefined }
    </GraphInteractor>
}

export function DataEditor() {

    return <GraphEditorProvider>
        <DataEditorImpl></DataEditorImpl>
    </GraphEditorProvider>
}