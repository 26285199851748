export default {
    common: {
      username: 'Username',
      email: 'Email',
      password: 'Password',
      actions: 'Actions',
      login: 'Login',
      logout: 'Logout',
      name: 'Name',
      cancel: 'Cancel',
      status: 'Status',
      state: 'State',
      search: 'Search',
      discard: 'Discard',
      manage: 'Manage',
      available: 'Available',
      installed: 'Installed',
      queries: 'Queries',
      custom: 'Custom',
      security: 'Security',
      sign_in_with: 'Sign in to {{displayName}}',
      code: 'Code',
      message: 'Message',
      details: "Details"
    },
    errors: {
      cannot_find_tenant: "Please enter a valid tenant name. If you're unsure, reach out to your administrator for assistance and more information"
    },
    operator: {
      login: 'Operator Login',
    },
    dashboard: {
      label: 'Dashboard',
    },
    graph: {
      label: 'Graph',
      visualize: 'Visualize',
      history: 'History',
      commit: 'Commit',      
      state: {
        outdated: 'Outdated',
        synching: 'Synching',
        upToDate: 'Up-to-Date',
      }
    },
    authentication: {
      label: 'Authentication'
    },
    dataGraph: {
      label: 'Data Graph',
      editor: 'Data Editor',
    },
    schemaGraph: {
      label: 'Schema Graph',
      editor: 'Schema Editor',
    },
    reports: {
      label: 'Reports',
    },
    generated_reports: {
      label: 'Generated Reports'
    },
    rules: {
      label: 'Rules',
      inputs: 'Inputs',
      actions: 'Actions',
      control_flows: 'Control Flows',
      outputs: 'Outputs',
    },
    audit_events: {
      label: 'Audit Events',
      types: {
        auth: {
          login: 'User {{byUser.username}} logged in',
          login_sso: 'User {{byUser.username}} in through SSO',
        },
        integrations: {
          install_integration: 'Installed integration {{context.name}}',
        },
        issues: {
          open_issue: "Opened issue {{context.name}}",
          close_issue: "Closed issue {{context.name}}"
        }
      },
    },
    observability: {
      label: 'Observability',
    },
    alerts: {
      label: 'Alerts',
    },
    integrations: {
      label: 'Integrations',
      installed_version: 'Installed Version'
    },
    insights: {
      label: 'Insights',
    },
    data: {
      label: 'Data',
      management: 'Data Management'
    },
    schema: {
      label: 'Schema',
    },
    analytics: {
      label: 'Analytics'
    },
    buckets: {
      label: 'Buckets',
    },
    views: {
      label: 'Views',
    },
    credentials: {
      label: 'Credentials',
    },
    profile: {
      label: 'Profile',
    },
    blocks: {
      inputs: {
        data_graph: 'Data Graph',
        schema_graph: 'Schema Graph',
        audit_log: 'Audit Log'
      },
      actions: {
        filter_nodes: 'Filter Nodes',
        traversal: 'Traversal',
        transform: 'Transform',
        filter: 'Filter',
      },
      control_flows: {
        if_else: 'If/Else',
        for_each: 'For Each'
      },
      outputs: {
        generate_report: 'Generate Report',
        notify: 'Notify'
      }
    },
    settings: {
      label: 'Settings',
    },
    system: {
      label: 'System'
    },
    tracking: {
      label: 'Tracking'
    },
    tickets: {
      label: "Tickets",
    },
    scans: {
      categories: {
        vulnerability_management: 'Vulnerability Management',
        asset_management: 'Asset Management',
        risk_assessment: 'Risk Assessment',
        compliance: 'Compliance',
        identity_and_access_management: "Identity and Access Management",
        data_protection: 'Data Protection',
        platform_security: 'Platform Security',
        application_security: 'Application Security',
        data_quality: 'Data Quality',
      },
    },
    'System': 'System',
    'Users': 'Users',
    'Tenant': 'Tenant',
    'Me': 'Me',
    'Not a customer yet?': 'Not a customer yet?',
    'Contact us': 'Contact us',
    'Get in touch': 'Get in touch with one of our specialists for more information about our services.',
    'Incorrect username or password': 'Incorrect username or password',
    'Role': 'Role',
    'SYS_ADMIN': 'Sys Admin',
    'ADMIN': 'Admin',
    'USER': 'User',
    'READ_ONLY': 'Read-only',
    'No reports yet': 'No reports yet',
    "You don't have any client credentials": "You don't have any client credentials",
    'Enter your gremlin query here': 'Enter your gremlin query here',
    'Upload a graph': 'Upload a graph',
    'Upload graph': 'Upload graph',
    'Cancel': 'Cancel',
    'Entire Graph': 'Entire Graph',
    'Execute': 'Execute',
    'Successfully uploaded graph!': 'Successfully uploaded graph!',
    'Unable to upload graph.': 'Unable to upload graph.',
    'No tenants were found': 'No tenants were found',
    'No users were found': 'No users were found',
}