import { DataEdge, DataNode, SchemaEntity, SchemaGraph, SchemaRelationship } from "@/libs/client"
import { ElementDefinition } from "cytoscape";
import { LIGHT_PRIMARY_COLOR, MAX_NODE_SIZE, MIN_SIZE_NODE, PRIMARY_COLOR } from "../config/data.constants";
import { groupBy } from "@/libs/utils/arrays";
import LazyDataGraph from "../lazy/LazyDataGraph";

export type ReRenderInst = {
    addedNodes: RenderedNode[]
    removedNodeIds: string[]
    addedEdges: RenderedEdge[]
    removedEdgeIds: string[]
}

export class RenderedDataGraph {
    schemaGraph: SchemaGraph;
    dataGraph: LazyDataGraph;
    private renderedNodes: Map<string, RenderedNode>;
    private expandedNodes: Set<string>
    private renderedEdges: Map<string, RenderedEdge>;
    private includeFields: string[]

    constructor(schemaGraph: SchemaGraph, dataGraph: LazyDataGraph, initialNodes: RenderedNode[]) {
        this.schemaGraph = schemaGraph;
        this.dataGraph = dataGraph;
        this.renderedNodes = new Map()
        this.expandedNodes = new Set()
        initialNodes.map(n => {
            this.renderedNodes.set(n.id, n)
        })
        this.renderedEdges = this.buildRenderedEdges()
        this.includeFields = []
    }

    nodes(): RenderedNode[] {
        return [...this.renderedNodes.values()]
    }

    edges(): RenderedEdge[] {
        return [...this.renderedEdges.values()]
    }

    remove(ids: string[]) {
        const types = new Set(ids.map(id => this.renderedNodes.get(id)).filter(n => n).map(n => n!.type));
        ids.forEach(id => this.renderedNodes.delete(id));
        types.forEach(type => {
            console.log(type)
            const nodesOfType = [...this.renderedNodes.values()].filter(n => n instanceof RenderedDataNode).filter(n => (n as RenderedDataNode).type === type)
            console.log(nodesOfType)
            if (!nodesOfType.length) {
                this.expandedNodes.delete(type)
            }
        })
        this.renderedEdges = this.buildRenderedEdges();
    }

    async expand(renderedNode: RenderedNode, selected: string[]): Promise<ReRenderInst> {
        const empty = {
            addedNodes: [],
            removedEdgeIds: [],
            addedEdges: [],
            removedNodeIds: [],
        }
        if (this.isExpanded(renderedNode)) {
            return empty
        }
        this.expandedNodes.add(renderedNode.id)
        const rnodes = groupBy(await renderedNode.expand(this.dataGraph, selected, this.includeFields), rn => rn.id)
        if (rnodes.size === 0) {
            return empty
        }
        rnodes.forEach((rn) => {
            this.renderedNodes.set(rn.id, rn)
        })
        const redges = this.buildRenderedEdges()
        const rerender = {
            addedNodes: [...rnodes.values()],
            removedNodeIds: [],
            addedEdges: this.diffAddedEdges(redges),
            removedEdgeIds: this.diffRemovedEdges(redges),
        }
        this.renderedEdges = redges;
        return rerender;
    }

    async expandEdges(edges: DataEdge[]): Promise<ReRenderInst> {
        const edgesToLoad = edges.filter(e => !this.renderedEdges.has(e.id!))
        const idsToLoad = [...new Set([...edgesToLoad.flatMap(e => [e.source?.id!, e.target?.id!])])]
        const loadedNodes = groupBy(await this.dataGraph.nodesByIds(idsToLoad), n => n?.id)
        const addedNodes = edgesToLoad
            .flatMap(e => {
                const enodes = []
                const sourceNode = loadedNodes.get(e.source?.id!)!
                const targetNode = loadedNodes.get(e.target?.id!)!
                if (!this.renderedNodes.has(sourceNode.id!)) {
                    enodes.push(new RenderedDataNode(sourceNode, this.includeFields))
                }
                if (!this.renderedNodes.has(targetNode.id!)) {
                    enodes.push(new RenderedDataNode(targetNode, this.includeFields))
                }
                return enodes;
            })
        addedNodes.forEach(n => { 
            this.renderedNodes.set(n.id, n);
            this.expandedNodes.add(n.type);
        });
        console.log(this.renderedEdges)
        const redges = this.buildRenderedEdges()
        console.log(redges)
        const inst = { addedNodes, addedEdges: this.diffAddedEdges(redges), removedEdgeIds: this.diffRemovedEdges(redges), removedNodeIds: []}
        this.renderedEdges = redges;
        return inst;
    }

    diffAddedEdges(redges: Map<string, RenderedEdge>) {
        const added: RenderedEdge[] = []
        redges.forEach(e => {
            if (!this.renderedEdges.has(e.id)) {
                added.push(e)
            }
        })
        return added
    }

    diffRemovedEdges(redges: Map<string, RenderedEdge>) {
        const removed: string[] = []
        this.renderedEdges.forEach(e => {
            if (!redges.has(e.id)) {
                removed.push(e.id)
            }
        })
        return removed
    }

    collapse(renderedNode: RenderedNode): ReRenderInst {
        const empty = {
            addedNodes: [],
            removedEdgeIds: [],
            addedEdges: [],
            removedNodeIds: [],
        }
        if (!this.isExpanded(renderedNode)) {
            return empty
        }
        this.expandedNodes.delete(renderedNode.id)
        const rnodeIds = renderedNode.collapse(this.dataGraph)
        if (rnodeIds.length === 0) {
            return empty
        }
        rnodeIds.forEach((rnId) => {
            this.renderedNodes.delete(rnId)
        })
        const redges = this.buildRenderedEdges()
        const rerender = {
            addedNodes: [],
            removedNodeIds: rnodeIds,
            addedEdges: this.diffAddedEdges(redges),
            removedEdgeIds: this.diffRemovedEdges(redges),
        }
        this.renderedEdges = redges;
        return rerender;

    }

    toggle(renderedNode: RenderedNode, selected: string[]) {
        if (this.isExpanded(renderedNode)) {
            return this.collapse(renderedNode)
        } 
        return this.expand(renderedNode, selected)
    }

    isExpanded(renderedNode: RenderedNode) {
        return this.expandedNodes.has(renderedNode.id)
    }

    isExpandedById(id: string) {
        return this.expandedNodes.has(id)
    }

    applyIncludeFields(includeFields: string[]): RenderedNode[] {
        this.includeFields = includeFields
        const changed = [...this.renderedNodes.values()].map(rn => {
            const newRn = rn.copyWithIncludeFields(includeFields)
            return [newRn, newRn.label !== rn.label]
        }).filter(rn => rn[1]).map(rn => rn[0] as RenderedNode)
        changed.forEach(c => this.renderedNodes.set(c.id, c))
        return changed
    }

    private buildRenderedEdges(): Map<string, RenderedEdge> {
        const redges = new Map<string, RenderedEdge>()
        const relationEdgeKeyBuilder = (name: string, sourceType: string, targetType: string) => `${sourceType}:${name}:${targetType}`;
        const edgeCount = this.dataGraph.meta.stats.edgeStats
        const overlapChecker = this.buildOverlapsChecker()
        const seenEdges = new Set<string>()
        this.dataGraph.loadedEdges()!
            .map(e => new RenderedDataEdge(e, overlapChecker(e)))
            .filter(e => this.renderedNodes.has(e.sourceId) && this.renderedNodes.has(e.targetId))
            .forEach(e => {
                seenEdges.add(relationEdgeKeyBuilder(e.name, e.edge.source?.type!, e.edge.target?.type!))
                redges.set(e.id, e)
            })
        const unseenEdgeCount = {
            ...edgeCount,
        }
        seenEdges.forEach(se => {
            unseenEdgeCount[se] -= 1;
        });
        Object.values(this.schemaGraph.relationships)
            .filter(e => this.renderedNodes.has(e.source!) && this.renderedNodes.has(e.target!))
            .filter(e => !this.isExpandedById(e.source!) || !this.isExpandedById(e.target!)
                || unseenEdgeCount[relationEdgeKeyBuilder(e.name!, e.source!, e.target!)]
                || !edgeCount[relationEdgeKeyBuilder(e.name!, e.source!, e.target!)])
            .forEach(r => {
                const ekey = relationEdgeKeyBuilder(r.name!, r.source!, r.target!)
                redges.set(r.type!, new RenderedSchemaEdge(r.name!, r.source!, r.target!, unseenEdgeCount[ekey] || 0, overlapChecker(r)))
            })
        return redges
    }

    private buildOverlapsChecker() {
        const buildOverlapKeys = (e: DataEdge | SchemaRelationship) => {
            if ((e as DataEdge).source?.id) {
                const source = (e as DataEdge).source;
                const target = (e as DataEdge).target;
                return [
                    [source?.type, target?.type],
                    [source?.id, target?.id],
                ]
            } else if ((e as SchemaRelationship).source) {
                return [[e.source, e.target]]
            }
            return []
        }
        const overlapSet = [...Object.values(this.schemaGraph.relationships), ...(this.dataGraph.loadedEdges() || [])].reduce((acc, cur) => {
            const keys = buildOverlapKeys(cur);
            keys.forEach(k => {
                acc.add(k.join(":"))
            })
            return acc
        }, new Set<string>())
        return (e: DataEdge | SchemaRelationship) => {
            const keys = buildOverlapKeys(e).map(k => k.reverse())
            return !!keys.map(k => k.join(":")).find(k => overlapSet?.has(k))
        }
    }

}

export interface RenderedNode {
    id: string
    type: string
    parent?: string
    label: string

    definition(): ElementDefinition
    isExpandable(): boolean
    isCollapsible(): boolean
    expand(dataGraph: LazyDataGraph, selected: string[], includeFields: string[]): Promise<RenderedNode[]>
    collapse(dataGraph: LazyDataGraph): string[]

    copyWithIncludeFields(fields: string[]): RenderedNode
}

export class RenderedTypeNode implements RenderedNode {
    id: string
    type: string
    label: string
    count: number
    private entity: SchemaEntity

    constructor(e: SchemaEntity,
        count: number) {
        this.id = e.type!;
        this.type = e.type!;
        this.count = count
        this.entity = e
        this.label = `${e.type} (${count})`
    }

    isExpandable(): boolean {
        return true
    }

    isCollapsible(): boolean {
        return true
    }

    definition(): ElementDefinition {
        const relSize = Math.min(8 + (this.count >= 1 ? Math.log(this.count) * 6 : 0), MAX_NODE_SIZE)
        return {
            data: {
                id: this.type,
                label: this.label,
                rawNode: this,
                renderedNode: this,
                count: this.count,
                color: this.count == 0 ? LIGHT_PRIMARY_COLOR : PRIMARY_COLOR,
                size: MIN_SIZE_NODE + relSize,
                type: 'grouped',
                graph: 'overview',
            }
        }
    }

    async expand(dataGraph: LazyDataGraph, selected: string[], includeFields: string[]): Promise<RenderedNode[]> {
        const selectedSet = new Set()
        selected.forEach(s => selectedSet.add(s))
        return (await dataGraph.nodesOfType(this.type))
            .filter(n => !selected.length || selectedSet.has(n.id!))
            .map(n => new RenderedDataNode(n, includeFields)) || []
    }

    collapse(dataGraph: LazyDataGraph): string[] {
        return dataGraph.loadedNodes()?.filter(n => n.type === this.type).map(n => n.id!) || []
    }

    copyWithIncludeFields(_: string[]): RenderedNode {
        return new RenderedTypeNode(this.entity, this.count)
    }
}

export class RenderedDataNode implements RenderedNode {
    id: string
    type: string
    node: DataNode
    parent: string
    label: string
    private includeTypeLabel: boolean

    constructor(node: DataNode, includeFields: string[], includeTypeLabel = false) {
        this.id = node.id!;
        this.type = node.type!;
        this.node = node;
        this.parent = node.type!;
        this.includeTypeLabel = includeTypeLabel
        this.label = this.buildLabel(includeFields)
    }

    private buildLabel(includeFields: string[]) {
        const parameters = includeFields.map(i => [i, i === 'oid' ? this.node.oid : this.node.properties[i]]).filter(pp => pp[1]).map(pp => `${pp[0]}=${pp[1]}`)
        const typeHeader = this.includeTypeLabel ? `${this.type}\n` : ''
        if (parameters.length) {
            return `${typeHeader}${this.node.display}\n` + parameters.join('\n') 
        }
        return `${typeHeader}${this.node.display!}`
    }

    isCollapsible(): boolean {
        return false
    }

    isExpandable(): boolean {
        return false
    }

    definition(): ElementDefinition {
        return {
            data: {
                id: this.node.id,
                label: this.label,
                color: "#12b886",
                rawNode: this,
                renderedNode: this,
                parent: this.parent,
                type: 'data',
            },
        }
    }

    expand(_: LazyDataGraph, _2: string[], _3: string[]): Promise<RenderedNode[]> {
        throw new Error("Unable to expand data node")
    }

    collapse(_: LazyDataGraph): string[] {
        throw new Error("Unable to collapse data node")
    }

    copyWithIncludeFields(fields: string[]): RenderedNode {
        return new RenderedDataNode(this.node, fields, this.includeTypeLabel)
    }
}

export interface RenderedEdge {
    id: string
    name: string
    sourceId: string
    targetId: string

    definition(): ElementDefinition
}

export class RenderedSchemaEdge implements RenderedEdge {
    id: string
    name: string
    sourceId: string
    targetId: string
    private count: number
    private overlaps: boolean;

    constructor(name: string, source: string, target: string, count: number, overlaps: boolean) {
        this.id = `${source}:${name}:${target}`
        this.name = name
        this.sourceId = source
        this.targetId = target
        this.count = count
        this.overlaps = overlaps;
    }

    definition(): ElementDefinition {
        return {
            data: {
                id: this.id,
                source: this.sourceId,
                target: this.targetId,
                label: `${this.name} (${this.count})`,
                renderedEdge: this,
                overlaps: this.overlaps + "",
                edgeType: !this.count ? 'relationship-empty' : 'relationship',
            },
        }
    }
}



export class RenderedDataEdge implements RenderedEdge {
    id: string
    name: string
    sourceId: string
    targetId: string

    edge: DataEdge
    private overlaps: boolean;

    constructor(edge: DataEdge, overlaps: boolean) {
        this.id = edge.id!
        this.name = edge.name!
        this.sourceId = edge.source!.id
        this.targetId = edge.target!.id
        this.edge = edge;
        this.overlaps = overlaps;
    }

    definition(): ElementDefinition {
        return {
            data: {
                id: this.edge.id,
                source: this.edge.source?.id,
                target: this.edge.target?.id,
                label: this.edge.name,
                renderedEdge: this,
                overlaps: this.overlaps + "",
                graph: 'ref',
            },
        }
    }

}