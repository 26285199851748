import SyncAnimation from "@/components/animations/SyncAnimation";
import { useContainer } from "@/components/containers/ContainerProvider";
import ChangeFab from "@/graph/visualize/common/commit/ChangeFab";
import GraphInteractor from "@/graph/visualize/common/interactor/GraphInteractor";
import { GraphDisplayProvider, useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { GraphEditorProvider, useGraphEditor } from "@/graph/visualize/providers/GraphEditorProvider";
import { buildSchemaChangeProps } from "@/graph/visualize/render/schema/changes/schema.changes";
import SchemaEditorToolbar from "@/graph/visualize/render/schema/editor/SchemaEditorToolbar";
import { buildSchemaNavigator } from "@/graph/visualize/render/schema/navigator/schema.navigator";
import SchemaRenderer from "@/graph/visualize/render/schema/renderer/SchemaRenderer";
import { IntegrationsApi, OpsIntegrationsApi, SchemaEntity, SchemaGraph, SchemaRelationship } from "@/libs/client";
import { ModifiedGraph } from "@/libs/graph/modified";
import { applyCommit } from "@/libs/graph/schema";
import { useTitle } from "@/libs/hooks/useTitle";
import { Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import classes from './IntegrationSchemaEditor.module.css';

function SchemaEditorImpl() {
    const navigate = useNavigate()
    const { id, integrationId } = useParams()
    const { t } = useTranslation()
    useTitle(t('schemaGraph.editor'))

    const { commit, loadingCommit, synching, setCommit } = useGraphEditor()
    const [schemaGraph, setSchemaGraph] = useState<SchemaGraph>()
    const { setActiveType } = useGraphDisplay()
    const [updatedSchemaGraph, setUpdatedSchemaGraph] = useState<ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship> | undefined>()
    const { openModal, closeAllModals } = useContainer()
    const fetchIntegrationSchema = async () => {
        const resp = await (new IntegrationsApi().integrationSchema(integrationId!))
        setSchemaGraph(resp.data)
    }
    const openIntegrationCommit = async () => {
        const resp = await (new OpsIntegrationsApi().openIntegrationCommit(integrationId!))
        setCommit(resp.data)
    }
    const init = async () => {
        await fetchIntegrationSchema()
        await openIntegrationCommit()
    }
    useEffect(() => {
        init()
        setActiveType("SCHEMA")
    }, [])
    useEffect(() => {
        if (schemaGraph && commit) {
            const newSchema = applyCommit(schemaGraph!, commit!)
            setUpdatedSchemaGraph(newSchema)
        }
    }, [schemaGraph, commit])
    useEffect(() => {
        if (synching) {
            openModal('sync_commit', <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <SyncAnimation height={400}></SyncAnimation>
                <h3>Cannot open a new commit since the graph is currently synching</h3>
                <Button onClick={() => {
                    closeAllModals()
                }}>Go Back</Button>
            </div>, {
                onClose: () => {
                    navigate(`/ops/catalogs/${id}/integrations`)
                }
            })
        }
    }, [synching])
    const loading = loadingCommit || !updatedSchemaGraph
    return schemaGraph && <GraphInteractor navigatorProps={{ 
        title: t('schemaGraph.label'),
        loading,
        elements: updatedSchemaGraph ? buildSchemaNavigator(updatedSchemaGraph!, undefined, true) : [],
    }}>
        <SchemaEditorToolbar version={schemaGraph.integrationVersion} commit={commit!} reloadSchemaGraph={async () => {
            //TODO
        }} backPath={`/ops/catalogs/${id}/integrations`}></SchemaEditorToolbar>
            { commit && <><SchemaRenderer
                modifiedGraph={updatedSchemaGraph}
                fullSchema={schemaGraph}
                update
            ></SchemaRenderer>
            { commit !== null ? <ChangeFab version={schemaGraph.integrationVersion!} commit={commit!} buildChangeProps={buildSchemaChangeProps}></ChangeFab> : undefined }
            </>}
    </GraphInteractor>
}

export default function IntegrationSchemaEditor() {
    return <div className={classes.graphContainer}>
        <GraphEditorProvider>
        <GraphDisplayProvider>
            <SchemaEditorImpl></SchemaEditorImpl>
        </GraphDisplayProvider>
    </GraphEditorProvider>
    </div>
}