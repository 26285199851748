import { ActionResultType } from "@/components/actions/actions"
import { useContainer } from "@/components/containers/ContainerProvider"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import MultiStepForm, { StepContentProps } from "@/components/form/MultiStepForm"
import { ReportsApi } from "@/libs/client"
import { useProject } from "@/libs/project/ProjectProvider"
import { useForm } from "@mantine/form"
import ReportFieldsForm from "./ReportFieldsForm"
import FormCheckbox from "@/components/form/FormCheckbox"
import FormSelect from "@/components/form/inputs/FormSelect"
import { Stack } from "@mantine/core"

type ReportFormProps = {
    onRefresh: () => void
}

function ReportSetupForm({ form }: StepContentProps) {
    return <form>
        <Stack gap={12}>
            <FormTextInput
                label="Name"
                required
                description="Name of your report"
                {...form.getInputProps("name")}
            ></FormTextInput>
            <FormTextInput
                label="Description"
                description="Description of your report"
                {...form.getInputProps("description")}
            ></FormTextInput>
            <FormSelect
                label="Source"
                required
                data={[{
                    label: 'Issues',
                    value: 'ISSUES'
                }]}
                description="Data source for your report"
                {...form.getInputProps("source")}
            ></FormSelect>
            <FormSelect
                label="Issue Type"
                required
                data={[{
                    label: 'Prioritized Vulnerabilities',
                    value: 'PV'
                }]}
                description="Issue type to use for the report"
                {...form.getInputProps("sourceFilter")}
            ></FormSelect>
            <FormCheckbox
                label="Share with Anyone"
                description="Generates a public URL that can be shared with anyone"
                {...form.getInputProps("shareWithAnyone")}
            ></FormCheckbox>
        </Stack>
    </form>
}

export default function ReportForm({ onRefresh } : ReportFormProps) {
    const { curProject } = useProject()
    const form = useForm({

    })
    const { closeAllModals } = useContainer()
    const onSubmit = async () => {
        const resp = await new ReportsApi().createReport(curProject.id!, { ...form.values })
        onRefresh()
        if (resp.status >= 300) {
            return { type: ActionResultType.FAILURE }
        }
        return { type: ActionResultType.SUCCESS }
    }
    return <MultiStepForm
        form={form}
        onSubmit={onSubmit}
        onClose={closeAllModals}
        steps={[
            {
                label: 'Report',
                description: 'Set Up',
                renderContent: (props) => <ReportSetupForm {...props}></ReportSetupForm>,
            },
            {
                label: 'Fields',
                description: 'Describe Fields',
                renderContent: (props) => <ReportFieldsForm {...props}></ReportFieldsForm>
            },
        ]}
    ></MultiStepForm>
}