import { Stack } from "@mantine/core";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import classes from './LoginBox.module.css';
import { LoginContext } from "@/libs/client";
import { Link, useLocation } from "react-router-dom";
import { isLocalhost } from "@/libs/utils/env";

type LoginBoxProps = {
    loginCtx: LoginContext;
}

function LoginBox({ loginCtx } : LoginBoxProps) {
    const { t } = useTranslation()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete("t")
    return <div className={classes.loginBox}>
        <Stack style={{maxWidth: 480}}>
            <h1 style={{marginBottom: 15, fontWeight: 300, fontSize: '2.1rem', textAlign: 'center', color: '#1f1f1f'}}>{t('common.sign_in_with', loginCtx.tenant || {})}</h1>
            <LoginForm loginCtx={loginCtx}></LoginForm>
            <div style={{textAlign: 'center', marginTop: '1.5rem'}}>
                <Link 
                    to={isLocalhost() ? 
                        location.pathname.replace("/login", "/login/find-tenant") + `${searchParams.size ? '?' : ''}${searchParams.toString()}`
                        :  `${window.location.protocol}//${window.location.host.split('.').slice(1).join('.')}`}
                    style={{color: '#71a5b1', textDecoration: 'none', fontSize: '0.9rem'}}>Sign in to a different tenant</Link>
            </div>
        </Stack>
    </div>
}

export default LoginBox;