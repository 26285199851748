import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { DescribeParam, InstalledIntegration, IntegrationsApi } from "@/libs/client";
import { buildParameterFormElement } from "@/libs/common/params";
import { buildApiUrl } from "@/libs/utils/apiUrl";
import { Badge, Button, Card, Checkbox, Grid, Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useMemo, useState } from "react";

type InstallIntegrationProps = {
    installed: InstalledIntegration
    onRefresh?: () => void
}

export default function UpdateInstallIntegration({ installed, onRefresh }: InstallIntegrationProps) {
    const { closeAllModals } = useContainer();
    const [parameters, setParameters] = useState<DescribeParam[]>()
    const form = useForm({
        initialValues: {
            name: installed.name,
            devMode: installed.devMode,
            allowInsecureHttps: installed.allowInsecureHttps,
            ...(Object.entries(installed.parameters).reduce((acc, cur) => {
                acc[`param_${cur[0]}`] = cur[1]
                return acc
            }, {} as { [key: string]: any}))
        },
    });
    console.log(installed.parameters)
    //TODO refetch installed

    const fetchParameters = async () => {
        const resp = await new IntegrationsApi().integrationParameters(installed.integration!.id!)
        setParameters(resp.data)
    }

    const valid = useMemo(() => {
        return form.values.name
            && !parameters?.find(p => p.required && !(form.values as any)[`param_${p.key!}`])
    }, [form.values, parameters])

    useEffect(() => {
        fetchParameters();
    }, [])

    return parameters ? <SfyForm 
        onClose={closeAllModals}
        disabled={!valid}
        onSubmit={async () => {
            const resp = await new IntegrationsApi().updateInstall(installed.id!, {
                devMode: form.values.devMode,
                parameters: Object.entries(form.values).filter(e => e[0].startsWith('param_')).reduce((acc, cur) => {
                    acc[cur[0].replace("param_", "")] = cur[1];
                    return acc;
                }, {} as { [key: string]: any})
            })
            if (onRefresh) onRefresh();
            if (resp.status === 204) {
                return { type: ActionResultType.SUCCESS }
            }
            return { type: ActionResultType.FAILURE } //TODO error
        }}
    >
        <Grid align="top">
            <Grid.Col span={7}>
            <div>
                <FormTextInput
                    label="Name"
                    disabled
                    withAsterisk
                    description="Unique name for the installation. This value should not include spaces."
                    {...form.getInputProps('name')}
                ></FormTextInput>
                {parameters.map(p => buildParameterFormElement(p, form))}
            </div>
            </Grid.Col>
            <Grid.Col span={5}>
            <div style={{marginTop: 30}}>
            <Card withBorder style={{borderRadius: 10}}>
                <Card.Section withBorder style={{textAlign: 'center', padding: 25, width: 300, background: 'white'}}>
                    <img width={110} src={buildApiUrl(`/integrations/${installed.integration!.id}/logo.svg`)}></img>
                </Card.Section>
                <Stack gap={15}>
                    <Group mt="md" mb="xs" gap={0}>
                        <Text fw={500} >{installed.integration!.label}</Text>
                        <Badge color="black" variant="default">
                            v{installed.integration!.latestVersion}
                        </Badge>
                    </Group>
                    <div style={{fontSize: '0.8rem', marginTop: -25}}>{installed.integration!.description}</div>
                    <Button variant="outline">View Docs</Button>
                </Stack>
            </Card>
            <Checkbox label="Enable Dev Mode" style={{marginTop: 20}} 
                defaultChecked={installed.devMode}
                {...form.getInputProps("devMode")}
            ></Checkbox>
            <Checkbox label="Allow Insecure HTTPs" style={{marginTop: 20}} 
                defaultChecked={installed.allowInsecureHttps}
                {...form.getInputProps("allowInsecureHttps")}
            ></Checkbox>
            </div>
            </Grid.Col>
        </Grid>
    </SfyForm> : <LoadingOverlay visible></LoadingOverlay>
}