import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormSelect from "@/components/form/inputs/FormSelect";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { Alert, Button, Group } from "@mantine/core";
import { WorkflowNodeContext, WorkflowNodeValues } from "../../descriptors/descriptor";
import { IoWarning } from "react-icons/io5";
import { ActionResultType } from "@/components/actions/actions";

function getPropertyFields(_: WorkflowNodeContext) {
    return []
}

export default function WorkflowFilterConfigure({ ctx } : {values: WorkflowNodeValues, ctx: WorkflowNodeContext}) {
    const { closeAllDrawers } = useContainer();
    console.log(ctx)
    if (!Object.entries(ctx.inputs).length) {
        return <SfyForm onClose={closeAllDrawers}>
            <Alert variant="light" color="yellow" title="Alert title" icon={<IoWarning></IoWarning>}>
                Cannot configure this block without an input.
            </Alert>
        </SfyForm>
    }
    const saveFilter = async () => {
        closeAllDrawers()
        return { type: ActionResultType.NONE }
    }
    return <SfyForm onSubmit={saveFilter} onClose={closeAllDrawers}>        
        <label>Property Filters</label>
        <Group>
            <FormSelect
                label="Field"
                data={getPropertyFields(ctx)}
            ></FormSelect>
            <FormSelect
                label="Condition"
                defaultValue={'='}
                data={[
                    { value: 'eq', label: '=' },
                    { value: 'lt', label: '<' },
                    { value: 'lte', label: '<=' },
                    { value: 'gt', label: '>' },
                    { value: 'gte', label: '>=' },
                    { value: 'contains', label: 'contains' },
                ]}
            ></FormSelect>
            <FormTextInput label="Value" asField placeholder="Value here" size="sm" styles={{input: {paddingLeft: 0, borderWidth: 0}}}></FormTextInput>
        </Group>
        <div>
            <Button fullWidth={false}>Add Property Filter</Button>
        </div>
        {false && <>
        <label>Traversal Filters</label>
        <Group>

        </Group>
        <div>
            <Button fullWidth={false}>Add Traversal Filter</Button>
        </div></>}
    </SfyForm>
}