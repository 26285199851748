import SaveGraphView from "@/graph/visualize/common/actions/SaveGraphView";
import { useGraphDisplay } from "@/graph/visualize/providers/GraphDisplayProvider";
import { useGraphLoader } from "@/graph/visualize/providers/GraphLoaderProvider";
import GraphToggle from "@/graph/visualize/common/toolbar/GraphToggle";
import GraphToolbar from "@/graph/visualize/common/toolbar/GraphToolbar";
import ViewDetailsButton from "@/graph/visualize/common/toolbar/ViewDetailsButton";
import { ActionIcon, Button } from "@mantine/core";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useContainer } from "@/components/containers/ContainerProvider";
import IntegrationsDrawer from "@/graph/visualize/integrations/IntegrationsDrawer";
import { DataDetails } from "@/graph/visualize/common/actions/view/DataDetails";
import { useProject } from "@/libs/project/ProjectProvider";
import { ExportGraphButton } from "@/graph/visualize/common/actions/ExportGraph";
import { PiWarningFill } from "react-icons/pi";
import { DataGraphWarnings, buildEdgeWarnings } from "../details/DataGraphWarnings";
import LazyDataGraph from "../lazy/LazyDataGraph";
import { GraphsApi } from "@/libs/client";

const SAVE_GRAPH_VIEW_KEY = 'save_graph_view'
function SaveViewButton() {
    const { curProject } = useProject()
    const { t } = useTranslation()
    const { currentQuery, reloadDataGraph } = useGraphLoader()
    const { openDrawer, closeDrawer } = useContainer()
    return currentQuery && <Button 
        onClick={() => openDrawer(SAVE_GRAPH_VIEW_KEY, <SaveGraphView projectId={curProject.id!} onClose={() => closeDrawer(SAVE_GRAPH_VIEW_KEY)} onRefresh={reloadDataGraph} currentQuery={currentQuery}></SaveGraphView>, { title: t('Save view') || '' })}
        styles={{label: {fontWeight: 600}}} size="xs" leftSection={<BiSave></BiSave>}>{t('Save view')}</Button>
}

type DataViewerToolbarProps = {
    dataGraph?: LazyDataGraph
}

export default function DataViewerToolbar({ dataGraph }: DataViewerToolbarProps) {
    const navigate = useNavigate()
    const { selected } = useGraphDisplay()
    const { schemaGraph, reloadDataGraph } = useGraphLoader()
    const { openDrawer, openModal } = useContainer()
    const { projectUrl, curProject } = useProject()
    const { t } = useTranslation()
    let selectedActions: ReactNode[][] = []
    if (selected?.length > 0) {
        selectedActions = [[<ViewDetailsButton
            renderDetails={() => <DataDetails selected={selected} dataGraph={dataGraph!} schemaGraph={schemaGraph!}></DataDetails>}
        ></ViewDetailsButton>]]
    }
    const edgeWarnings = useMemo(() => {
        if (!dataGraph) {
            return [];
        }
        //TODO
        return buildEdgeWarnings()
    }, [dataGraph])
    return <>
        <GraphToolbar
            lhs={[[<GraphToggle></GraphToggle>]]}
            rhs={[...selectedActions, 
                [<SaveViewButton></SaveViewButton>, 
                <ExportGraphButton></ExportGraphButton>,
                ...(edgeWarnings.length ? [<ActionIcon onClick={() => {
                    openModal('data_graph_warning', <DataGraphWarnings warnings={edgeWarnings}></DataGraphWarnings>, {
                        title: 'Warnings',
                        type: 'graph',
                    })
                }} variant="transparent" style={{marginRight: '0.5rem'}} aria-label="Warning" color="yellow">
                    <PiWarningFill></PiWarningFill>
                </ActionIcon>] : []),
            ]]}
            moreActions={[{
                label: 'View',
                items: [
                {
                    label: 'Info',
                    onClick: () => {}
                },
                {
                    label: t('integrations.label'),
                    onClick: () => openDrawer('integrations', <IntegrationsDrawer></IntegrationsDrawer>, {
                        title: t('integrations.label') || '',
                    })
                },
                {
                    label: 'History',
                    onClick: () => {}
                },
            ]}, {
                label: 'Actions',
                items: [
                    {
                        label: 'Update Schema',
                        onClick: () => navigate(projectUrl('/data/graph/schema/edit'))
                    },
                    {
                        label: 'Refresh Metadata',
                        onClick: async () => {
                            await new GraphsApi().dataGraphRefreshMeta(curProject.id!!)
                            reloadDataGraph()
                        }
                    }
                ]
            }]}
        ></GraphToolbar>
    </>
}