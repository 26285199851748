import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { Catalog, CatalogsApi } from "@/libs/client";

export default function OpsCatalogLanding() {
    const { id } = useParams()
    const [catalog, setCatalog] = useState<Catalog>()
    const fetch = async () => {
        const res = await new CatalogsApi().catalog(id!)
        if (res.status == 200) {
            setCatalog(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (catalog) {
            useTitle(`${catalog.name} - Script`)
        }
    }, [catalog])
    return catalog && <EntityPage
        category={`Catalog`}
        title={catalog.name || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: `/ops/catalogs/${id}/details`,
        }, {
            key: 'integrations',
            label: "Integrations",
            route: `/ops/catalogs/${id}/integrations`,

        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'catalogs',
                label: 'Catalogs',
                link: '/ops/catalogs',
            },
            {
                key: 'name',
                label: catalog.name!,
            }
        ]}
    ></EntityPage>
}