import { Button, Flex, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from './InfoBox.module.css';

export default function InfoBox() {
    const { t } = useTranslation()
    return <div>
            <div className={classes.overlay}></div>
            <Flex style={{marginLeft: 20}} align="flex-start" justify="center">
                <Stack style={{width: 400, zIndex: 1, padding: '80px 30px', display: 'flex', color: 'white', alignItems: 'center'}}>
                    <div style={{marginBottom: '1rem', textAlign: 'center', marginTop: 30}}>
                        <div style={{fontSize: '2rem', fontWeight: 'bold'}}>{ t('Not a customer yet?') }</div>
                        <div style={{fontSize: '1rem', color: '#f0f0f0', marginTop: 15}}>{ t('Get in touch') }</div>
                    </div>
                    <Button style={{width: '100%', maxWidth: 280, marginTop: 20, borderColor: 'white', borderRadius: 20}} bg='transparent' color="white">{ t('Contact us') }</Button>
                </Stack>
            </Flex>
        </div>
}