import { UseFormReturnType } from "@mantine/form"
import { DescribeParam, GraphsApi, IntegrationsApi } from "../client"
import { ComboboxData, MultiSelect, Textarea } from "@mantine/core"
import FormSelect from "@/components/form/inputs/FormSelect"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import FormCheckbox from "@/components/form/FormCheckbox"
import { useEffect, useState } from "react"
import { useProject } from "../project/ProjectProvider"
import { getAllElements } from "../utils/pagination"

function InstalledIntegrationsSelect({ param, form, disabled } : { param: DescribeParam, form: UseFormReturnType<any>, disabled: boolean }) {
    const [options, setOptions] = useState<ComboboxData>([])
    const { curProject } = useProject()
    const fetchInstalledIntegrations = async () => {
        const installed = await getAllElements(async page => (await new IntegrationsApi().installedIntegrations(curProject.id!, {
            page
        })).data)
        setOptions(installed.map(i => ({
            label: i.name!,
            value: i.name!,
        })))
    }
    useEffect(() => {
        fetchInstalledIntegrations()
    }, [])
    return <FormSelect
        key={param.key}
        label={param.label}
        searchable
        withAsterisk={param.required}
        description={param.description}
        data={options}
        {...form.getInputProps(`param_${param.key!}`)}
        disabled={disabled}
    ></FormSelect>
}


function EntitiesSelect({ param, form, disabled } : { param: DescribeParam, form: UseFormReturnType<any>, disabled: boolean }) {
    const [options, setOptions] = useState<ComboboxData>([])
    const { curProject } = useProject()
    const fetchEntities = async () => {
        const resp = await new GraphsApi().schemaGraph(curProject.id!)
        setOptions(Object.keys(resp.data.entities).sort().map(e => ({
            label: e,
            value: e,
        })))
    }
    useEffect(() => {
        fetchEntities()
    }, [])
    return <MultiSelect
        key={param.key}
        label={param.label}
        searchable
        withAsterisk={param.required}
        description={param.description}
        data={options}
        {...form.getInputProps(`param_${param.key!}`)}
        disabled={disabled}
    ></MultiSelect>
}

export function buildParameterFormElement(param: DescribeParam, form: UseFormReturnType<any>, disabled = false) {
    //TODO sensitive textarea
    switch(param.type) {
        case 'boolean': return <FormCheckbox style={{marginTop: 20}} key={param.key}
                        label={param.label}
                        {...form.getInputProps(`param_${param.key!}`)}
                        disabled={disabled}
                    ></FormCheckbox>
        case "textarea":
        case "privatekey":
        case "certificate":
        case "json":
            return <Textarea
                key={param.key}
                styles={{
                    root: {
                        marginTop: '0.5rem',
                    },
                    input: {
                        minHeight: 150,
                    }
                }}
                label={param.label}
                withAsterisk={param.required}
                description={param.description}
                {...form.getInputProps(`param_${param.key!}`)}
                disabled={disabled}
            ></Textarea>
        case "installed_integrations":
            return <InstalledIntegrationsSelect param={param} form={form} disabled={disabled}></InstalledIntegrationsSelect>
        case "entities":
            return <EntitiesSelect param={param} form={form} disabled={disabled}></EntitiesSelect>
        case "multiselect":
            return <MultiSelect
                key={param.key}
                label={param.label}
                withAsterisk={param.required}
                description={param.description}
                data={param.options?.map((opt: any) => ({ label: opt.label!, value: opt.value! }))}
                {...form.getInputProps(`param_${param.key!}`)}
                disabled={disabled}
            ></MultiSelect>
        default: return param.options?.length ? <FormSelect
                        key={param.key}
                        label={param.label}
                        defaultValue={param.required && !form.values[`param_${param.key!}`] ? param.options[0].value : undefined}
                        allowDeselect={!param.required}
                        withAsterisk={param.required}
                        description={param.description}
                        data={param.options.map((opt: any) => ({ label: opt.label!, value: opt.value! }))}
                        {...form.getInputProps(`param_${param.key!}`)}
                        disabled={disabled}
                    ></FormSelect> :
                    <FormTextInput
                        key={param.key}
                        label={param.label}
                        withAsterisk={param.required}
                        description={param.description}
                        sensitive={param.sensitive}
                        {...form.getInputProps(`param_${param.key!}`)}
                        disabled={disabled}
                    ></FormTextInput>
    }
}


export function checkParametersValid(parameters: DescribeParam[], form: UseFormReturnType<any>) {
    return !parameters?.find(p => p.required && !(form.values as any)[`param_${p.key!}`])
}

export function buildParameters(parameters: DescribeParam[], form: UseFormReturnType<any>)  {
    const parametersMap = parameters.reduce((acc, cur) => {
        acc[cur.key!] = cur
        return acc
    }, {} as { [key: string]: DescribeParam })
    return Object.entries(form.values).filter(e => e[0].startsWith('param_')).reduce((acc, cur) => {
        const key = cur[0].replace("param_", "");
        acc[key] = parametersMap[key]?.type === 'json' ? btoa(cur[1] as string): cur[1];
        return acc;
    }, {} as { [key: string]: any})
}

export function buildFormParameters(parameters: { [key: string]: any }) {
    return Object.entries(parameters)
        .reduce((acc, cur) => {
            acc[`param_${cur[0]}`] = cur[1]
            return acc
        }, {} as { [key: string]: any })
}