import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { DescribeParam, Integration, IntegrationsApi } from "@/libs/client";
import { buildParameterFormElement, buildParameters, checkParametersValid } from "@/libs/common/params";
import { useProject } from "@/libs/project/ProjectProvider";
import { buildApiUrl } from "@/libs/utils/apiUrl";
import { Badge, Button, Card, Checkbox, Grid, Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useMemo, useState } from "react";

type InstallIntegrationProps = {
    integration: Integration
    onRefresh?: () => void
}

export default function InstallIntegration({ integration, onRefresh }: InstallIntegrationProps) {
    const { closeAllModals } = useContainer();
    const { curProject } = useProject()
    const [parameters, setParameters] = useState<DescribeParam[]>()
    const form = useForm({
        initialValues: {
            name: integration.allowMany ? '' : integration.name,
            devMode: false,
            allowInsecureHttps: false,
        },
    });

    const fetchParameters = async () => {
        const resp = await new IntegrationsApi().integrationParameters(integration.id!)
        setParameters(resp.data)
    }

    const valid = useMemo(() => {
        return (form.values.name || !integration.allowMany)
            && checkParametersValid(parameters || [], form)
    }, [form.values, parameters])

    useEffect(() => {
        fetchParameters();
    }, [])

    return parameters ? <SfyForm 
        onClose={closeAllModals}
        disabled={!valid}
        onSubmit={async () => {
            const resp = await new IntegrationsApi().install(integration.id!, {
                ...form.values,
                name: form.values.name || '',
                projectId: curProject.id!,
                parameters: buildParameters(parameters, form),
            })
            if (onRefresh) onRefresh();
            if (resp.status === 200) {
                return { type: ActionResultType.SUCCESS }
            }
            return { type: ActionResultType.FAILURE } //TODO error
        }}
    >
        <Grid align="top">
            <Grid.Col span={7}>
            <Stack>
                <FormTextInput
                    leftSectionWidth={integration.allowMany ? ((integration.name?.length || 0) * 8.5) + 5 : undefined}
                    leftSection={integration.allowMany ? <div style={{marginLeft: 5, fontSize: '0.75rem', marginTop: 4, fontWeight: 600}}>{integration.name} /</div> : undefined}
                    label="Name"
                    withAsterisk
                    description="Unique name for the installation. This value should not include spaces."
                    disabled={!integration.allowMany}
                    {...form.getInputProps('name')}
                ></FormTextInput>
                {parameters.map(p => buildParameterFormElement(p, form))}
            </Stack>
            </Grid.Col>
            <Grid.Col span={5}>
            <div style={{marginTop: 3, width: 350}}>
            <Card withBorder style={{borderRadius: 10}}>
                <Card.Section withBorder style={{textAlign: 'center', padding: 50, background: 'white'}}>
                    <img width={110} src={buildApiUrl(`/integrations/${integration.id}/logo.svg`)}></img>
                </Card.Section>
                <Stack gap={15}>
                    <Group mt="md" mb="xs" gap={0}>
                        <Text fw={500} >{integration.label}</Text>
                        <Badge color="black" variant="default">
                            v{integration.latestVersion}
                        </Badge>
                    </Group>
                    <div style={{fontSize: '0.8rem', marginTop: -25}}>{integration.description}</div>
                    <Button variant="outline">View Docs</Button>
                </Stack>
            </Card>
            <Checkbox label="Enable Dev Mode" style={{marginTop: 20}} 
                {...form.getInputProps("devMode")}
            ></Checkbox>
            <Checkbox label="Allow Insecure HTTPs" style={{marginTop: 20}} 
                {...form.getInputProps("allowInsecureHttps")}
            ></Checkbox>
            </div>
            </Grid.Col>
        </Grid>
    </SfyForm> : <LoadingOverlay visible></LoadingOverlay>
}