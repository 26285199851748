import { useTranslation } from "react-i18next";
import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { useProject } from "@/libs/project/ProjectProvider";
import { InstalledIntegration, IntegrationsApi } from "@/libs/client";

export default function InstalledIntegrationLanding() {
    const { id } = useParams()
    const { t } = useTranslation()
    const { projectUrl } = useProject()
    const [installed, setInstalled] = useState<InstalledIntegration>()
    const fetch = async () => {
        const res = await new IntegrationsApi().installedIntegration(id!)
        if (res.status == 200) {
            setInstalled(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (installed) {
            useTitle(`${installed.integration!.label!} - Installed Integration`)
        }
    }, [installed])
    return installed && <EntityPage
        category={`Installed Integration`}
        title={installed.integration!.label || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: projectUrl(`/collection/integrations/installed/${id}/details`),
        }, {
            key: 'commits',
            label: "Commits",
            route: projectUrl(`/collection/integrations/installed/${id}/commits`),
        },
        !installed.integration?.catalog?.custom ? {
            key: 'runs',
            label: 'Runs',
            route: projectUrl(`/collection/integrations/installed/${id}/runs`),
        } : undefined, installed.integration?.usesFieldMappings ? {
            key: 'mappings',
            label: "Mappings",
            route: projectUrl(`/collection/integrations/installed/${id}/mappings`),
        } : undefined].filter(t => t) as TabData[]}
        breadcrumbs={[
            {
                key: 'integrations',
                label: t('integrations.label'),
            },
            {
                key: 'installed',
                label: t(`common.installed`),
                link: projectUrl(`/collection/integrations/installed`),
            },
            {
                key: 'name',
                label: installed.name!,
            }
        ]}
    ></EntityPage>
}