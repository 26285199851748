import { ActionIcon, Button, Divider, Group, Stack } from '@mantine/core';
import classes from './FindTenantBox.module.css';
import LoginInput from './LoginInput';
import { useEffect, useState } from 'react';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { ContextApi } from '@/libs/client';
import { useTranslation } from 'react-i18next';
import { toSvg } from 'jdenticon';
import { FaSignInAlt, FaTimes } from 'react-icons/fa';
import { getSignedInTenants, removeSignedInTenant } from '@/libs/common/tenant';
import { isLocalhost } from '@/libs/utils/env';

const goToLogin = (tenant: string, navigate: NavigateFunction, location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    if (isLocalhost()) {
        searchParams.set('t', tenant)
        navigate(location.pathname.replace("/find-tenant", "") + `${searchParams.size ? '?' : ''}${searchParams.toString()}`)
    } else {
        window.location.href = `${window.location.protocol}//${tenant}.${window.location.host}`
    }
}

function Jdenticon({ value, size } : { value: string, size: number }) {
    const [svg, setSvg] = useState('')
    useEffect(() => {
        setSvg(toSvg(value, size))
    }, [])
    return <img style={{width: size, height: size}} src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`} />
}

function PreviouslySignedRow({ name, displayName, onRefresh }: { name: string, displayName: string, onRefresh: () => void }) {
    const navigate = useNavigate()
    const location = useLocation()
    return <Group style={{position: 'relative', borderRadius: 15, border: '1px solid #f0f0f0', padding: '1rem 1rem'}}>
        <Jdenticon value={name} size={60}></Jdenticon>
        <div>{displayName}</div>
        <Button style={{position: 'absolute', right: 40}} variant='default' leftSection={<FaSignInAlt></FaSignInAlt>}
            onClick={() => goToLogin(name, navigate, location)}
        >Go to</Button>
        <ActionIcon onClick={() => { removeSignedInTenant(name); onRefresh()}} variant='subtle' size="xs" color="gray" style={{position: 'absolute', top: 8, right: 8}}>
            <FaTimes size="0.8rem" color="#d0d0d0"></FaTimes>
        </ActionIcon>
    </Group>
}

function PreviouslySigned() {
    const [signedIn, setSignedIn] = useState(getSignedInTenants())
    return !!signedIn.length && <>
        <Divider label="Previously Signed In" style={{marginTop: 15, marginBottom: 15}}></Divider>
        {signedIn.map(si => <PreviouslySignedRow name={si.tenant.name || ''} displayName={si.tenant.displayName || ''} onRefresh={() => setSignedIn(getSignedInTenants())}></PreviouslySignedRow>)}
    </>
}

export default function FindTenantBox() {
    const { t } = useTranslation()
    const [error, setError] = useState('')
    const [tenant, setTenant] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const goToTenant = async (e: any) => {
        e.preventDefault();
        const resp = await new ContextApi().tenantExists(tenant!)
        if (!resp.data.exists) {
            document.getElementsByTagName("input")[0].focus()
            setError(t('errors.cannot_find_tenant') || '')
            return
        }
        goToLogin(tenant, navigate, location)
    }
    return <form className={classes.multiTenantBox} onSubmit={goToTenant}>
        <Stack style={{maxWidth: 480}}>
            <h1 style={{marginBottom: 0, fontWeight: 300, fontSize: '2.1rem', textAlign: 'center', color: '#1f1f1f'}}>
                Find your Tenant
            </h1>
            <div style={{marginTop: -10, marginBottom: 10, color: '#c0c0c0', textAlign: 'center', fontSize: '1rem'}}>
                This will direct you to the login <br />page dedicated specifically for your tenant.
            </div>
            <Stack gap={30}>
                <LoginInput
                    label="Enter your tenant's name"
                    value={tenant}
                    onChange={(e: any) => {
                        setTenant(e.currentTarget.value);
                        setError('');
                    }}
                    error={error}
                    onClick={() => setError('')}
                ></LoginInput>
                <Button type="submit" disabled={!tenant}>Continue</Button>
            </Stack>
            <div style={{fontSize: '0.9rem', textAlign: 'center', color: '#a0a0a0', marginTop: 5}}>
                Don't have a tenant yet? <a href="#" style={{color: '#71a5b1', textDecoration: 'none'}}>Contact us</a>
            </div>
            <PreviouslySigned></PreviouslySigned>
        </Stack>
    </form>
}