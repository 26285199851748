export default {
    common: {
      username: "Nom d'utilisateur",
      password: 'Mot de passe',  
      action: 'Actions',
      login: 'Se connecter',
      logout: 'Se déconnecter',
      name: 'Nom',
      cancel: 'Annuler',
      status: 'Status',
      search: 'Recherche',
      discard: 'Défausser',
      manage: 'Gestion',
      disponible: 'Disponible',
      installed: 'Installée',
      queries: 'Requêtes',
      custom: 'Personnalisé',
      security: 'Sécurité',
      code: 'Code',
      message: 'Message',
    },
    operator: {
      login: 'Connexion opérateur',
    },
    graph: {
      label: 'Graphe',
      visualization: 'Visualiser',
      history: 'Historique',
      commit: 'Commit',
      state: {
        outdated: 'En retard',
        synching: 'Synchronisation',
        upToDate: 'À jour',
      }
    },
    authentication: {
      label: 'Authentication'
    },
    dataGraph: {
      label: 'Graphe de Données',
      editor: 'Éditeur de Données',
    },
    schemaGraph: {
      label: 'Graphe de Schéma',
      editor: 'Éditeur de Schéma',
    },
    reports: {
      label: 'Rapport',
    },
    rules: {
      label: 'Règles',
      inputs: 'Entrées',
      decisions: 'Décisions',
      output: 'Sorties',
    },
    audit_events: {
      label: "Journal d'audit",
      types: {
        auth: {
          login: 'Utilisateur {{byUser.username}} connecté',
          login_sso: 'Utilisateur {{byUser.username}} connecté via SSO',
        },
        integrations: {
          install_integration: 'Intégration installée {{context.name}}',
        },
        issues: {
          open_issue: "Problème ouvert {{context.name}}",
          close_issue: "Problème fermer {{context.name}}"
        }
      },
    },
    observability: {
      label: 'Observabilité',
    },
    alerts: {
      label: 'Alertes',
    },
    integrations: {
      label: 'Intégrations',
    },
    data: {
      label: 'Données',
      management: 'Gestion des Données'
    },
    schema: {
      label: 'Schéma',
    },
    analytics: {
      label: 'Analytiques',
    },
    buckets: {
      label: 'Bac de Données',
    },
    views: {
      label: 'Vues',
    },
    credentials: {
      label: 'Identifiants',
    },
    profile: {
      label: 'Profile',
    },
    tracking: {
      label: 'Traquer'
    },
    tickets: {
      label: "Tickets",
    },
    scans: {
      categories : {
        vulnerability_management : 'Gestion des vulnérabilités',
        asset_management : 'Gestion des actifs',
        risk_assessment : 'Évaluation des risques',
        compliance : 'Conformité',
        identity_and_access_management : "Gestion des identités et des accès",
        data_protection : 'Protection des données',
        platform_security : 'Sécurité de la plateforme',
        application_security : 'Sécurité des applications',
        data_quality: 'Qualité des données',
      },
    },
    'System': 'Système',
    'Users': 'Utilisateurs',
    'Tenant': 'Organisation',
    'Moi': 'Moi',
    'Not a customer yet?': 'Pas encore un client?',
    'Contact Us': 'Contactez-nous',
    'Get in touch': "Contactez l'un de nos spécialistes pour plus d'informations sur nos services.",
    'Incorrect username or password': 'Identifiant ou mot de passe incorrect',
    'Role': 'Role',
    'SYS_ADMIN': 'Administrateur système',
    'ADMIN': 'Administrateur',
    'USER': 'Utilisateur',
    'READ_ONLY': 'Lecture-seulement',
    'No reports yet': 'Aucun rapport disponible',
    "You don't have any client credentials": "Vous n'avez pas d'identification client",
    'Enter your gremlin query here': 'Entrez votre requête gremlin ici',
    'Upload a graph': 'Téléverser un graph',
    'Upload graph': 'Téléverser le graph',
    'Cancel': 'Annuler',
    'Entire Graph': 'Graph Entier',
    'Execute': 'Executer',
    'Successfully uploaded graph!': 'Graph téléversé avec succès!',
    'Unable to upload graph.': 'Impossible de téléverser le graph.',
    'No tenants were found': "Aucun organisation n'a été trouvé",
    'No users were found': "Aucun utilisateur n'a été trouvé",
}