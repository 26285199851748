import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { useProject } from "@/libs/project/ProjectProvider";
import { Ticket, TicketsApi } from "@/libs/client";

export default function TicketLanding() {
    const { id } = useParams()
    const { projectUrl } = useProject()
    const [ticket, setTicket] = useState<Ticket>()
    const fetch = async () => {
        const res = await new TicketsApi().ticket(id!)
        if (res.status == 200) {
            setTicket(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (ticket) {
            useTitle(`${ticket.title} - Ticket`)
        }
    }, [ticket])
    return ticket && <EntityPage
        category={`Report`}
        title={`Ticket #${ticket.ticketNumber}`|| ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: projectUrl(`/insights/tickets/${id}/details`),
        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'tickets',
                label: 'Tickets',
                link: projectUrl('/insights/tickets'),
            },
            {
                key: 'name',
                label: `#${ticket.ticketNumber!}`,
            }
        ]}
    ></EntityPage>
}