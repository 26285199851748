import { useContainer } from "@/components/containers/ContainerProvider";
import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { Report, ReportsApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import notify from "@/libs/notify/notify";
import { useProject } from "@/libs/project/ProjectProvider";
import { Badge, Group } from "@mantine/core";
import moment from "moment";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ReportForm from "./actions/ReportForm";
import { useInfo } from "@/libs/info/InfoProvider";

export default function ReportsLanding() {
    const { isExperimental } = useInfo()
    const { t } = useTranslation()
    const { curProject, projectUrl } = useProject()
    const { openModal } = useContainer()
    const navigate = useNavigate()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("reports.label"))
    const headers = [
        t('common.name'),
        'Last Generated Date'
    ];
    return <EntityPage
        category={t('analytics.label')}
        title={t('reports.label')}>
        <ListSection<Report>
        callbacks={listRef}
        headers={headers}
        renderRow={(e) => ({
            cols: [{
                display: [{
                    elem: <Group gap={5}><div>{e.name}</div> <Badge size="sm" color="purple">CSV</Badge></Group> ,
                }],
            }, {
                display: [{
                    label: e.lastGeneratedDate ? moment(e.lastGeneratedDate).fromNow() : '',
                }]
            }],
        })}
        load={async (page) => {
            const resp = await new ReportsApi().reports(curProject.id!, page);
            if (resp.status === 200) {
                return resp.data;
            }
            return { error: 'TODO' };
        }}
        topActions={isExperimental() ? [{
            label: 'Add Report',
            icon: <FaPlus></FaPlus>,
            execute: async () => {
                openModal('add_reports', <ReportForm onRefresh={() => listRef.current?.refresh()}></ReportForm>, {
                    title: t('Add Report') || ''
                })
            }
        }] : []}
        rowActions={r => [{
            label: 'Delete All',
            icon: <FaTrash></FaTrash>,
            execute: async () => {
                await new ReportsApi().deleteReport(r.id!)
                listRef.current?.refresh()
                notify.success("Successfully deleted report " + r.name)
            }
        }]}
        onRowClick={r => navigate(projectUrl(`/insights/reports/${r.id}`))}
        emptyLabel={t('No reports yet')}
    ></ListSection>
    </EntityPage>
}