import { useTitle } from "@/libs/hooks/useTitle";
import EntityPage from "@/components/page/EntityPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabData } from "@/components/page/EntityTabs";
import { useProject } from "@/libs/project/ProjectProvider";
import { Script, ScriptsApi } from "@/libs/client";

export default function ScriptLanding() {
    const { id } = useParams()
    const { projectUrl } = useProject()
    const [script, setScript] = useState<Script>()
    const fetch = async () => {
        const res = await new ScriptsApi().script(id!)
        if (res.status == 200) {
            setScript(res.data);
        } else {
            //TODO
        }
    }
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {
        if (script) {
            useTitle(`${script.name} - Script`)
        }
    }, [script])
    return script && <EntityPage
        category={`Script`}
        title={script.name || ''}
        tabs={[{
            key: 'details',
            label: "Details",
            route: projectUrl(`/analysis/scripts/${id}/details`),
        }] as TabData[]}
        breadcrumbs={[
            {
                key: 'scripts',
                label: 'Scripts',
                link: projectUrl('/analysis/scripts'),
            },
            {
                key: 'name',
                label: script.name!,
            }
        ]}
    ></EntityPage>
}