import { TypeOptions, toast } from "react-toastify"

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARN = 'warning',
    INFO = 'info',
}

type Notification = {
    type: NotificationType
} & NotificationOpts

type NotificationOpts = {
    message: string,
}

function post({ message, type }: Notification) {
    console.log(type.toLocaleString().toLowerCase() as TypeOptions)
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        type: type.toLocaleString() as TypeOptions,
    })
}

function internalNotify(opts: NotificationOpts | string, type: NotificationType) {
    if ((opts as NotificationOpts).message) {
        return post({
            ...(opts as NotificationOpts),
            type,
        })
    }
    return post({
        message: opts as string,
        type,
    })
}

function info(opts: NotificationOpts | string) {
    internalNotify(opts, NotificationType.INFO)
}

function success(opts: NotificationOpts | string) {
    internalNotify(opts, NotificationType.SUCCESS)
}

function error(opts: NotificationOpts | string) {
    internalNotify(opts, NotificationType.ERROR)
}

function warn(opts: NotificationOpts | string) {
    internalNotify(opts, NotificationType.WARN)
}

export default {
    info,
    success,
    error,
    warn,
}