import { Divider, Menu, UnstyledButton } from '@mantine/core';
import { ReactNode, forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { useProject } from '@/libs/project/ProjectProvider';
import { useAuth } from '@/libs/auth/AuthProvider';
import classes from './HeaderTabs.module.css';
import cx from 'clsx';
import { useInfo } from '@/libs/info/InfoProvider';

type TabProps = {
    label: string
    route: string
    menu?: boolean
    menuItem?: boolean
}

const Tab = forwardRef<HTMLButtonElement, TabProps>(({label, route, menu, menuItem, ...others}: TabProps, ref) => {
	const navigate = useNavigate()
    const location = useLocation()
    const active = location.pathname.startsWith(route)
    const buttonOpts = {
        ...others,
        ...(!menu ? { onClick: () => navigate(route)  } : null)
    }
    if (menuItem) {
        return <Menu.Item className={active ? classes.activeMenuItem : undefined} onClick={() => navigate(route)}>{label}</Menu.Item>
    }
    return <UnstyledButton ref={ref} {...buttonOpts} className={cx(classes.tab, active ? classes.activeTab : classes.inactiveTab)}>
        <div>{label}</div>
        {menu && <FaChevronDown size={8} style={{marginLeft: 5}}></FaChevronDown>}
    </UnstyledButton>
});


function OperatorHeaderTabs() {
    const { isSaas } = useInfo()
    const { t } = useTranslation()
    return <>
        {false && <Tab route="/ops/dashboard" label={t("Dashboard")}></Tab> }
        <Tab route="/ops/tenants" label={t("Tenants")}></Tab>
        { isSaas() ? <Tab route="/ops/deployments" label={t("Deployments")}></Tab> : <></> }
        <Tab route="/ops/catalogs" label={t("Catalogs")}></Tab>
        <Tab route="/ops/settings" label={t("Settings")}></Tab>
    </>
}

function TabWithMenu({ children }: { children: ReactNode }) {
    return <Menu offset={{ mainAxis: 4, crossAxis: 50 }} position="bottom"
        styles={{ itemLabel: { fontSize: '0.9rem' }}}>
        { children }
    </Menu>
}

function TabMenuDropdown({ children }: { children: ReactNode }) {
    return <Menu.Dropdown style={{paddingLeft: 10, paddingRight: 10, minWidth: 200}}>
        {children}
    </Menu.Dropdown>
}

function RegularHeaderTabs() {
    const { isExperimental } = useInfo()
    const { projectUrl, projects } = useProject()
    const { t } = useTranslation()
    return <>
        { projects.length ? <>
        <Tab route={projectUrl("/dashboard")} label={t("dashboard.label")}></Tab>
        <TabWithMenu>
            <Menu.Target>
                <Tab route={projectUrl("/data/graph")} label={t("data.label")} menu></Tab>
            </Menu.Target>
            <TabMenuDropdown>
                <Menu.Label style={{fontSize: '0.7rem'}}>{ t("graph.visualize") }</Menu.Label>
                <Tab route={projectUrl("/data/graph")} label={t("graph.label")} menuItem></Tab>
                <Tab route={projectUrl("/data/commits")} label={t("graph.history")} menuItem></Tab>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("common.queries") }</Menu.Label>
                <Tab route={projectUrl("/data/views")} label={t("views.label")} menuItem></Tab>
            </TabMenuDropdown>
        </TabWithMenu>
        <TabWithMenu>
            <Menu.Target>
                <Tab route={projectUrl("/collection")} label={"Collection"} menu></Tab>
            </Menu.Target>
            <TabMenuDropdown>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("Automated") }</Menu.Label>
                <Tab route={projectUrl("/collection/integrations")} label={t("integrations.label")} menuItem></Tab>
                {isExperimental() && <><Menu.Label  style={{fontSize: '0.7rem'}}>{ t("Manual") }</Menu.Label>
                <Tab route={projectUrl("/collection/campaigns")} label={'Campaigns'} menuItem></Tab></>}
            </TabMenuDropdown>
        </TabWithMenu>
        <TabWithMenu>
            <Menu.Target>
                <Tab route={projectUrl("/analysis")} label={"Analysis"} menu></Tab>
            </Menu.Target>
            <TabMenuDropdown>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("Scans") }</Menu.Label>
                <Tab route={projectUrl("/analysis/scans")} label={'Scans'} menuItem></Tab>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("Automation") }</Menu.Label>
                {isExperimental() && <Tab route={projectUrl("/analysis/workflows")} label={'Workflows'} menuItem></Tab>}
                <Tab route={projectUrl("/analysis/scripts")} label={"Scripts"} menuItem></Tab>
            </TabMenuDropdown>
        </TabWithMenu>
        <TabWithMenu>
            <Menu.Target>
                <Tab route={projectUrl("/insights")} label={t("insights.label")} menu></Tab>
            </Menu.Target>
            <TabMenuDropdown>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("analytics.label") }</Menu.Label>
                <Tab route={projectUrl("/insights/reports")} label={t("reports.label")} menuItem></Tab>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("tracking.label") }</Menu.Label>
                <Tab route={projectUrl("/insights/issues")} label={"Issues"} menuItem></Tab>
                <Tab route={projectUrl("/insights/tickets")} label={t("tickets.label")} menuItem></Tab>
            </TabMenuDropdown>
        </TabWithMenu>
        <Divider style={{ opacity: 0.3, marginTop: 5, marginBottom: 5}} color="white" orientation="vertical"></Divider>
        <TabWithMenu>
            <Menu.Target>
                <Tab route="/admin" label={t("Admin")} menu={true}></Tab>
            </Menu.Target>
            <TabMenuDropdown>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("common.manage") }</Menu.Label>
                <Tab route="/admin/users" label={t("Users")} menuItem></Tab>
                <Tab route="/admin/projects" label={t("Projects")} menuItem></Tab>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("common.security") }</Menu.Label>
                <Tab route="/admin/authentication" label={t("authentication.label")} menuItem></Tab>
                <Menu.Label  style={{fontSize: '0.7rem'}}>{ t("system.label") }</Menu.Label>
                <Tab route={"/admin/audit_events"} label={t("audit_events.label")} menuItem></Tab>
                { isExperimental() && <Tab route="/admin/settings" label={t("settings.label")} menuItem></Tab> }
            </TabMenuDropdown>
        </TabWithMenu>
        </> : <></>}
    </>
}

export default function HeaderTabs() {
    const { isOperator } = useAuth()
    return <div className={classes.tabs}>
        { isOperator() ? <OperatorHeaderTabs /> : <RegularHeaderTabs />}
    </div>
}