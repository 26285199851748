import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection"
import { DisplayElemSize } from "@/components/table/EntityTable"
import { CatalogsApi, Integration, IntegrationsApi } from "@/libs/client"
import { useTitle } from "@/libs/hooks/useTitle"
import { useProject } from "@/libs/project/ProjectProvider"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
//import { useNavigate } from "react-router-dom"
import { GrInstallOption } from "react-icons/gr"
import { PiPackageDuotone } from "react-icons/pi"
import { Badge, Group } from "@mantine/core"
import { FaCheckCircle } from "react-icons/fa"
import { buildApiUrl } from "@/libs/utils/apiUrl"
import { useContainer } from "@/components/containers/ContainerProvider"
import InstallIntegration from "./actions/InstallIntegration"

export default function IntegrationsList({ catalogId } : { catalogId?: string })  {
    const { openModal } = useContainer()
    //const navigate = useNavigate()
    const { t } = useTranslation()
    const { curProject } = useProject()

    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("Integrations"))
    const headers = [
        t('common.name'),
        'Latest Version',
    ];
    return <ListSection<Integration>
            callbacks={listRef}
            headers={headers}
            renderRow={(e) => ({
                cols: [{
                    display: [{
                        elem:<Group gap={6}>
                            <div>{e.label || ''}</div>
                            <Group gap={3}>
                                {e.installed ? <Badge size="xs" color="green" leftSection={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FaCheckCircle></FaCheckCircle></div>}>Installed</Badge> : <></>}
                            </Group>
                        </Group>,                    
                    },
                    {
                        label: e.description || '',
                        size: DisplayElemSize.xs,
                        secondary: true,
                    }],
                },{
                    display: [{
                        label: e.latestVersion || '',
                        size: DisplayElemSize.lg,
                    }],
                } ],
            })}
            load={async (page: number) => {  
                const resp =  catalogId ? await new CatalogsApi().catalogIntegrations(catalogId, page) 
                    : await new IntegrationsApi().integrations(curProject.id!, page);
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            emptyLabel={t('No integrations yet')}
            //onRowClick={b => navigate(projectUrl(`/data/integrations/${b.id}`))}
            rowActions={(e) => {
                if (catalogId) {
                    return []
                }
                if (e.installed && !e.allowMany) {
                    return []
                }
                return [{
                    label: 'Install',
                    icon: <GrInstallOption></GrInstallOption>,
                    execute: () => openModal('install_integration', <InstallIntegration integration={e} onRefresh={() => listRef.current?.refresh()}></InstallIntegration>, {
                        title: t('Install Integration') || ''
                    })
                },
            ]}}
            logo={{
                getUrl: (e) => buildApiUrl(`/integrations/${e.id}/logo.svg`),
                width: 35,
                maxHeight: 35,
                headerIcon: <PiPackageDuotone></PiPackageDuotone>
            }}
        ></ListSection>
}