import { DataNode, GraphsApi, Issue, IssuesApi } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { sortBy } from "@/libs/utils/sort";
import { Badge, Grid, GridCol, Group, Stack, Table } from "@mantine/core";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import classes from './VulnerabilityPrioritizationBoard.module.css';
import DashboardCard from "../DashboardCard";

const PRIORITY_COLORS = ["#e65a0a", "#ffd500", "#2bcd69", "#2a86bc", "#50bfd8"]
const PRIORITIES = ["p1", "p2", "p3", "p4", "p5"]
const getPriorityColor = (p: string) => PRIORITY_COLORS[PRIORITIES.indexOf(p)]!

function VulnerabilityCountCard({ priority, size, count }: { count: number, priority: string, size: 'sm' | 'lg' }) {
    const color = getPriorityColor(priority)
    return <DashboardCard title={<><Badge color={color} style={{marginTop: -4}}>{priority}</Badge> Vulnerabilities</>}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <div style={{fontWeight: 300, fontSize: size === 'sm' ? '2.5rem' : '6rem'}}>
                {count}
            </div>
        </div>
    </DashboardCard>
}

type PERIOD_CHOICE = 'last_3_months' | 'last_30_days';
type PRIORITY_CHOICE = 'all' | 'p1' | 'p2' | 'p3' | 'p4' | 'p5';

function VulnerabiltyLineGraphCard({ vulnerabilities }: { vulnerabilities: Issue[]}) {
    //TODO handle closed
    const [priority, setPriority] = useState<PRIORITY_CHOICE>('all')
    const priorities: PRIORITY_CHOICE[] = ['all', 'p1', 'p2', 'p3', 'p4', 'p5']
    const [period, setPeriod] = useState<PERIOD_CHOICE>('last_3_months')
    const periods: PERIOD_CHOICE[] = ['last_3_months', 'last_30_days']
    const periodCtx = {
        'last_3_months': {
            count: 3,
            period: 'MONTHS'
        }, 
        'last_30_days': {
            count: 30,
            period: 'DAYS'
        }
    }
    const nextPeriod = () => setPeriod(periods[(periods.indexOf(period) + 1) % periods.length])
    const nextPriority = () => setPriority(priorities[(priorities.indexOf(priority) + 1) % priorities.length])

    const countPerPeriod = useMemo(() => {
        const fvuls = vulnerabilities.filter(v => priority === 'all' || (v.context || {})["priority"]?.toString() === priority)
        if (period == 'last_30_days') {
            console.log([...[...Array(30).keys()]
            .reverse()
            .map(p => moment().subtract(p, 'days'))
            .map(p => ({ inc: 0, day: p.date(), created: p.toDate()})), 
            ...fvuls.map(v => ({ inc: 1, day: moment(v.createdDate!).date(), created: moment(v.createdDate).toDate()}))].sort(sortBy(d => d.created)))
            return [...[...Array(30).keys()]
                .reverse()
                .map(p => moment().subtract(p, 'days'))
                .map(p => ({ inc: 0, day: p.date(), created: p.toDate()})), 
                ...fvuls.map(v => ({ inc: 1, day: moment(v.createdDate!).date(), created: moment(v.createdDate).toDate()}))].sort(sortBy(d => d.created)).reduce((acc, cur) => {
                    acc[cur.day] = acc[cur.day] || acc[moment(cur.created).subtract(1, 'days').date()] || 0
                    acc[cur.day] += cur.inc
                    return acc
                }, {} as { [key: string]: number})
        }
        return [...[2, 1, 0].map(p => ({inc: 0, month: moment().subtract(p, 'month').month()})), 
                ...fvuls.map(v => ({ inc: 1, month: moment(v.createdDate!).month()}))].sort(sortBy(v => v.month)).reduce((acc, cur) => {
            acc[cur.month] = acc[cur.month] || acc[cur.month - 1] || 0
            acc[cur.month] += cur.inc
            return acc
        }, {} as { [key: string]: number})
    }, [vulnerabilities, priority, period])
    const xAxis = useMemo(() => {
        if (period == 'last_30_days') {
            return [...Array(30).keys()]
                .reverse()
                .map(p => moment().subtract(p, 'days'))
                .map(p => ({ key: p.date(), label: p.format('DD') }))
        }
        return [2, 1, 0]
            .map(p => moment().subtract(p, 'month'))
            .map(p => ({ key: p.month(), label: p.format('MMM') }))
    }, [priority, period])
    return <DashboardCard title="Vunerability Over Time">
        <div style={{position: 'absolute', top: 30, right: 20, fontWeight: 700, fontSize: '0.9rem'}}>
            <Group>
                <div style={{cursor:'pointer', userSelect: 'none'}} onClick={() => nextPriority()}>{priority.toUpperCase()}</div>
                <div style={{cursor:'pointer', userSelect: 'none'}} onClick={() => nextPeriod()}>LAST <span style={{color: "#12b886"}}>{periodCtx[period].count}</span> {periodCtx[period].period}</div>
            </Group>
        </div>
        <Chart
                series={[
                    {
                      name: 'Vulnerabilities',
                      data: xAxis.map(p => countPerPeriod[p.key] || 0),
                      color: '#1f1f1f',
                    }]}
                type="line"
                options={{
                    chart: {
                      type: 'line',
                      toolbar: {
                        show: false,
                      },
                      zoom: {
                        enabled: false,
                      },
                    },
                    stroke: {
                        width: 2,
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'category',
                        categories: xAxis.map(p => p.label),
                    },
                }}    
                height={220}
                width={'100%'}
            ></Chart>
    </DashboardCard>
}

function VulerabilityAssetsListCard({ vulnerabilities } : { vulnerabilities: Issue[] }) {
    const {curProject} = useProject()
    const [nodesMap, setNodesMap] = useState<{[key: string]: DataNode}>({})
    const fetchNodes = async () => {
        const nodeIds = [...new Set(vulnerabilities.flatMap(v => [v.targetId + "", 
            ...(v.conditionOutputs?.flatMap(so => so.reasons.map(d => d.id)) || []),
        ] as string[]))]
        const resp = (await new GraphsApi().fetchData(curProject.id!, {
            nodeIds,
        })).data
        setNodesMap(resp.nodes.reduce((acc, cur) => {
            acc[cur.id!] = cur
            return acc
        }, {} as {[key: string]: DataNode}))
    }
    const assets = useMemo(() => {
        return Object.values(vulnerabilities.reduce((acc, cur) => {
            //TODO
            const priority = (cur.context || {})["priority"]?.toString()
            cur.conditionOutputs?.flatMap(so => so.reasons).forEach(r => {
                if (nodesMap[r.id!]?.type === 'Asset') {
                    if (!acc[r.id!]) {
                        acc[r.id!] = { asset: nodesMap[r.id!], priorities: [priority], date: moment(cur.createdDate).toDate() }
                    } else {
                        acc[r.id!].priorities = [...new Set([...acc[r.id!].priorities, priority])].sort()
                        acc[r.id!].date = moment(acc[r.id!].date).isAfter(moment(cur.createdDate)) ? acc[r.id!].date : moment(cur.createdDate).toDate()
                    }
                }
            })
            return acc
        }, {} as { [key: string]: { asset: any, priorities: string[], date: Date }}))
    }, [vulnerabilities, nodesMap])
    useEffect(() => {
        fetchNodes()
    }, [vulnerabilities])

    const navigate = useNavigate()
    const { projectUrl } = useProject()
    const onRowClick = (id: string) => {
        navigate(projectUrl(`/insights/issues?entities=${id}&state=OPEN`))
    }
    return <DashboardCard title="Vulnerable Assets">
        <div style={{minHeight: 150}}>
            <Table striped>
                <Table.Thead>
                    <Table.Th>
                        Asset
                    </Table.Th>
                    <Table.Th>
                        Priority Level
                    </Table.Th>
                    <Table.Th>
                        Since
                    </Table.Th>
                </Table.Thead>
                <Table.Tbody>
                    { assets.map(a => <Table.Tr className={classes.assetRow} key={a.asset.id} onClick={() => onRowClick(a.asset.id)}>
                        <Table.Td>
                            {a.asset?.display}
                        </Table.Td>
                        <Table.Td>
                            <Group gap={2}>{a.priorities.map(p => <Badge key={p} color={getPriorityColor(p)}>{p}</Badge>)}</Group>
                        </Table.Td>
                        <Table.Td>
                            {moment(a.date).fromNow()}
                        </Table.Td>
                    </Table.Tr>) }
                </Table.Tbody>
            </Table>
        </div>
    </DashboardCard>
}

function VulnerabilityPriorityChartCard({ vulnerabilities } : { vulnerabilities: Issue[] }) {
    const calculateVulCountFunc = (priority: string) => {
        return (vulnerabilities || []).filter(i => (i.context || {})["priority"]?.toString() === priority).length
    }
    const priorities = ["p1", "p2", "p3", "p4", "p5"]
    const data = priorities.map(p => calculateVulCountFunc(p))
    const hasData = data.findIndex(d => d > 0) >= 0
    return <DashboardCard title="Priority Distribution">
            { hasData ? <Chart
                series={data}
                type="donut"
                options={{
                    chart: {
                      type: 'donut',
                    },
                    labels: priorities.map(p => p.toUpperCase()),
                    dataLabels: {
                        enabled: false,
                    },                  
                    colors: PRIORITY_COLORS,
                    plotOptions: {
                        pie: {
                          donut: {           
                            size: '85%',
                          },
                        }
                      }
                }}    
                height={250}
                width={'100%'}
            ></Chart> : <div style={{fontSize: '1.5rem', color: '#c0c0c0', textAlign: 'center', marginTop: '5%'}}>N/A</div> }
    </DashboardCard>
}

export default function VulnerabilityPrioritizationBoard() {
    const { curProject } = useProject()
    const [vuls, setVuls] = useState<Issue[]>()
    const fetchVuls = async () => {
        let done = false;
        let vs: Issue[] = []
        let page = 1;
        while(!done) {
            const resp = await new IssuesApi().issues(curProject.id!, "OPEN", "PV", undefined, undefined, undefined, page, 100, undefined)
            vs = [...vs, ...(resp.data.list || [])]
            done = (page >= (resp.data.totalPages || 0))
            page += 1
        }
        setVuls(vs)
    }
    const calculateVulCountFunc = (priority: string) => () => {
        return (vuls || []).filter(i => (i.context || {})["priority"]?.toString() === priority).length
    }
    const p1Count = useMemo(calculateVulCountFunc('p1'), [vuls])
    const p2Count = useMemo(calculateVulCountFunc('p2'), [vuls])
    const p3Count = useMemo(calculateVulCountFunc('p3'), [vuls])
    useEffect(() => {
        fetchVuls()
    }, [])
    return vuls && <div>
        <div style={{maxWidth: 1400, margin: 'auto'}}>
        <Stack>
        <Grid>
            <GridCol span={6}>
                <Grid>
                    <GridCol span={6}>
                        <VulnerabilityCountCard count={p1Count} priority="p1" size="lg"></VulnerabilityCountCard>
                    </GridCol>
                    <GridCol span={6}>
                        <Stack>
                        <VulnerabilityCountCard count={p2Count} priority="p2" size="sm"></VulnerabilityCountCard>
                        <VulnerabilityCountCard count={p3Count} priority="p3" size="sm"></VulnerabilityCountCard>
                        </Stack>
                    </GridCol>
                </Grid>
            </GridCol>
            <GridCol span={6}>
                <VulnerabiltyLineGraphCard vulnerabilities={vuls}></VulnerabiltyLineGraphCard>
            </GridCol>
        </Grid>
        <Grid>
            <GridCol span={4}>
                <VulnerabilityPriorityChartCard vulnerabilities={vuls}></VulnerabilityPriorityChartCard>
            </GridCol>
            <GridCol span={8}>
                <VulerabilityAssetsListCard vulnerabilities={vuls}></VulerabilityAssetsListCard>
            </GridCol>
        </Grid>
        </Stack>
        </div>
    </div>
}