import { useContainer } from "@/components/containers/ContainerProvider";
import MultiStepForm from "@/components/form/MultiStepForm";
import MappingSetupForm from "./MappingSetupForm";
import MappingFieldsForm from "./MappingFieldsForm";
import { useEffect, useMemo, useState } from "react";
import { EdgeMapping, GraphsApi, SaveMapping, SchemaGraph, Mapping, FieldMapping, IntegrationsApi } from "@/libs/client";
import { useForm } from "@mantine/form";
import ImportSampleForm from "./ImportSampleForm";
import { deepCopy } from "@/libs/utils/obj";
import { useProject } from "@/libs/project/ProjectProvider";
import { ActionResultType } from "@/components/actions/actions";

type MappingFormProps = {
    installedId: string
    mapping?: Mapping
    update?: boolean
    custom?: boolean
    onCompleted: () => void
}

export default function MappingForm({ installedId, onCompleted, mapping, update, custom } : MappingFormProps) {
    const { closeAllModals } = useContainer();
    const [schema, setSchema] = useState<SchemaGraph>()
    const { curProject } = useProject()

    const form = useForm({
        initialValues: {
            elementType: 'NODE',
            target: '',
            source: '',
            mappedType: '',
            fileType: 'CSV',
            filePattern: '',
            
            sampleFile: '',
            fileFields: [] as string[],
            rawMappings: [] as string[][],
            edgeFields: [] as EdgeMapping[],

            sourceSelector: 'oid',
            targetSelector: 'oid',
            
            ...deepCopy(mapping),
        },
        validate: {
            filePattern: v => !v ? 'File pattern is required' : undefined,
            mappedType: v => !v ? 'Error' : undefined,
            source: (v, values) => values.elementType === 'EDGE' && !v ? 'Error' : undefined,
            target: (v, values) => values.elementType === 'EDGE' && !v ? 'Error' : undefined,
        }
    });
    useEffect(() => {
        if (!update) {
            return
        }
        if (mapping?.elementType === 'EDGE') {
            const [source, _, target] = mapping?.mappedType?.split(":")!
            form.setFieldValue('source', source);
            form.setFieldValue('target', target);    
            const fields = mapping?.edgeMappings?.flatMap(fm => [fm.sourceFileField!, fm.targetFileField!])!
            const edgeMapping = mapping?.edgeMappings![0]
            form.setFieldValue('fileFields', fields)
            form.setFieldValue('rawMappings', [["start:0", "end:source"], ["start:1", "end:target"]])
            form.setFieldValue('sourceSelector', edgeMapping.sourceSelector!)
            form.setFieldValue('targetSelector', edgeMapping.targetSelector!)
        } else {
            const fileFields: string[] = [];
            mapping?.fieldMappings?.map(fm => fm.fileField!)
                .forEach(fm => {
                    if (!fileFields.includes(fm)) {
                        fileFields.push(fm)
                    }
                })
            const rawMappings = mapping?.fieldMappings?.map(fm => [`start:${fileFields.indexOf(fm.fileField!)}`, `end:field:${fm.schemaField!}`]) || [];
            mapping?.edgeMappings?.forEach((em, idx) => {
                let field: string;
                console.log(em)
                if (em.targetFileField) {
                    field = em.targetFileField!;
                } else {
                    field = em.sourceFileField!;
                }
                if (!fileFields.includes(field)) {
                    fileFields.push(field)
                }
                rawMappings?.push([`start:${fileFields.indexOf(field)}`, `end:edge:${idx}`])
            })
            form.setFieldValue('fileFields', fileFields)
            form.setFieldValue('edgeFields', mapping?.edgeMappings || [])
            form.setFieldValue('rawMappings', rawMappings)
        }

    }, [])
    const saveMapping = useMemo(() => {
        const getFileField = (rm: string[]) => {
            if (!rm) return
            const idx = Number(rm[0].split(':')[1])
            if (!isNaN(idx)) return form.values.fileFields[idx]?.trim()
        }
        const getEntityField = (rm: string[]) => rm[1].split(':')[2]
        const getEdgeField = (rm: string[]) => {
            if (!rm) return
            const idx = Number(rm[1].split(':')[2])
            if (!isNaN(idx)) return form.values.edgeFields[idx]
        }
        const fieldMappings: FieldMapping[] = form.values.rawMappings
            .filter(rm => rm[1].startsWith('end:field:'))
            .map(rm => [getFileField(rm), getEntityField(rm)])
            .filter(rm => rm[0])
            .map(rm => ({
                fileField: rm[0]?.trim(),
                schemaField: rm[1]?.trim(),
            } as FieldMapping))
        let edgeMappings : EdgeMapping[] = []
        if (form.values.elementType === 'NODE') {
            edgeMappings = form.values.rawMappings
                .filter(rm => rm[1].startsWith('end:edge:'))
                .map(rm => [getFileField(rm), getEdgeField(rm)])
                .filter(rm => rm[0])
                .map(rm => ({
                    ...rm[1] as EdgeMapping,
                    ...((rm[1] as any)?.direction === "In" ? { //TODO
                        sourceFileField: rm[0]?.toString()?.trim(),
                    } : { 
                        targetFileField: rm[0]?.toString()?.trim(),
                    })
                } as EdgeMapping))
        } else if (form.values.elementType === 'EDGE') {
            edgeMappings = [{
                relationshipId: form.values.mappedType,
                sourceFileField: getFileField(form.values.rawMappings.find(rm => rm[1] === 'end:source')!),
                sourceSelector: form.values.sourceSelector?.trim(),
                targetFileField: getFileField(form.values.rawMappings.find(rm => rm[1] === 'end:target')!),
                targetSelector: form.values.targetSelector?.trim(),
            }]
        }
        return {
            ...form.values,
            fieldMappings,
            edgeMappings,
        } as SaveMapping
    }, [form.values])
    const allowLast = useMemo(() => {
        if (saveMapping.elementType === 'NODE') {
            return true
        } else if (saveMapping.elementType === 'EDGE') {
            const em = saveMapping.edgeMappings![0]!
            return (em.sourceFileField && em.sourceSelector)
                && (em.targetFileField && em.targetSelector);
        }
        return false
    }, [saveMapping])
    const fetchSchema = async () => {
        const resp = await new GraphsApi().schemaGraph(curProject.id!);
        if (resp.status === 200) {
            setSchema(resp.data);
        }
    }
    useEffect(() => {
        fetchSchema()
    }, [])
    return schema && <MultiStepForm
        form={form}
        onClose={closeAllModals}
        onSubmit={async () => {
            if (update) {
                await new IntegrationsApi().updateMapping(mapping!.id!, saveMapping)
            } else {
                await new IntegrationsApi().createMapping(installedId, saveMapping)
            }
            onCompleted()
            return { type: ActionResultType.SUCCESS }
        }}
        steps={[{
            label: 'Set Up',
            description: 'What and when.', 
            fields: ['elementType', 'target', 'source', 'mappedType', 'fileType', 'filePattern'],
            renderContent: (props) => <MappingSetupForm {...props} schema={schema} custom={custom === true}></MappingSetupForm>
        },
        ...(!update &&custom ? [{
            label: 'Import Sample',
            description: 'Sample File for mapping',
            fields: ['sampleFile'],
            renderContent: (props: any) => <ImportSampleForm {...props} schema={schema}></ImportSampleForm>
        }] : []),
        {
            label: 'Fields',
            description: 'Mapping definitions of fields.',
            disableNext: !allowLast,
            renderContent: (props) => <MappingFieldsForm {...props} schema={schema} saveMapping={saveMapping}></MappingFieldsForm>
        }]}
    ></MultiStepForm>
}