import { useContainer } from "@/components/containers/ContainerProvider";
import { Commit } from "@/libs/client";
import { useTranslation } from "react-i18next";
import { BiGitCommit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CommitGraphForm from "../actions/update/CommitGraphForm";
import { Button } from "@mantine/core";


type CommitButtonProps = {
    reloadGraph: () => Promise<void>
    commit: Commit;
    navigateOnSuccess: string;
}

const COMMIT_GRAPH_KEY = 'commit_graph_form'

export default function CommitButton({ reloadGraph, commit, navigateOnSuccess } : CommitButtonProps) {
    const navigate = useNavigate()
    const { openModal, closeDrawer } = useContainer()
    const { t } = useTranslation()
    return <Button 
            styles={{label: {fontWeight: 600}}} 
            size="xs" 
            leftSection={<BiGitCommit></BiGitCommit>}
            onClick={() => openModal(COMMIT_GRAPH_KEY, <CommitGraphForm 
                commit={commit} 
                onSuccess={ async () => {                     
                    await reloadGraph()
                    navigate(navigateOnSuccess)
                } }
                onClose={() => closeDrawer(COMMIT_GRAPH_KEY)}></CommitGraphForm>, { title: 'Commit Changes' })}
        >
            {t('graph.commit')}
        </Button>
}
