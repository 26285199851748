import EntityPage from "@/components/page/EntityPage";
import ListSection, { ListSectionCallbacks } from "@/components/page/sections/ListSection";
import { DisplayElemSize } from "@/components/table/EntityTable";
import { AuditEvent, AuditEventApi } from "@/libs/client";
import { useTitle } from "@/libs/hooks/useTitle";
import moment from "moment";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function AuditLanding() {
    const { t } = useTranslation()
    const listRef = useRef<ListSectionCallbacks>()
    useTitle(t("audit_events.label"))
    const headers = [
        t('Event'),
        'State',
        'User',
        'Project'
    ];
    return <EntityPage
            category={t("system.label")}
            title={t("audit_events.label")}>
            <ListSection<AuditEvent>
            callbacks={listRef}
            load={async (page) => {
                const resp = await new AuditEventApi().auditEvents(page);
                if (resp.status === 200) {
                    return resp.data;
                }
                return { error: 'TODO' };
            }}
            headers={headers}
            renderRow={(e) => ({
                cols: [{
                        display: [{
                            label: buildLabelKey(e),
                            size: DisplayElemSize.lg,
                        }, {
                            label: moment(e.createdDate).fromNow(),
                            size: DisplayElemSize.sm,
                        }], 
                    },
                    {
                        display: [{
                            label: e.state,
                            status: e.state === 'SUCCESS' ? 'green' : 'red',
                        }], 
                    },
                    {
                        display: [{
                            label: e.byUser?.username,
                        }], 
                    },
                    {
                        display: [{
                            label: e.project?.name,
                        }], 
                    }
                ],
            })}
            emptyLabel={t("No audit logs yet..")}></ListSection>
    </EntityPage>
}

function buildLabelKey(e: AuditEvent): string {
    const { t } = useTranslation()
    var label = `audit_events.types.${e.category?.toLowerCase()}.${e.type?.toLowerCase()}`;
    if (e.specifier) {
        label += `_${e.specifier.toLowerCase()}`;
    }
    const labelCtx = { ...prefixMap('context', e.context), ...prefixMap('byUser', e.byUser) }
    console.log(labelCtx)
    return t(label, labelCtx)
}

function prefixMap(prefix: string, obj: any): {[key: string]: string} {
    return Object.keys(obj)
        .reduce((acc, cur) => {
            acc[`${prefix}.${cur}`] = obj[cur]?.toString()
            return acc
        }, {} as {[key: string]: any})
}