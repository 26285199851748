import { ActionResultType } from "@/components/actions/actions";
import { useContainer } from "@/components/containers/ContainerProvider";
import SfyForm from "@/components/form/SfyForm";
import FormTextInput from "@/components/form/inputs/FormTextInput";
import { useAuth } from "@/libs/auth/AuthProvider";
import { SaveUser, UsersApi } from "@/libs/client";
import { doResponse } from "@/libs/utils/response";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";

export function AddUser({ onRefresh }: { onRefresh?: () => void }) {
    const { closeAllModals } = useContainer();
    const [mismatchError, setMismatchError] = useState('')
    const form = useForm()
    const ctx = useAuth()
    
    useEffect(() => {
        setMismatchError('')
    }, [form.values])
    return <SfyForm 
        onClose={closeAllModals}
        onSubmit={async () => {
            if (form.values.password !== form.values.confirmPassword) {
                setMismatchError("Password don't match")
                return { type: ActionResultType.FORM_ERROR }
            } else {
                return doResponse(new UsersApi().createUser({
                    ...form.values,
                    role: 'ADMIN',
                    tenantId: ctx.userCtx.user?.tenant?.id,
                } as SaveUser), onRefresh)
            }
        }}
    >
        <FormTextInput
            label="Username"
            description="Unique username for the user you want to create"
            {...form.getInputProps('username')}
        ></FormTextInput>
        <FormTextInput
            label="Password"
            description="Unique password for the user you want to create"
            type="password"
            {...form.getInputProps('password')}
            error={mismatchError}
        ></FormTextInput>
        <FormTextInput
            label="Confirm Password"
            description="Confirm the password by writing down the same one you wrote in the previous field"
            type="password"
            {...form.getInputProps('confirmPassword')}
            error={mismatchError}
        ></FormTextInput>
    </SfyForm>
}