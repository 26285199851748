import { ContainerProviderContextProps } from "@/components/containers/ContainerProvider"
import { ExpandEdges } from "../actions/ExpandEdges"
import { RenderedDataGraph } from "../renderer/rendered";
import { MutableRefObject } from "react";
import { DataEdge } from "@/libs/client";
import { buildDataLayout } from './data.layout';
import cytoscape from "cytoscape";

export function initDataContextMenu(
    cy: cytoscape.Core,
    renderedGraph: MutableRefObject<RenderedDataGraph | undefined>,
    { openModal, closeAllModals } : ContainerProviderContextProps,   
    userDefinedLayout: boolean, 
) {
     // @ts-ignore
     const instance = cy.contextMenus({
        evtType: 'cxttap',
        menuItems: [
            {
                id: 'hide', 
                content: 'Hide', 
                selector: 'node[type="data"]', 
                onClickFunction: function () { 
                    const ids = cy.nodes(":selected").map(s => s.id())
                    cy.nodes(":selected").remove()
                    renderedGraph.current!.remove(ids)
                },
            },
            {
                id: 'expand_edges', 
                content: 'Expand Edges...', 
                selector: 'node[type="data"]', 
                onClickFunction: function () { 
                    const se = cy.nodes(":selected")[0]
                    const dataGraph = renderedGraph.current!.dataGraph
                    const positionOf = (id: string) => {
                        return { ...cy.$id(id).position() }
                    }
                    const onSelect = async (edges: DataEdge[]) => {
                        const inst = await renderedGraph.current!.expandEdges(edges)
                        inst.removedEdgeIds.forEach(id => cy!.remove(`edge[id = "${id}"]`))
                        inst.addedNodes.forEach(n => cy!.add({ 
                            ...n.definition(),
                            position: positionOf(n.parent!),
                        }))
                        console.log(inst.removedEdgeIds)
                        inst.addedEdges.forEach(e => cy.add(e.definition()))
                        cy?.layout({ ...buildDataLayout(userDefinedLayout), randomize: false, fit: false }).run()  
                        closeAllModals()  
                    }
                    openModal('expand_edges', <ExpandEdges id={se.id()} dataGraph={dataGraph} onSelect={onSelect}></ExpandEdges>, {
                        title: `Expand Edges of ${se.data("label")}`,
                        type: 'graph'
                    });
                },
            },
        ]
    })
    cy.on('cxttap', (evt) => {
        cy.nodes().forEach(n => { n.unselect() })
        if (evt.target.select) {
            evt.target.select()
        }
    })
}