import { FaTrash } from 'react-icons/fa';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { useWorkflowEditor } from './WorkflowEditorProvider';
import classes from './CustomEdge.module.css';
import { WorkflowNodeIOType, WorkflowNodeInput } from './descriptors/descriptor';
import { useEffect, useState } from 'react';

function getLabelFromType(type: WorkflowNodeIOType) {
    let label;
    switch(type.type) {
        case "nodes": 
            label = "Data Node"
            break;
        case "entities":
            label = "Entity"
            break;
        default:
            const split = type.type.split(":")
            label = split[split.length - 1]
    }
    return `${label}${type.many ? '[]' : ''}`
}

function getLabelFromInput(input: WorkflowNodeInput): string {
    if (!input?.types?.length) {
        return ''
    }
    let label;
    if (input.types.length === 1) {
        label = getLabelFromType(input.types[0])
    } else {
        label = `(${input.types.map(getLabelFromType).join(", ")})`
    }
    return `${label}${input.many ? '[]' : ''}`
}

export default function CustomEdge(props: EdgeProps) {
    const { reactFlowInstance, getContextOf, changeWatcher } = useWorkflowEditor()
    const { sourceX, sourceY, targetX, targetY, selected, id } = props;
    const [targetCtx, setTargetCtx] = useState(getContextOf(props.target))
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });
    useEffect(() => {
        console.log(changeWatcher)
        setTargetCtx(getContextOf(props.target))
    }, [changeWatcher])
    const label = targetCtx.inputs[props.source] ? getLabelFromInput(targetCtx.inputs[props.source]) : 'Invalid'
    return targetCtx && <>
            <BaseEdge path={edgePath} {...props} style={{ ...props.style, stroke: label === 'Invalid' ? '#c22e2e' : undefined}} />
            <EdgeLabelRenderer>
            <div
                style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    pointerEvents: 'all',
                }}
                className="nodrag nopan"
                >
                <div style={{ fontSize: '0.8rem', color: label === 'Invalid' ? '#c22e2e' : '#8f8f8f'}}>{ label }</div>
                { selected && <div className={classes.deleteButton} onClick={() => reactFlowInstance?.deleteElements({edges: [{ id }]})}><FaTrash></FaTrash></div>}
                </div>
            </EdgeLabelRenderer>
        </>;
}