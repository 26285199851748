import { useEffect, useRef, useState } from 'react';
import cytoscape, { ElementDefinition } from 'cytoscape';
import { useGraphDisplay } from '../../../providers/GraphDisplayProvider';
import { CommitsApi, MoveEntity, Position, SchemaEntity, SchemaGraph, SchemaRelationship } from '@/libs/client';
import { getSelectedIds } from '../../cy.utils';
import { initHtmlNode } from '../config/schema.htmlNode';
import { buildSchemaCy } from '../config/schema.cy';
import { buildSchemaLayout } from '../config/schema.layout';
import { initContextMenu } from '../config/schema.ctxmenu';
import { ModifiedElem, ModifiedGraph } from '../../../../../libs/graph/modified';
import { useContainer } from '@/components/containers/ContainerProvider';
import { initEdgeEdit } from '../config/schema.edgeEdit';
import AddRelationship from '../actions/AddRelationship';
import { useGraphEditor } from '@/graph/visualize/providers/GraphEditorProvider';
import { createGrid } from '../../cy.grid';
import { ActionResultType } from '@/components/actions/actions';
import classes from './SchemaRenderer.module.css';

type SchemaRendererProps = {
    modifiedGraph?: ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship>
    update?: boolean
    fullSchema?: SchemaGraph
}

function getPrefixLabel<T>(e: ModifiedElem<T>) {
    let labelPrefix = '';
    if (e.new) {
        labelPrefix = '+'
    } else if (e.removed) {
        labelPrefix = '-'
    } else if (e.updated) {
        labelPrefix = '*'
    }
    return labelPrefix
}

function getType<T>(e: ModifiedElem<T>) {
    if (e.dim) {
        return "dim"
    } else if (e.new) {
        return "new"
    } else if(e.removed) {
        return "removed"
    } else if (e.updated) {
        return "updated"
    }
    return "reg"
}

type BiDirChecker = (r: SchemaRelationship) => boolean

function buildBiDirChecker(modifiedGraph?: ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship>): BiDirChecker {
    const buildEdgeId = (source?: string, target?: string) => `${source}:${target}`
    const edgeIds = new Set(modifiedGraph?.edges.map(e => buildEdgeId(e.source, e.target)) || [])
    return r => edgeIds.has(buildEdgeId(r.target, r.source))
}

function buildEntityElement(e: ModifiedElem<SchemaEntity>, fromFullSchema: boolean) : ElementDefinition {
    return {
        data: {
            id: e.type,
            label: getPrefixLabel(e) + e.type,
            description: e.description || 'No description available',
            rawEntity: e,
            new: e.new,
            removed: e.removed,
            updated: e.updated,
            fieldCount: Object.values(e.fields || {}).length || 0,
            type: fromFullSchema ? "dim" : getType(e),
            fromFullSchema: fromFullSchema
        },
    }
}

function buildRelationshipElement(r: ModifiedElem<SchemaRelationship>, biDirChecker: BiDirChecker, fromFullSchema: boolean) : ElementDefinition {
    return {
        data: {
            id: r.type,
            source: r.source,
            target: r.target,
            label: getPrefixLabel(r) + r.name,
            rawRelationship: r,
            bi: biDirChecker(r) + "",
            new: r.new,
            removed: r.removed,
            updated: r.updated,
            type: fromFullSchema ? "dim" : getType(r),
            fromFullSchema: fromFullSchema
        },
    }
}

function buildDisplayElements(fullSchema?: SchemaGraph, modifiedGraph?: ModifiedGraph<SchemaGraph, SchemaEntity, SchemaRelationship>): ElementDefinition[] {
    const entityElements = modifiedGraph?.nodes.map(e => {
        return buildEntityElement(e, false)
    }) || []
    const addedEntityIds = new Set([...entityElements.map(e => e.data.id)])
    const fullEntityElements = Object.entries(fullSchema?.entities || {})
        .filter(e => !addedEntityIds.has(e[0]))
        .map(e => buildEntityElement(e[1], true))
    const biDirChecker = buildBiDirChecker(modifiedGraph)
    const relationshipElements = modifiedGraph?.edges.map(r => {
        return buildRelationshipElement(r, biDirChecker, false)
    }) || []
    const addedRelIds = new Set([...relationshipElements.map(e => e.data.id)])
    const fullRelElements = Object.entries(fullSchema?.relationships || {})
        .filter(e => !addedRelIds.has(e[0]))
        .map(e => buildRelationshipElement(e[1], biDirChecker, true))
    return [ ...entityElements, ...fullEntityElements, ...relationshipElements, ...fullRelElements ];
}


export default function SchemaRenderer({ modifiedGraph, fullSchema, update } : SchemaRendererProps) {
    const [cy, setCy] = useState<cytoscape.Core | undefined>(undefined)
    const { selected, setSelected } = useGraphDisplay()
    const containerProps = useContainer()
    const { commit, reloadCommit } = useGraphEditor()
    const [init, setInit] = useState(true)
    const setSelectedRef = useRef(setSelected)
    const userDefinedLayout = Object.entries(modifiedGraph?.graph.positions || {}).length > 0
    const modifiedGraphRef = useRef(modifiedGraph)
    useEffect(() => {
        modifiedGraphRef.current = modifiedGraph;
    }, [modifiedGraph])
    useEffect(() => {
        setSelectedRef.current = setSelected
    }, [selected, setSelected])
    //TODO extract cytoscape in another effect
    useEffect(() => {
        console.log(selected)
        if (!cy) {
            return
        }
        cy.nodes(":selected")
            .filter(s => !selected.includes(s.id()))
            .unselect()
        cy.nodes()
            .filter(s => selected.includes(s.id()))
            .select()
        if (selected.length === 1) {
            cy.animate({ center: { eles: cy.nodes().$id(selected[0]) } }, { duration: 500 })
        }
    }, [selected])
    useEffect(() => {
        let currentCy = cy
        const elements = buildDisplayElements(fullSchema, modifiedGraph)
        if (currentCy) {
            //onSelected(undefined)
            currentCy.destroy();
        }
        currentCy = buildSchemaCy();
        initHtmlNode(currentCy);
        const edgeEditor = initEdgeEdit(currentCy);
        if (update) {
            initContextMenu(currentCy, containerProps, commit!.id!, reloadCommit, edgeEditor, modifiedGraphRef, fullSchema);
        }
        // @ts-ignore
        currentCy.on('ehcomplete', (event, sourceNode, targetNode, addedEdge) => {
            const edge = currentCy!.edges(`edge[id="${addedEdge.id()}"]`)[0]
            setTimeout(() => {
                currentCy?.remove(edge)
                currentCy!.nodes(`node[id="${targetNode.id()}"]`)[0].unselect()
            }, 300)
            const source = sourceNode.data().rawEntity.type;
            const target = targetNode.data().rawEntity.type;
            containerProps.openModal('add_relationship', <AddRelationship source={source} target={target} onClose={containerProps.closeAllModals} onSubmit={async (relationship: SchemaRelationship) => {
                await new CommitsApi().addChanges(commit!.id!, [{
                    addRelationship: {
                        relationship,
                    }
                }])
                containerProps.closeAllModals()
                reloadCommit()
                return { type: ActionResultType.SUCCESS }
            }}></AddRelationship>, { title: 'Add Edge' })
        })
        // @ts-ignore
        currentCy.autopanOnDrag({
            enabled: true, // Whether the extension is enabled on register
            selector: 'node', // Which elements will be affected by this extension
            speed: 0.3 // Speed of panning when elements exceed canvas bounds
        });
        try {
            currentCy.add(elements)
        } catch(err) {
            console.error("Unable to load schema graph ", err);
            return;
        }
        if (update) {
            const { initCanvas, resizeCanvas } = createGrid(currentCy!)
            currentCy.on('layoutready', () => {
                initCanvas()
            })  
            currentCy.on('zoom', () => {
                resizeCanvas()
            })  
            currentCy.on('pan', () => {
                resizeCanvas()
            })  
        }
        currentCy.on('tapselect', () => {
            setSelectedRef.current(getSelectedIds(currentCy!))
        }) 
        currentCy.on('tapunselect', () => {
            setSelectedRef.current(getSelectedIds(currentCy!))
        }) 
        currentCy.on('tapend', 'node', async (event) => {
            if (!commit) {
                return
            }
            let nodes: any = currentCy!.nodes(":selected")
            if (nodes.length == 0) {
                nodes = event.target
            }
            const moves: MoveEntity[] = nodes.map((t: any) => ({
                id: t.id(),
                new: t.data('new') as boolean,
                position: t.position(),
            })).filter((n: any) => n.new).map((n: any) => n as MoveEntity)
            await new CommitsApi().addChanges(commit?.id!, moves.map(moveEntity => ({
                moveEntity,
            })))
        });    
        setCy(currentCy);
    }, []);

    useEffect(() => {
        if (!cy || !modifiedGraph) {
            return;
        }
        const existingNodeIds = new Set([...(modifiedGraph?.nodes.map(n => n.type!) || []), ...(Object.keys(fullSchema?.entities || {}) || [])])
        const existingEdgeIds = new Set([...(modifiedGraph?.edges.map(e => e.type) || []), ...(Object.keys(fullSchema?.relationships || {}))])
        cy.edges().forEach(e => {
            if (!existingEdgeIds.has(e.id())) {
                e.remove()
            } else if (!existingNodeIds.has(e.data('source'))
                || !existingNodeIds.has(e.data('target'))) {
                e.remove()
            }  else if (update) {
                const biDirChecker = buildBiDirChecker(modifiedGraph)
                e.attr(buildRelationshipElement(fullSchema?.relationships[e.id()]!, biDirChecker, true).data)
            }
        })
        cy.nodes().forEach(n => {
            if (!existingNodeIds.has(n.id())) {
                n.remove()
            } else if (update) {
                n.attr(buildEntityElement(fullSchema?.entities[n.id()]!, true).data)
            }
        })
        const positions = Object.entries(modifiedGraph.graph.positions)
            .reduce((acc, cur) => {
                if (cur[1].x !== undefined && cur[1].x !== null
                    && cur[1].y !== undefined && cur[1].y !== null) {
                        acc[cur[0]] = cur[1]
                    }
                    return acc
            }, {} as {[key: string]: Position})
        modifiedGraph?.nodes
            .forEach(n => {
                const cur = cy.nodes(`node[id="${n.type}"]`)[0]
                const elem = buildEntityElement(n, false)
                if (!cur) {
                    cy.add({ ...elem, position: { ...positions[n.type!] } as any})
                } else {
                    cur.attr(elem.data)
                    cur.position({ ...positions[n.type!] } as any)
                }
            })
        const biDirChecker = buildBiDirChecker(modifiedGraph!)
        modifiedGraph?.edges
            .forEach(e => {
                const cur = cy.edges(`edge[id="${e.type}"]`)[0]
                const elem = buildRelationshipElement(e, biDirChecker, false)
                if (!existingNodeIds.has(elem.data.source)
                    || !existingNodeIds.has(elem.data.target)) {
                    return;
                }
                if (!cur) {
                    cy.add(elem)
                } else {
                    cur.attr(elem.data)
                }
            })
        if (init) {
            const layout = buildSchemaLayout(userDefinedLayout);
            cy.layout({ ...layout, 
                ready: () => {
                    cy.animate({ fit: { eles: cy.nodes(), padding: 50}, duration: 0, complete: () => {
                        setInit(false)
                    } },)
                }
            }).run()   
        } else if (!update) {
            const layout = buildSchemaLayout(userDefinedLayout);
            cy.layout({ ...layout, 
                ready: () => {
                    cy.animate({ fit: { eles: cy.nodes(), padding: 50}, duration: 0, })
                }
            }).run()  
        }
    }, [cy, modifiedGraph])
    return <>
        <div id="cy-schema" className={classes.graph} style={{opacity: init ? 0 : 1}}></div>
    </>
}