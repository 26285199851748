/* tslint:disable */
/* eslint-disable */
/**
 * Sentryfy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddChange
 */
export interface AddChange {
    /**
     * 
     * @type {AddNode}
     * @memberof AddChange
     */
    'addNode'?: AddNode;
    /**
     * 
     * @type {UpdateNode}
     * @memberof AddChange
     */
    'updateNode'?: UpdateNode;
    /**
     * 
     * @type {RemoveNode}
     * @memberof AddChange
     */
    'removeNode'?: RemoveNode;
    /**
     * 
     * @type {AddEdge}
     * @memberof AddChange
     */
    'addEdge'?: AddEdge;
    /**
     * 
     * @type {UpdateEdge}
     * @memberof AddChange
     */
    'updateEdge'?: UpdateEdge;
    /**
     * 
     * @type {RemoveEdge}
     * @memberof AddChange
     */
    'removeEdge'?: RemoveEdge;
    /**
     * 
     * @type {AddEntity}
     * @memberof AddChange
     */
    'addEntity'?: AddEntity;
    /**
     * 
     * @type {UpdateEntity}
     * @memberof AddChange
     */
    'updateEntity'?: UpdateEntity;
    /**
     * 
     * @type {MoveEntity}
     * @memberof AddChange
     */
    'moveEntity'?: MoveEntity;
    /**
     * 
     * @type {RemoveEntity}
     * @memberof AddChange
     */
    'removeEntity'?: RemoveEntity;
    /**
     * 
     * @type {AddRelationship}
     * @memberof AddChange
     */
    'addRelationship'?: AddRelationship;
    /**
     * 
     * @type {UpdateRelationship}
     * @memberof AddChange
     */
    'updateRelationship'?: UpdateRelationship;
    /**
     * 
     * @type {RemoveRelationship}
     * @memberof AddChange
     */
    'removeRelationship'?: RemoveRelationship;
    /**
     * 
     * @type {SetSchemaLayout}
     * @memberof AddChange
     */
    'setLayout'?: SetSchemaLayout;
}
/**
 * 
 * @export
 * @interface AddEdge
 */
export interface AddEdge {
    /**
     * 
     * @type {RawDataEdge}
     * @memberof AddEdge
     */
    'edge': RawDataEdge;
}
/**
 * 
 * @export
 * @interface AddEntity
 */
export interface AddEntity {
    /**
     * 
     * @type {SchemaEntity}
     * @memberof AddEntity
     */
    'entity': SchemaEntity;
    /**
     * 
     * @type {Position}
     * @memberof AddEntity
     */
    'position'?: Position;
}
/**
 * 
 * @export
 * @interface AddNode
 */
export interface AddNode {
    /**
     * 
     * @type {RawDataNode}
     * @memberof AddNode
     */
    'node': RawDataNode;
}
/**
 * 
 * @export
 * @interface AddRelationship
 */
export interface AddRelationship {
    /**
     * 
     * @type {SchemaRelationship}
     * @memberof AddRelationship
     */
    'relationship': SchemaRelationship;
}
/**
 * 
 * @export
 * @interface AiRequest
 */
export interface AiRequest {
    /**
     * 
     * @type {Array<ChatMessage>}
     * @memberof AiRequest
     */
    'messages': Array<ChatMessage>;
}
/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'message'?: string;
    /**
     * 
     * @type {PartialProject}
     * @memberof Alert
     */
    'project'?: PartialProject;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'createdDate'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Alert
     */
    'context': { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'level'?: AlertLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Alert
     */
    'resolved'?: boolean;
}

export const AlertLevelEnum = {
    Low: 'LOW',
    Medium: 'MEDIUM',
    Normal: 'NORMAL',
    High: 'HIGH',
    Critical: 'CRITICAL'
} as const;

export type AlertLevelEnum = typeof AlertLevelEnum[keyof typeof AlertLevelEnum];

/**
 * 
 * @export
 * @interface ApproveCommit
 */
export interface ApproveCommit {
    /**
     * 
     * @type {string}
     * @memberof ApproveCommit
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface AuditEvent
 */
export interface AuditEvent {
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'type'?: AuditEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'category'?: AuditEventCategoryEnum;
    /**
     * 
     * @type {PartialUser}
     * @memberof AuditEvent
     */
    'byUser'?: PartialUser;
    /**
     * 
     * @type {PartialProject}
     * @memberof AuditEvent
     */
    'project'?: PartialProject;
    /**
     * 
     * @type {PartialTenant}
     * @memberof AuditEvent
     */
    'tenant'?: PartialTenant;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'createdDate'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AuditEvent
     */
    'context': { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'specifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'state'?: AuditEventStateEnum;
}

export const AuditEventTypeEnum = {
    Login: 'LOGIN',
    InstallIntegration: 'INSTALL_INTEGRATION',
    OpenIssue: 'OPEN_ISSUE',
    CloseIssue: 'CLOSE_ISSUE'
} as const;

export type AuditEventTypeEnum = typeof AuditEventTypeEnum[keyof typeof AuditEventTypeEnum];
export const AuditEventCategoryEnum = {
    Auth: 'AUTH',
    Integrations: 'INTEGRATIONS',
    Issues: 'ISSUES'
} as const;

export type AuditEventCategoryEnum = typeof AuditEventCategoryEnum[keyof typeof AuditEventCategoryEnum];
export const AuditEventStateEnum = {
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type AuditEventStateEnum = typeof AuditEventStateEnum[keyof typeof AuditEventStateEnum];

/**
 * 
 * @export
 * @interface AvailableVersion
 */
export interface AvailableVersion {
    /**
     * 
     * @type {string}
     * @memberof AvailableVersion
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableVersion
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface AzureChatExtensionsMessageContext
 */
export interface AzureChatExtensionsMessageContext {
    /**
     * 
     * @type {Array<ChatMessage>}
     * @memberof AzureChatExtensionsMessageContext
     */
    'messages'?: Array<ChatMessage>;
}
/**
 * 
 * @export
 * @interface AzureDevopsSettings
 */
export interface AzureDevopsSettings {
    /**
     * 
     * @type {string}
     * @memberof AzureDevopsSettings
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureDevopsSettings
     */
    'project'?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureDevopsSettings
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureDevopsSettings
     */
    'clientSecret'?: string;
}
/**
 * 
 * @export
 * @interface Bucket
 */
export interface Bucket {
    /**
     * 
     * @type {string}
     * @memberof Bucket
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Bucket
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Bucket
     */
    'type'?: BucketTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Bucket
     */
    'createdDate'?: string;
    /**
     * 
     * @type {GraphStats}
     * @memberof Bucket
     */
    'stats'?: GraphStats;
    /**
     * 
     * @type {boolean}
     * @memberof Bucket
     */
    'core'?: boolean;
}

export const BucketTypeEnum = {
    Schema: 'SCHEMA',
    Data: 'DATA'
} as const;

export type BucketTypeEnum = typeof BucketTypeEnum[keyof typeof BucketTypeEnum];

/**
 * 
 * @export
 * @interface BucketData
 */
export interface BucketData {
    /**
     * 
     * @type {string}
     * @memberof BucketData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BucketData
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof BucketData
     */
    'properties': { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof BucketData
     */
    'core': boolean;
}
/**
 * 
 * @export
 * @interface Catalog
 */
export interface Catalog {
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'source'?: CatalogSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'state'?: CatalogStateEnum;
    /**
     * 
     * @type {PartialTenant}
     * @memberof Catalog
     */
    'tenant'?: PartialTenant;
    /**
     * 
     * @type {boolean}
     * @memberof Catalog
     */
    'ops'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Catalog
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Catalog
     */
    'lastSynched'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Catalog
     */
    'fromFederated'?: boolean;
}

export const CatalogSourceEnum = {
    Federated: 'FEDERATED',
    Github: 'GITHUB',
    Custom: 'CUSTOM'
} as const;

export type CatalogSourceEnum = typeof CatalogSourceEnum[keyof typeof CatalogSourceEnum];
export const CatalogStateEnum = {
    Active: 'ACTIVE',
    Connecting: 'CONNECTING'
} as const;

export type CatalogStateEnum = typeof CatalogStateEnum[keyof typeof CatalogStateEnum];

/**
 * 
 * @export
 * @interface Change
 */
export interface Change {
    /**
     * 
     * @type {string}
     * @memberof Change
     */
    'type'?: ChangeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Change
     */
    'target'?: ChangeTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof Change
     */
    'elementId'?: string;
    /**
     * 
     * @type {ChangeData}
     * @memberof Change
     */
    'data'?: ChangeData;
}

export const ChangeTypeEnum = {
    Add: 'ADD',
    Update: 'UPDATE',
    Remove: 'REMOVE',
    Layout: 'LAYOUT',
    Move: 'MOVE',
    ClearAll: 'CLEAR_ALL'
} as const;

export type ChangeTypeEnum = typeof ChangeTypeEnum[keyof typeof ChangeTypeEnum];
export const ChangeTargetEnum = {
    Node: 'NODE',
    Edge: 'EDGE',
    Entity: 'ENTITY',
    Relationship: 'RELATIONSHIP',
    Graph: 'GRAPH'
} as const;

export type ChangeTargetEnum = typeof ChangeTargetEnum[keyof typeof ChangeTargetEnum];

/**
 * 
 * @export
 * @interface ChangeData
 */
export interface ChangeData {
    /**
     * 
     * @type {string}
     * @memberof ChangeData
     */
    'userId'?: string;
    /**
     * 
     * @type {AddNode}
     * @memberof ChangeData
     */
    'addNode'?: AddNode;
    /**
     * 
     * @type {UpdateNode}
     * @memberof ChangeData
     */
    'updateNode'?: UpdateNode;
    /**
     * 
     * @type {RemoveNode}
     * @memberof ChangeData
     */
    'removeNode'?: RemoveNode;
    /**
     * 
     * @type {AddEdge}
     * @memberof ChangeData
     */
    'addEdge'?: AddEdge;
    /**
     * 
     * @type {UpdateEdge}
     * @memberof ChangeData
     */
    'updateEdge'?: UpdateEdge;
    /**
     * 
     * @type {RemoveEdge}
     * @memberof ChangeData
     */
    'removeEdge'?: RemoveEdge;
    /**
     * 
     * @type {object}
     * @memberof ChangeData
     */
    'dataClearAll'?: object;
    /**
     * 
     * @type {AddEntity}
     * @memberof ChangeData
     */
    'addEntity'?: AddEntity;
    /**
     * 
     * @type {UpdateEntity}
     * @memberof ChangeData
     */
    'updateEntity'?: UpdateEntity;
    /**
     * 
     * @type {MoveEntity}
     * @memberof ChangeData
     */
    'moveEntity'?: MoveEntity;
    /**
     * 
     * @type {RemoveEntity}
     * @memberof ChangeData
     */
    'removeEntity'?: RemoveEntity;
    /**
     * 
     * @type {AddRelationship}
     * @memberof ChangeData
     */
    'addRelationship'?: AddRelationship;
    /**
     * 
     * @type {UpdateRelationship}
     * @memberof ChangeData
     */
    'updateRelationship'?: UpdateRelationship;
    /**
     * 
     * @type {RemoveRelationship}
     * @memberof ChangeData
     */
    'removeRelationship'?: RemoveRelationship;
    /**
     * 
     * @type {object}
     * @memberof ChangeData
     */
    'schemaClearAll'?: object;
    /**
     * 
     * @type {SetSchemaLayout}
     * @memberof ChangeData
     */
    'setLayout'?: SetSchemaLayout;
    /**
     * 
     * @type {string}
     * @memberof ChangeData
     */
    'createdDate'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'name'?: string;
    /**
     * 
     * @type {FunctionCall}
     * @memberof ChatMessage
     */
    'function_call'?: FunctionCall;
    /**
     * 
     * @type {AzureChatExtensionsMessageContext}
     * @memberof ChatMessage
     */
    'context'?: AzureChatExtensionsMessageContext;
}
/**
 * 
 * @export
 * @interface ClientCredential
 */
export interface ClientCredential {
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    'description'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof ClientCredential
     */
    'scopes'?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof ClientCredential
     */
    'accessTokenTtl'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {ClientCredentialUser}
     * @memberof ClientCredential
     */
    'user'?: ClientCredentialUser;
}
/**
 * 
 * @export
 * @interface ClientCredentialUser
 */
export interface ClientCredentialUser {
    /**
     * 
     * @type {string}
     * @memberof ClientCredentialUser
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ColumnTransform
 */
export interface ColumnTransform {
    /**
     * 
     * @type {string}
     * @memberof ColumnTransform
     */
    'type'?: ColumnTransformTypeEnum;
    /**
     * 
     * @type {FetchEntityColumnTransform}
     * @memberof ColumnTransform
     */
    'fetchEntity'?: FetchEntityColumnTransform;
    /**
     * 
     * @type {TraverseColumnTransform}
     * @memberof ColumnTransform
     */
    'traverseEdge'?: TraverseColumnTransform;
    /**
     * 
     * @type {FetchPropertyColumnTransform}
     * @memberof ColumnTransform
     */
    'fetchProperty'?: FetchPropertyColumnTransform;
    /**
     * 
     * @type {object}
     * @memberof ColumnTransform
     */
    'mapValue'?: object;
    /**
     * 
     * @type {SimpleExpressionColumnTransform}
     * @memberof ColumnTransform
     */
    'simpleExpression'?: SimpleExpressionColumnTransform;
    /**
     * 
     * @type {ComplexExpressionColumnTransform}
     * @memberof ColumnTransform
     */
    'complexExpression'?: ComplexExpressionColumnTransform;
}

export const ColumnTransformTypeEnum = {
    FetchEntity: 'FETCH_ENTITY',
    TraverseEdge: 'TRAVERSE_EDGE',
    FetchProperty: 'FETCH_PROPERTY',
    MapValue: 'MAP_VALUE',
    SimpleExpression: 'SIMPLE_EXPRESSION'
} as const;

export type ColumnTransformTypeEnum = typeof ColumnTransformTypeEnum[keyof typeof ColumnTransformTypeEnum];

/**
 * 
 * @export
 * @interface Commit
 */
export interface Commit {
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    'message'?: string;
    /**
     * 
     * @type {Array<Change>}
     * @memberof Commit
     */
    'changes'?: Array<Change>;
    /**
     * 
     * @type {number}
     * @memberof Commit
     */
    'graphVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Commit
     */
    'approved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Commit
     */
    'synched': boolean;
    /**
     * 
     * @type {Bucket}
     * @memberof Commit
     */
    'bucket'?: Bucket;
    /**
     * 
     * @type {CommitStats}
     * @memberof Commit
     */
    'stats'?: CommitStats;
}
/**
 * 
 * @export
 * @interface CommitStats
 */
export interface CommitStats {
    /**
     * 
     * @type {number}
     * @memberof CommitStats
     */
    'totalChanges': number;
    /**
     * 
     * @type {number}
     * @memberof CommitStats
     */
    'additions': number;
    /**
     * 
     * @type {number}
     * @memberof CommitStats
     */
    'updates': number;
    /**
     * 
     * @type {number}
     * @memberof CommitStats
     */
    'deletions': number;
}
/**
 * 
 * @export
 * @interface ComplexExpressionColumnTransform
 */
export interface ComplexExpressionColumnTransform {
    /**
     * 
     * @type {string}
     * @memberof ComplexExpressionColumnTransform
     */
    'expression'?: string;
}
/**
 * 
 * @export
 * @interface ConditionOutput
 */
export interface ConditionOutput {
    /**
     * 
     * @type {string}
     * @memberof ConditionOutput
     */
    'integrationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionOutput
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionOutput
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionOutput
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ConditionOutputReason>}
     * @memberof ConditionOutput
     */
    'reasons': Array<ConditionOutputReason>;
    /**
     * 
     * @type {string}
     * @memberof ConditionOutput
     */
    'conditionType'?: ConditionOutputConditionTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConditionOutput
     */
    'conditionConfig': { [key: string]: object; };
}

export const ConditionOutputConditionTypeEnum = {
    Catalog: 'CATALOG',
    Script: 'SCRIPT',
    View: 'VIEW'
} as const;

export type ConditionOutputConditionTypeEnum = typeof ConditionOutputConditionTypeEnum[keyof typeof ConditionOutputConditionTypeEnum];

/**
 * 
 * @export
 * @interface ConditionOutputReason
 */
export interface ConditionOutputReason {
    /**
     * 
     * @type {string}
     * @memberof ConditionOutputReason
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionOutputReason
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionOutputReason
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface CosmosDbSettings
 */
export interface CosmosDbSettings {
    /**
     * 
     * @type {string}
     * @memberof CosmosDbSettings
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CosmosDbSettings
     */
    'readWritePrimaryKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CosmosDbSettings
     */
    'readPrimaryKey'?: string;
}
/**
 * 
 * @export
 * @interface CreateCatalog
 */
export interface CreateCatalog {
    /**
     * 
     * @type {string}
     * @memberof CreateCatalog
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalog
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalog
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCatalog
     */
    'source': CreateCatalogSourceEnum;
}

export const CreateCatalogSourceEnum = {
    Federated: 'FEDERATED',
    Github: 'GITHUB',
    Custom: 'CUSTOM'
} as const;

export type CreateCatalogSourceEnum = typeof CreateCatalogSourceEnum[keyof typeof CreateCatalogSourceEnum];

/**
 * 
 * @export
 * @interface CreateProject
 */
export interface CreateProject {
    /**
     * 
     * @type {string}
     * @memberof CreateProject
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProject
     */
    'backend': CreateProjectBackendEnum;
}

export const CreateProjectBackendEnum = {
    Local: 'LOCAL',
    Sql: 'SQL',
    CosmosDb: 'COSMOS_DB'
} as const;

export type CreateProjectBackendEnum = typeof CreateProjectBackendEnum[keyof typeof CreateProjectBackendEnum];

/**
 * 
 * @export
 * @interface CreateScanTask
 */
export interface CreateScanTask {
    /**
     * 
     * @type {string}
     * @memberof CreateScanTask
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScanTask
     */
    'installedScanId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateScanTask
     */
    'rank'?: number;
    /**
     * 
     * @type {Array<SelectedCondition>}
     * @memberof CreateScanTask
     */
    'selectedConditions'?: Array<SelectedCondition>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateScanTask
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {ScanTicketConfig}
     * @memberof CreateScanTask
     */
    'ticketConfig'?: ScanTicketConfig;
    /**
     * 
     * @type {ScanSlaConfig}
     * @memberof CreateScanTask
     */
    'slaConfig'?: ScanSlaConfig;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CreateScanTask
     */
    'parameters': { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof CreateScanTask
     */
    'aiEnhancementEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateScript
 */
export interface CreateScript {
    /**
     * 
     * @type {string}
     * @memberof CreateScript
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScript
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateScript
     */
    'language': CreateScriptLanguageEnum;
    /**
     * 
     * @type {SaveTrigger}
     * @memberof CreateScript
     */
    'trigger'?: SaveTrigger;
    /**
     * 
     * @type {string}
     * @memberof CreateScript
     */
    'type': CreateScriptTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateScript
     */
    'target'?: string;
}

export const CreateScriptLanguageEnum = {
    Python2: 'PYTHON2',
    Python: 'PYTHON'
} as const;

export type CreateScriptLanguageEnum = typeof CreateScriptLanguageEnum[keyof typeof CreateScriptLanguageEnum];
export const CreateScriptTypeEnum = {
    Condition: 'CONDITION',
    Report: 'REPORT'
} as const;

export type CreateScriptTypeEnum = typeof CreateScriptTypeEnum[keyof typeof CreateScriptTypeEnum];

/**
 * 
 * @export
 * @interface CreatedDeploymentSecretKey
 */
export interface CreatedDeploymentSecretKey {
    /**
     * 
     * @type {string}
     * @memberof CreatedDeploymentSecretKey
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedDeploymentSecretKey
     */
    'secretKey'?: string;
}
/**
 * 
 * @export
 * @interface DataEdge
 */
export interface DataEdge {
    /**
     * 
     * @type {string}
     * @memberof DataEdge
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataEdge
     */
    'name'?: string;
    /**
     * 
     * @type {DataEdgePoint}
     * @memberof DataEdge
     */
    'source'?: DataEdgePoint;
    /**
     * 
     * @type {DataEdgePoint}
     * @memberof DataEdge
     */
    'target'?: DataEdgePoint;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DataEdge
     */
    'properties': { [key: string]: object; };
    /**
     * 
     * @type {Array<BucketData>}
     * @memberof DataEdge
     */
    'buckets'?: Array<BucketData>;
}
/**
 * 
 * @export
 * @interface DataEdgePoint
 */
export interface DataEdgePoint {
    /**
     * 
     * @type {string}
     * @memberof DataEdgePoint
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataEdgePoint
     */
    'oid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataEdgePoint
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface DataGraph
 */
export interface DataGraph {
    /**
     * 
     * @type {string}
     * @memberof DataGraph
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataGraph
     */
    'version'?: number;
    /**
     * 
     * @type {Array<DataNode>}
     * @memberof DataGraph
     */
    'nodes'?: Array<DataNode>;
    /**
     * 
     * @type {Array<DataEdge>}
     * @memberof DataGraph
     */
    'edges'?: Array<DataEdge>;
    /**
     * 
     * @type {Array<Bucket>}
     * @memberof DataGraph
     */
    'buckets'?: Array<Bucket>;
    /**
     * 
     * @type {string}
     * @memberof DataGraph
     */
    'state'?: DataGraphStateEnum;
}

export const DataGraphStateEnum = {
    UpToDate: 'UP_TO_DATE',
    Synching: 'SYNCHING',
    Outdated: 'OUTDATED'
} as const;

export type DataGraphStateEnum = typeof DataGraphStateEnum[keyof typeof DataGraphStateEnum];

/**
 * 
 * @export
 * @interface DataGraphMeta
 */
export interface DataGraphMeta {
    /**
     * 
     * @type {string}
     * @memberof DataGraphMeta
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataGraphMeta
     */
    'version'?: number;
    /**
     * 
     * @type {SchemaGraph}
     * @memberof DataGraphMeta
     */
    'schema'?: SchemaGraph;
    /**
     * 
     * @type {string}
     * @memberof DataGraphMeta
     */
    'state'?: DataGraphMetaStateEnum;
    /**
     * 
     * @type {GraphStats}
     * @memberof DataGraphMeta
     */
    'stats': GraphStats;
}

export const DataGraphMetaStateEnum = {
    UpToDate: 'UP_TO_DATE',
    Synching: 'SYNCHING',
    Outdated: 'OUTDATED'
} as const;

export type DataGraphMetaStateEnum = typeof DataGraphMetaStateEnum[keyof typeof DataGraphMetaStateEnum];

/**
 * 
 * @export
 * @interface DataNode
 */
export interface DataNode {
    /**
     * 
     * @type {string}
     * @memberof DataNode
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataNode
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataNode
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataNode
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataNode
     */
    'oid'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DataNode
     */
    'properties': { [key: string]: object; };
    /**
     * 
     * @type {Array<BucketData>}
     * @memberof DataNode
     */
    'buckets'?: Array<BucketData>;
}
/**
 * 
 * @export
 * @interface Deployment
 */
export interface Deployment {
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'connectionStatus'?: DeploymentConnectionStatusEnum;
    /**
     * 
     * @type {Array<DeploymentSecretKey>}
     * @memberof Deployment
     */
    'secretKeys'?: Array<DeploymentSecretKey>;
    /**
     * 
     * @type {boolean}
     * @memberof Deployment
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {DeploymentVersion}
     * @memberof Deployment
     */
    'deploymentVersion'?: DeploymentVersion;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'updateToVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'lastDeployed'?: string;
}

export const DeploymentConnectionStatusEnum = {
    Up: 'UP',
    Down: 'DOWN'
} as const;

export type DeploymentConnectionStatusEnum = typeof DeploymentConnectionStatusEnum[keyof typeof DeploymentConnectionStatusEnum];

/**
 * 
 * @export
 * @interface DeploymentSecretKey
 */
export interface DeploymentSecretKey {
    /**
     * 
     * @type {string}
     * @memberof DeploymentSecretKey
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentSecretKey
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentSecretKey
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentSecretKey
     */
    'expirationDate'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentVersion
 */
export interface DeploymentVersion {
    /**
     * 
     * @type {string}
     * @memberof DeploymentVersion
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentVersion
     */
    'releasedDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentVersion
     */
    'behind': boolean;
}
/**
 * 
 * @export
 * @interface DescribeOption
 */
export interface DescribeOption {
    /**
     * 
     * @type {string}
     * @memberof DescribeOption
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DescribeOption
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface DescribeParam
 */
export interface DescribeParam {
    /**
     * 
     * @type {string}
     * @memberof DescribeParam
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof DescribeParam
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DescribeParam
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DescribeParam
     */
    'required': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DescribeParam
     */
    'sensitive': boolean;
    /**
     * 
     * @type {string}
     * @memberof DescribeParam
     */
    'type'?: string;
    /**
     * 
     * @type {Array<DescribeOption>}
     * @memberof DescribeParam
     */
    'options'?: Array<DescribeOption>;
}
/**
 * 
 * @export
 * @interface EdgeMapping
 */
export interface EdgeMapping {
    /**
     * 
     * @type {string}
     * @memberof EdgeMapping
     */
    'relationshipId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeMapping
     */
    'sourceFileField'?: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeMapping
     */
    'sourceSelector'?: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeMapping
     */
    'targetFileField'?: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeMapping
     */
    'targetSelector'?: string;
}
/**
 * 
 * @export
 * @interface FetchEntityColumnTransform
 */
export interface FetchEntityColumnTransform {
    /**
     * 
     * @type {string}
     * @memberof FetchEntityColumnTransform
     */
    'entity'?: string;
}
/**
 * 
 * @export
 * @interface FetchPropertyColumnTransform
 */
export interface FetchPropertyColumnTransform {
    /**
     * 
     * @type {string}
     * @memberof FetchPropertyColumnTransform
     */
    'property'?: string;
}
/**
 * 
 * @export
 * @interface FetchedData
 */
export interface FetchedData {
    /**
     * 
     * @type {Array<DataNode>}
     * @memberof FetchedData
     */
    'nodes': Array<DataNode>;
    /**
     * 
     * @type {Array<DataEdge>}
     * @memberof FetchedData
     */
    'edges': Array<DataEdge>;
}
/**
 * 
 * @export
 * @interface FetchedDataRequest
 */
export interface FetchedDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FetchedDataRequest
     */
    'nodeType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchedDataRequest
     */
    'nodeIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchedDataRequest
     */
    'edgeIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FetchedDataRequest
     */
    'edgeSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchedDataRequest
     */
    'edgeTargetId'?: string;
}
/**
 * 
 * @export
 * @interface FieldChange
 */
export interface FieldChange {
    /**
     * 
     * @type {string}
     * @memberof FieldChange
     */
    'type': FieldChangeTypeEnum;
    /**
     * 
     * @type {SchemaField}
     * @memberof FieldChange
     */
    'field'?: SchemaField;
}

export const FieldChangeTypeEnum = {
    Add: 'ADD',
    Remove: 'REMOVE',
    Update: 'UPDATE'
} as const;

export type FieldChangeTypeEnum = typeof FieldChangeTypeEnum[keyof typeof FieldChangeTypeEnum];

/**
 * 
 * @export
 * @interface FieldMapping
 */
export interface FieldMapping {
    /**
     * 
     * @type {string}
     * @memberof FieldMapping
     */
    'fileField'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldMapping
     */
    'schemaField'?: string;
}
/**
 * 
 * @export
 * @interface FunctionCall
 */
export interface FunctionCall {
    /**
     * 
     * @type {string}
     * @memberof FunctionCall
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionCall
     */
    'arguments'?: string;
}
/**
 * 
 * @export
 * @interface GeneratedReport
 */
export interface GeneratedReport {
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {Report}
     * @memberof GeneratedReport
     */
    'report'?: Report;
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    'filename'?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneratedReport
     */
    'sizeInBytes'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratedReport
     */
    'numberOfRows'?: number;
}
/**
 * 
 * @export
 * @interface GraphStats
 */
export interface GraphStats {
    /**
     * 
     * @type {number}
     * @memberof GraphStats
     */
    'totalEntities': number;
    /**
     * 
     * @type {number}
     * @memberof GraphStats
     */
    'totalRelationships': number;
    /**
     * 
     * @type {number}
     * @memberof GraphStats
     */
    'totalNodes': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof GraphStats
     */
    'nodeStats': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof GraphStats
     */
    'totalEdges': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof GraphStats
     */
    'edgeStats': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface Info
 */
export interface Info {
    /**
     * 
     * @type {string}
     * @memberof Info
     */
    'deploymentType': InfoDeploymentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Info
     */
    'aiConfigured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Info
     */
    'experimental': boolean;
    /**
     * 
     * @type {string}
     * @memberof Info
     */
    'version': string;
}

export const InfoDeploymentTypeEnum = {
    Saas: 'SAAS',
    OnPremise: 'ON_PREMISE'
} as const;

export type InfoDeploymentTypeEnum = typeof InfoDeploymentTypeEnum[keyof typeof InfoDeploymentTypeEnum];

/**
 * 
 * @export
 * @interface InstallIntegrationDto
 */
export interface InstallIntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof InstallIntegrationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstallIntegrationDto
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof InstallIntegrationDto
     */
    'version'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof InstallIntegrationDto
     */
    'parameters': { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof InstallIntegrationDto
     */
    'devMode'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallIntegrationDto
     */
    'allowInsecureHttps'?: boolean;
}
/**
 * 
 * @export
 * @interface InstallScan
 */
export interface InstallScan {
    /**
     * 
     * @type {string}
     * @memberof InstallScan
     */
    'projectId': string;
}
/**
 * 
 * @export
 * @interface InstalledIntegration
 */
export interface InstalledIntegration {
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'name'?: string;
    /**
     * 
     * @type {PartialIntegration}
     * @memberof InstalledIntegration
     */
    'integration'?: PartialIntegration;
    /**
     * 
     * @type {Array<Bucket>}
     * @memberof InstalledIntegration
     */
    'buckets'?: Array<Bucket>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof InstalledIntegration
     */
    'parameters': { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'nextRunDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'lastRunDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'state'?: InstalledIntegrationStateEnum;
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'installedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledIntegration
     */
    'installedVersion'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstalledIntegration
     */
    'devMode'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstalledIntegration
     */
    'allowInsecureHttps'?: boolean;
}

export const InstalledIntegrationStateEnum = {
    Active: 'ACTIVE',
    Installing: 'INSTALLING',
    Uninstalling: 'UNINSTALLING',
    ErrorSynching: 'ERROR_SYNCHING'
} as const;

export type InstalledIntegrationStateEnum = typeof InstalledIntegrationStateEnum[keyof typeof InstalledIntegrationStateEnum];

/**
 * 
 * @export
 * @interface InstalledScan
 */
export interface InstalledScan {
    /**
     * 
     * @type {string}
     * @memberof InstalledScan
     */
    'id'?: string;
    /**
     * 
     * @type {PartialScanDefinitionDto}
     * @memberof InstalledScan
     */
    'scanDefinition'?: PartialScanDefinitionDto;
    /**
     * 
     * @type {PartialProject}
     * @memberof InstalledScan
     */
    'project'?: PartialProject;
}
/**
 * 
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'latestVersion'?: string;
    /**
     * 
     * @type {Array<AvailableVersion>}
     * @memberof Integration
     */
    'versions'?: Array<AvailableVersion>;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'changelog'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'installed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'allowMany'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'usesFieldMappings'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'logo'?: string;
}
/**
 * 
 * @export
 * @interface IntegrationRunDto
 */
export interface IntegrationRunDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationRunDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationRunDto
     */
    'state'?: IntegrationRunDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof IntegrationRunDto
     */
    'runDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationRunDto
     */
    'executionInMs'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationRunDto
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationRunDto
     */
    'updatedDate'?: string;
}

export const IntegrationRunDtoStateEnum = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type IntegrationRunDtoStateEnum = typeof IntegrationRunDtoStateEnum[keyof typeof IntegrationRunDtoStateEnum];

/**
 * 
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'issueCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'createdDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Issue
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'state'?: IssueStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'shortCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    'issueNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ConditionOutput>}
     * @memberof Issue
     */
    'conditionOutputs'?: Array<ConditionOutput>;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'targetType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'targetId'?: string;
    /**
     * 
     * @type {Ticket}
     * @memberof Issue
     */
    'ticket'?: Ticket;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Issue
     */
    'context'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Issue
     */
    'userDefinedMetadata'?: { [key: string]: string; };
    /**
     * 
     * @type {IssueSla}
     * @memberof Issue
     */
    'sla'?: IssueSla;
    /**
     * 
     * @type {{ [key: string]: DataNode; }}
     * @memberof Issue
     */
    'relatedNodes'?: { [key: string]: DataNode; };
}

export const IssueStateEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type IssueStateEnum = typeof IssueStateEnum[keyof typeof IssueStateEnum];

/**
 * 
 * @export
 * @interface IssueFilter
 */
export interface IssueFilter {
    /**
     * 
     * @type {string}
     * @memberof IssueFilter
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueFilter
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface IssueFilterSectionDto
 */
export interface IssueFilterSectionDto {
    /**
     * 
     * @type {string}
     * @memberof IssueFilterSectionDto
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueFilterSectionDto
     */
    'label'?: string;
    /**
     * 
     * @type {Array<IssueFilter>}
     * @memberof IssueFilterSectionDto
     */
    'filters'?: Array<IssueFilter>;
}
/**
 * 
 * @export
 * @interface IssueSla
 */
export interface IssueSla {
    /**
     * 
     * @type {string}
     * @memberof IssueSla
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueSla
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof IssueSla
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueSla
     */
    'period'?: IssueSlaPeriodEnum;
}

export const IssueSlaPeriodEnum = {
    Nanos: 'Nanos',
    Micros: 'Micros',
    Millis: 'Millis',
    Seconds: 'Seconds',
    Minutes: 'Minutes',
    Hours: 'Hours',
    HalfDays: 'HalfDays',
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    Years: 'Years',
    Decades: 'Decades',
    Centuries: 'Centuries',
    Millennia: 'Millennia',
    Eras: 'Eras',
    Forever: 'Forever'
} as const;

export type IssueSlaPeriodEnum = typeof IssueSlaPeriodEnum[keyof typeof IssueSlaPeriodEnum];

/**
 * 
 * @export
 * @interface JiraSettings
 */
export interface JiraSettings {
    /**
     * 
     * @type {string}
     * @memberof JiraSettings
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof JiraSettings
     */
    'apiToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof JiraSettings
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof JiraSettings
     */
    'projectKey'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JiraSettings
     */
    'labels'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'tenant': string;
}
/**
 * 
 * @export
 * @interface LoginContext
 */
export interface LoginContext {
    /**
     * 
     * @type {PartialTenant}
     * @memberof LoginContext
     */
    'tenant'?: PartialTenant;
    /**
     * 
     * @type {Array<SsoLogin>}
     * @memberof LoginContext
     */
    'ssoLogins': Array<SsoLogin>;
}
/**
 * 
 * @export
 * @interface Mapping
 */
export interface Mapping {
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'filePattern'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'fileType'?: MappingFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'elementType'?: MappingElementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'mappedType'?: string;
    /**
     * 
     * @type {Array<FieldMapping>}
     * @memberof Mapping
     */
    'fieldMappings'?: Array<FieldMapping>;
    /**
     * 
     * @type {Array<EdgeMapping>}
     * @memberof Mapping
     */
    'edgeMappings'?: Array<EdgeMapping>;
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'updatedDate'?: string;
}

export const MappingFileTypeEnum = {
    Csv: 'CSV'
} as const;

export type MappingFileTypeEnum = typeof MappingFileTypeEnum[keyof typeof MappingFileTypeEnum];
export const MappingElementTypeEnum = {
    Node: 'NODE',
    Edge: 'EDGE',
    Entity: 'ENTITY',
    Relationship: 'RELATIONSHIP',
    Graph: 'GRAPH'
} as const;

export type MappingElementTypeEnum = typeof MappingElementTypeEnum[keyof typeof MappingElementTypeEnum];

/**
 * 
 * @export
 * @interface MeContext
 */
export interface MeContext {
    /**
     * 
     * @type {boolean}
     * @memberof MeContext
     */
    'loggedIn': boolean;
    /**
     * 
     * @type {User}
     * @memberof MeContext
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface MoveEntity
 */
export interface MoveEntity {
    /**
     * 
     * @type {string}
     * @memberof MoveEntity
     */
    'id': string;
    /**
     * 
     * @type {Position}
     * @memberof MoveEntity
     */
    'position': Position;
}
/**
 * 
 * @export
 * @interface OpenCommit
 */
export interface OpenCommit {
    /**
     * 
     * @type {string}
     * @memberof OpenCommit
     */
    'graphType': OpenCommitGraphTypeEnum;
}

export const OpenCommitGraphTypeEnum = {
    Schema: 'SCHEMA',
    Data: 'DATA'
} as const;

export type OpenCommitGraphTypeEnum = typeof OpenCommitGraphTypeEnum[keyof typeof OpenCommitGraphTypeEnum];

/**
 * 
 * @export
 * @interface PageDtoAlert
 */
export interface PageDtoAlert {
    /**
     * 
     * @type {Array<Alert>}
     * @memberof PageDtoAlert
     */
    'list'?: Array<Alert>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAlert
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAlert
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAlert
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAlert
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoAuditEvent
 */
export interface PageDtoAuditEvent {
    /**
     * 
     * @type {Array<AuditEvent>}
     * @memberof PageDtoAuditEvent
     */
    'list'?: Array<AuditEvent>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAuditEvent
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAuditEvent
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAuditEvent
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoAuditEvent
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoBucket
 */
export interface PageDtoBucket {
    /**
     * 
     * @type {Array<Bucket>}
     * @memberof PageDtoBucket
     */
    'list'?: Array<Bucket>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoBucket
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoBucket
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoBucket
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoBucket
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoCatalog
 */
export interface PageDtoCatalog {
    /**
     * 
     * @type {Array<Catalog>}
     * @memberof PageDtoCatalog
     */
    'list'?: Array<Catalog>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCatalog
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCatalog
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCatalog
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCatalog
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoClientCredential
 */
export interface PageDtoClientCredential {
    /**
     * 
     * @type {Array<ClientCredential>}
     * @memberof PageDtoClientCredential
     */
    'list'?: Array<ClientCredential>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoClientCredential
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoClientCredential
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoClientCredential
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoClientCredential
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoCommit
 */
export interface PageDtoCommit {
    /**
     * 
     * @type {Array<Commit>}
     * @memberof PageDtoCommit
     */
    'list'?: Array<Commit>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCommit
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCommit
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCommit
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoCommit
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoDeployment
 */
export interface PageDtoDeployment {
    /**
     * 
     * @type {Array<Deployment>}
     * @memberof PageDtoDeployment
     */
    'list'?: Array<Deployment>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoDeployment
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoDeployment
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoDeployment
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoDeployment
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoGeneratedReport
 */
export interface PageDtoGeneratedReport {
    /**
     * 
     * @type {Array<GeneratedReport>}
     * @memberof PageDtoGeneratedReport
     */
    'list'?: Array<GeneratedReport>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoGeneratedReport
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoGeneratedReport
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoGeneratedReport
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoGeneratedReport
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoInstalledIntegration
 */
export interface PageDtoInstalledIntegration {
    /**
     * 
     * @type {Array<InstalledIntegration>}
     * @memberof PageDtoInstalledIntegration
     */
    'list'?: Array<InstalledIntegration>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledIntegration
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledIntegration
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledIntegration
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledIntegration
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoInstalledScan
 */
export interface PageDtoInstalledScan {
    /**
     * 
     * @type {Array<InstalledScan>}
     * @memberof PageDtoInstalledScan
     */
    'list'?: Array<InstalledScan>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledScan
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledScan
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledScan
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoInstalledScan
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoIntegration
 */
export interface PageDtoIntegration {
    /**
     * 
     * @type {Array<Integration>}
     * @memberof PageDtoIntegration
     */
    'list'?: Array<Integration>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIntegration
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIntegration
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIntegration
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIntegration
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoIssue
 */
export interface PageDtoIssue {
    /**
     * 
     * @type {Array<Issue>}
     * @memberof PageDtoIssue
     */
    'list'?: Array<Issue>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIssue
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIssue
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIssue
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoIssue
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoProject
 */
export interface PageDtoProject {
    /**
     * 
     * @type {Array<Project>}
     * @memberof PageDtoProject
     */
    'list'?: Array<Project>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoProject
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoProject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoProject
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoProject
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoReport
 */
export interface PageDtoReport {
    /**
     * 
     * @type {Array<Report>}
     * @memberof PageDtoReport
     */
    'list'?: Array<Report>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReport
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReport
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReport
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReport
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoReportBuilderColumnDto
 */
export interface PageDtoReportBuilderColumnDto {
    /**
     * 
     * @type {Array<ReportBuilderColumnDto>}
     * @memberof PageDtoReportBuilderColumnDto
     */
    'list'?: Array<ReportBuilderColumnDto>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReportBuilderColumnDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReportBuilderColumnDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReportBuilderColumnDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoReportBuilderColumnDto
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoScanDefinition
 */
export interface PageDtoScanDefinition {
    /**
     * 
     * @type {Array<ScanDefinition>}
     * @memberof PageDtoScanDefinition
     */
    'list'?: Array<ScanDefinition>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanDefinition
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanDefinition
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanDefinition
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanDefinition
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoScanTask
 */
export interface PageDtoScanTask {
    /**
     * 
     * @type {Array<ScanTask>}
     * @memberof PageDtoScanTask
     */
    'list'?: Array<ScanTask>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanTask
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanTask
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanTask
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScanTask
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoScript
 */
export interface PageDtoScript {
    /**
     * 
     * @type {Array<Script>}
     * @memberof PageDtoScript
     */
    'list'?: Array<Script>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScript
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScript
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScript
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoScript
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoTenant
 */
export interface PageDtoTenant {
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof PageDtoTenant
     */
    'list'?: Array<Tenant>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTenant
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTenant
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTenant
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTenant
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoTicket
 */
export interface PageDtoTicket {
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof PageDtoTicket
     */
    'list'?: Array<Ticket>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTicket
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTicket
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTicket
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoTicket
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoUser
 */
export interface PageDtoUser {
    /**
     * 
     * @type {Array<User>}
     * @memberof PageDtoUser
     */
    'list'?: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoUser
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoUser
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoUser
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoUser
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface PageDtoWorkflow
 */
export interface PageDtoWorkflow {
    /**
     * 
     * @type {Array<Workflow>}
     * @memberof PageDtoWorkflow
     */
    'list'?: Array<Workflow>;
    /**
     * 
     * @type {number}
     * @memberof PageDtoWorkflow
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoWorkflow
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoWorkflow
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDtoWorkflow
     */
    'totalElements'?: number;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PartialCatalog
 */
export interface PartialCatalog {
    /**
     * 
     * @type {string}
     * @memberof PartialCatalog
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCatalog
     */
    'custom'?: boolean;
}
/**
 * 
 * @export
 * @interface PartialIntegration
 */
export interface PartialIntegration {
    /**
     * 
     * @type {string}
     * @memberof PartialIntegration
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialIntegration
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialIntegration
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialIntegration
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialIntegration
     */
    'latestVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialIntegration
     */
    'customType'?: PartialIntegrationCustomTypeEnum;
    /**
     * 
     * @type {PartialCatalog}
     * @memberof PartialIntegration
     */
    'catalog'?: PartialCatalog;
    /**
     * 
     * @type {boolean}
     * @memberof PartialIntegration
     */
    'usesFieldMappings'?: boolean;
}

export const PartialIntegrationCustomTypeEnum = {
    Upload: 'UPLOAD'
} as const;

export type PartialIntegrationCustomTypeEnum = typeof PartialIntegrationCustomTypeEnum[keyof typeof PartialIntegrationCustomTypeEnum];

/**
 * 
 * @export
 * @interface PartialProject
 */
export interface PartialProject {
    /**
     * 
     * @type {string}
     * @memberof PartialProject
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialProject
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PartialScanDefinitionDto
 */
export interface PartialScanDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof PartialScanDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialScanDefinitionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialScanDefinitionDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialScanDefinitionDto
     */
    'category'?: string;
}
/**
 * 
 * @export
 * @interface PartialTenant
 */
export interface PartialTenant {
    /**
     * 
     * @type {string}
     * @memberof PartialTenant
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialTenant
     */
    'ops': boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialTenant
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTenant
     */
    'displayName'?: string;
}
/**
 * 
 * @export
 * @interface PartialUser
 */
export interface PartialUser {
    /**
     * 
     * @type {string}
     * @memberof PartialUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUser
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUser
     */
    'imageUrl'?: string;
}
/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'backend'?: ProjectBackendEnum;
    /**
     * 
     * @type {PartialTenant}
     * @memberof Project
     */
    'tenant'?: PartialTenant;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'createdDate'?: string;
}

export const ProjectBackendEnum = {
    Local: 'LOCAL',
    Sql: 'SQL',
    CosmosDb: 'COSMOS_DB'
} as const;

export type ProjectBackendEnum = typeof ProjectBackendEnum[keyof typeof ProjectBackendEnum];

/**
 * 
 * @export
 * @interface QueryResponse
 */
export interface QueryResponse {
    /**
     * 
     * @type {string}
     * @memberof QueryResponse
     */
    'message': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryResponse
     */
    'queries': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof QueryResponse
     */
    'clarificationNeeded': boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryResponse
     */
    'raw': string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryResponse
     */
    'resetGraph': boolean;
}
/**
 * 
 * @export
 * @interface RawDataEdge
 */
export interface RawDataEdge {
    /**
     * 
     * @type {string}
     * @memberof RawDataEdge
     */
    'name'?: string;
    /**
     * 
     * @type {DataEdgePoint}
     * @memberof RawDataEdge
     */
    'source'?: DataEdgePoint;
    /**
     * 
     * @type {DataEdgePoint}
     * @memberof RawDataEdge
     */
    'target'?: DataEdgePoint;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RawDataEdge
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof RawDataEdge
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RawDataEdge
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface RawDataNode
 */
export interface RawDataNode {
    /**
     * 
     * @type {string}
     * @memberof RawDataNode
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RawDataNode
     */
    'oid'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RawDataNode
     */
    'properties'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof RawDataNode
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface RemoveEdge
 */
export interface RemoveEdge {
    /**
     * 
     * @type {string}
     * @memberof RemoveEdge
     */
    'id': string;
    /**
     * 
     * @type {RemoveEdgeMeta}
     * @memberof RemoveEdge
     */
    'meta'?: RemoveEdgeMeta;
}
/**
 * 
 * @export
 * @interface RemoveEdgeMeta
 */
export interface RemoveEdgeMeta {
    /**
     * 
     * @type {string}
     * @memberof RemoveEdgeMeta
     */
    'name'?: string;
    /**
     * 
     * @type {DataEdgePoint}
     * @memberof RemoveEdgeMeta
     */
    'source'?: DataEdgePoint;
    /**
     * 
     * @type {DataEdgePoint}
     * @memberof RemoveEdgeMeta
     */
    'target'?: DataEdgePoint;
    /**
     * 
     * @type {string}
     * @memberof RemoveEdgeMeta
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface RemoveEntity
 */
export interface RemoveEntity {
    /**
     * 
     * @type {string}
     * @memberof RemoveEntity
     */
    'id': string;
    /**
     * 
     * @type {RemoveEntityMeta}
     * @memberof RemoveEntity
     */
    'meta'?: RemoveEntityMeta;
}
/**
 * 
 * @export
 * @interface RemoveEntityMeta
 */
export interface RemoveEntityMeta {
    /**
     * 
     * @type {string}
     * @memberof RemoveEntityMeta
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface RemoveNode
 */
export interface RemoveNode {
    /**
     * 
     * @type {string}
     * @memberof RemoveNode
     */
    'id': string;
    /**
     * 
     * @type {RemoveNodeMeta}
     * @memberof RemoveNode
     */
    'meta'?: RemoveNodeMeta;
}
/**
 * 
 * @export
 * @interface RemoveNodeMeta
 */
export interface RemoveNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof RemoveNodeMeta
     */
    'oid'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveNodeMeta
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface RemoveRelationship
 */
export interface RemoveRelationship {
    /**
     * 
     * @type {string}
     * @memberof RemoveRelationship
     */
    'id': string;
    /**
     * 
     * @type {RemoveRelationshipMeta}
     * @memberof RemoveRelationship
     */
    'meta'?: RemoveRelationshipMeta;
}
/**
 * 
 * @export
 * @interface RemoveRelationshipMeta
 */
export interface RemoveRelationshipMeta {
    /**
     * 
     * @type {string}
     * @memberof RemoveRelationshipMeta
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveRelationshipMeta
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveRelationshipMeta
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveRelationshipMeta
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'lastGeneratedDate'?: string;
    /**
     * 
     * @type {PartialProject}
     * @memberof Report
     */
    'project'?: PartialProject;
    /**
     * 
     * @type {ReportBuilderDto}
     * @memberof Report
     */
    'builder'?: ReportBuilderDto;
}
/**
 * 
 * @export
 * @interface ReportBuilderColumnDto
 */
export interface ReportBuilderColumnDto {
    /**
     * 
     * @type {string}
     * @memberof ReportBuilderColumnDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportBuilderColumnDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ColumnTransform>}
     * @memberof ReportBuilderColumnDto
     */
    'transform'?: Array<ColumnTransform>;
}
/**
 * 
 * @export
 * @interface ReportBuilderDto
 */
export interface ReportBuilderDto {
    /**
     * 
     * @type {ReportBuilderSelector}
     * @memberof ReportBuilderDto
     */
    'selector'?: ReportBuilderSelector;
    /**
     * 
     * @type {Array<ReportBuilderColumnDto>}
     * @memberof ReportBuilderDto
     */
    'columns'?: Array<ReportBuilderColumnDto>;
}
/**
 * 
 * @export
 * @interface ReportBuilderSelector
 */
export interface ReportBuilderSelector {
    /**
     * 
     * @type {string}
     * @memberof ReportBuilderSelector
     */
    'select'?: ReportBuilderSelectorSelectEnum;
}

export const ReportBuilderSelectorSelectEnum = {
    Issues: 'ISSUES'
} as const;

export type ReportBuilderSelectorSelectEnum = typeof ReportBuilderSelectorSelectEnum[keyof typeof ReportBuilderSelectorSelectEnum];

/**
 * 
 * @export
 * @interface RunTestDto
 */
export interface RunTestDto {
    /**
     * 
     * @type {string}
     * @memberof RunTestDto
     */
    'script': string;
}
/**
 * 
 * @export
 * @interface SaveClientCredential
 */
export interface SaveClientCredential {
    /**
     * 
     * @type {string}
     * @memberof SaveClientCredential
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveClientCredential
     */
    'description': string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SaveClientCredential
     */
    'scopes': Set<string>;
    /**
     * 
     * @type {number}
     * @memberof SaveClientCredential
     */
    'accessTokenTtl'?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveClientCredential
     */
    'expirationDate'?: string;
}
/**
 * 
 * @export
 * @interface SaveCustomIntegration
 */
export interface SaveCustomIntegration {
    /**
     * 
     * @type {string}
     * @memberof SaveCustomIntegration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveCustomIntegration
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof SaveCustomIntegration
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCustomIntegration
     */
    'customType': SaveCustomIntegrationCustomTypeEnum;
}

export const SaveCustomIntegrationCustomTypeEnum = {
    Upload: 'UPLOAD'
} as const;

export type SaveCustomIntegrationCustomTypeEnum = typeof SaveCustomIntegrationCustomTypeEnum[keyof typeof SaveCustomIntegrationCustomTypeEnum];

/**
 * 
 * @export
 * @interface SaveDeployment
 */
export interface SaveDeployment {
    /**
     * 
     * @type {string}
     * @memberof SaveDeployment
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SaveMapping
 */
export interface SaveMapping {
    /**
     * 
     * @type {string}
     * @memberof SaveMapping
     */
    'filePattern': string;
    /**
     * 
     * @type {string}
     * @memberof SaveMapping
     */
    'fileType': SaveMappingFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveMapping
     */
    'elementType': SaveMappingElementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveMapping
     */
    'mappedType': string;
    /**
     * 
     * @type {Array<FieldMapping>}
     * @memberof SaveMapping
     */
    'fieldMappings'?: Array<FieldMapping>;
    /**
     * 
     * @type {Array<EdgeMapping>}
     * @memberof SaveMapping
     */
    'edgeMappings'?: Array<EdgeMapping>;
}

export const SaveMappingFileTypeEnum = {
    Csv: 'CSV'
} as const;

export type SaveMappingFileTypeEnum = typeof SaveMappingFileTypeEnum[keyof typeof SaveMappingFileTypeEnum];
export const SaveMappingElementTypeEnum = {
    Node: 'NODE',
    Edge: 'EDGE',
    Entity: 'ENTITY',
    Relationship: 'RELATIONSHIP',
    Graph: 'GRAPH'
} as const;

export type SaveMappingElementTypeEnum = typeof SaveMappingElementTypeEnum[keyof typeof SaveMappingElementTypeEnum];

/**
 * 
 * @export
 * @interface SaveReportDto
 */
export interface SaveReportDto {
    /**
     * 
     * @type {string}
     * @memberof SaveReportDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveReportDto
     */
    'description'?: string;
    /**
     * 
     * @type {ReportBuilderSelector}
     * @memberof SaveReportDto
     */
    'selector'?: ReportBuilderSelector;
}
/**
 * 
 * @export
 * @interface SaveSsoProvider
 */
export interface SaveSsoProvider {
    /**
     * 
     * @type {string}
     * @memberof SaveSsoProvider
     */
    'provider'?: SaveSsoProviderProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveSsoProvider
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveSsoProvider
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveSsoProvider
     */
    'autoApproval': boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SaveSsoProvider
     */
    'customOptions'?: { [key: string]: object; };
}

export const SaveSsoProviderProviderEnum = {
    Microsoft: 'MICROSOFT'
} as const;

export type SaveSsoProviderProviderEnum = typeof SaveSsoProviderProviderEnum[keyof typeof SaveSsoProviderProviderEnum];

/**
 * 
 * @export
 * @interface SaveTenantDto
 */
export interface SaveTenantDto {
    /**
     * 
     * @type {string}
     * @memberof SaveTenantDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveTenantDto
     */
    'displayName'?: string;
}
/**
 * 
 * @export
 * @interface SaveTrigger
 */
export interface SaveTrigger {
    /**
     * 
     * @type {string}
     * @memberof SaveTrigger
     */
    'cron'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveTrigger
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveTrigger
     */
    'period'?: SaveTriggerPeriodEnum;
}

export const SaveTriggerPeriodEnum = {
    Day: 'DAY',
    Hour: 'HOUR'
} as const;

export type SaveTriggerPeriodEnum = typeof SaveTriggerPeriodEnum[keyof typeof SaveTriggerPeriodEnum];

/**
 * 
 * @export
 * @interface SaveUser
 */
export interface SaveUser {
    /**
     * 
     * @type {string}
     * @memberof SaveUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SaveUser
     */
    'role': SaveUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveUser
     */
    'tenantId'?: string;
}

export const SaveUserRoleEnum = {
    Operator: 'OPERATOR',
    Admin: 'ADMIN',
    User: 'USER',
    ReadOnly: 'READ_ONLY'
} as const;

export type SaveUserRoleEnum = typeof SaveUserRoleEnum[keyof typeof SaveUserRoleEnum];

/**
 * 
 * @export
 * @interface SaveView
 */
export interface SaveView {
    /**
     * 
     * @type {string}
     * @memberof SaveView
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveView
     */
    'query'?: string;
}
/**
 * 
 * @export
 * @interface SaveWorkflow
 */
export interface SaveWorkflow {
    /**
     * 
     * @type {string}
     * @memberof SaveWorkflow
     */
    'name'?: string;
    /**
     * 
     * @type {WorkflowDefinition}
     * @memberof SaveWorkflow
     */
    'definition'?: WorkflowDefinition;
}
/**
 * 
 * @export
 * @interface ScanCategory
 */
export interface ScanCategory {
    /**
     * 
     * @type {string}
     * @memberof ScanCategory
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof ScanCategory
     */
    'installedCount': number;
}
/**
 * 
 * @export
 * @interface ScanDefinition
 */
export interface ScanDefinition {
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'category'?: string;
    /**
     * 
     * @type {PartialCatalog}
     * @memberof ScanDefinition
     */
    'catalog'?: PartialCatalog;
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'target'?: string;
    /**
     * 
     * @type {PartialIntegration}
     * @memberof ScanDefinition
     */
    'integration'?: PartialIntegration;
    /**
     * 
     * @type {boolean}
     * @memberof ScanDefinition
     */
    'installed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'type'?: ScanDefinitionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ScanDefinition
     */
    'shortCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScanDefinition
     */
    'deprecated'?: boolean;
}

export const ScanDefinitionTypeEnum = {
    Scan: 'SCAN',
    Condition: 'CONDITION'
} as const;

export type ScanDefinitionTypeEnum = typeof ScanDefinitionTypeEnum[keyof typeof ScanDefinitionTypeEnum];

/**
 * 
 * @export
 * @interface ScanSlaConfig
 */
export interface ScanSlaConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ScanSlaConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScanSlaConfig
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScanSlaConfig
     */
    'period'?: ScanSlaConfigPeriodEnum;
}

export const ScanSlaConfigPeriodEnum = {
    Nanos: 'Nanos',
    Micros: 'Micros',
    Millis: 'Millis',
    Seconds: 'Seconds',
    Minutes: 'Minutes',
    Hours: 'Hours',
    HalfDays: 'HalfDays',
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    Years: 'Years',
    Decades: 'Decades',
    Centuries: 'Centuries',
    Millennia: 'Millennia',
    Eras: 'Eras',
    Forever: 'Forever'
} as const;

export type ScanSlaConfigPeriodEnum = typeof ScanSlaConfigPeriodEnum[keyof typeof ScanSlaConfigPeriodEnum];

/**
 * 
 * @export
 * @interface ScanTask
 */
export interface ScanTask {
    /**
     * 
     * @type {string}
     * @memberof ScanTask
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanTask
     */
    'name'?: string;
    /**
     * 
     * @type {InstalledScan}
     * @memberof ScanTask
     */
    'installedScan'?: InstalledScan;
    /**
     * 
     * @type {Array<SelectedCondition>}
     * @memberof ScanTask
     */
    'selectedConditions'?: Array<SelectedCondition>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ScanTask
     */
    'parameters': { [key: string]: object; };
    /**
     * 
     * @type {ScanTicketConfig}
     * @memberof ScanTask
     */
    'ticketConfig'?: ScanTicketConfig;
    /**
     * 
     * @type {ScanSlaConfig}
     * @memberof ScanTask
     */
    'slaConfig'?: ScanSlaConfig;
    /**
     * 
     * @type {string}
     * @memberof ScanTask
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanTask
     */
    'state'?: ScanTaskStateEnum;
    /**
     * 
     * @type {number}
     * @memberof ScanTask
     */
    'rank'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScanTask
     */
    'aiEnhancementEnabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScanTask
     */
    'labels'?: Array<string>;
}

export const ScanTaskStateEnum = {
    Running: 'RUNNING',
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type ScanTaskStateEnum = typeof ScanTaskStateEnum[keyof typeof ScanTaskStateEnum];

/**
 * 
 * @export
 * @interface ScanTicketConfig
 */
export interface ScanTicketConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ScanTicketConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScanTicketConfig
     */
    'autoApprove'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScanTicketConfig
     */
    'projectKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanTicketConfig
     */
    'issueType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScanTicketConfig
     */
    'service'?: ScanTicketConfigServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof ScanTicketConfig
     */
    'priority'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScanTicketConfig
     */
    'labels'?: Array<string>;
}

export const ScanTicketConfigServiceEnum = {
    AzureDevops: 'AZURE_DEVOPS',
    Jira: 'JIRA'
} as const;

export type ScanTicketConfigServiceEnum = typeof ScanTicketConfigServiceEnum[keyof typeof ScanTicketConfigServiceEnum];

/**
 * 
 * @export
 * @interface SchemaEntity
 */
export interface SchemaEntity {
    /**
     * 
     * @type {string}
     * @memberof SchemaEntity
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaEntity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaEntity
     */
    'displayLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaEntity
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaEntity
     */
    'source'?: string;
    /**
     * 
     * @type {{ [key: string]: SchemaField; }}
     * @memberof SchemaEntity
     */
    'fields'?: { [key: string]: SchemaField; };
}
/**
 * 
 * @export
 * @interface SchemaField
 */
export interface SchemaField {
    /**
     * 
     * @type {string}
     * @memberof SchemaField
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaField
     */
    'type'?: SchemaFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SchemaField
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaField
     */
    'source'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaField
     */
    'indexed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SchemaField
     */
    'order'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaField
     */
    'required'?: boolean;
}

export const SchemaFieldTypeEnum = {
    String: 'STRING',
    Bool: 'BOOL',
    Int: 'INT',
    Float: 'FLOAT'
} as const;

export type SchemaFieldTypeEnum = typeof SchemaFieldTypeEnum[keyof typeof SchemaFieldTypeEnum];

/**
 * 
 * @export
 * @interface SchemaGraph
 */
export interface SchemaGraph {
    /**
     * 
     * @type {string}
     * @memberof SchemaGraph
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SchemaGraph
     */
    'version'?: number;
    /**
     * 
     * @type {{ [key: string]: SchemaEntity; }}
     * @memberof SchemaGraph
     */
    'entities': { [key: string]: SchemaEntity; };
    /**
     * 
     * @type {{ [key: string]: SchemaRelationship; }}
     * @memberof SchemaGraph
     */
    'relationships': { [key: string]: SchemaRelationship; };
    /**
     * 
     * @type {Array<Bucket>}
     * @memberof SchemaGraph
     */
    'buckets'?: Array<Bucket>;
    /**
     * 
     * @type {string}
     * @memberof SchemaGraph
     */
    'state'?: SchemaGraphStateEnum;
    /**
     * 
     * @type {{ [key: string]: Position; }}
     * @memberof SchemaGraph
     */
    'positions': { [key: string]: Position; };
    /**
     * 
     * @type {string}
     * @memberof SchemaGraph
     */
    'integrationVersion'?: string;
}

export const SchemaGraphStateEnum = {
    UpToDate: 'UP_TO_DATE',
    Synching: 'SYNCHING',
    Outdated: 'OUTDATED'
} as const;

export type SchemaGraphStateEnum = typeof SchemaGraphStateEnum[keyof typeof SchemaGraphStateEnum];

/**
 * 
 * @export
 * @interface SchemaRelationship
 */
export interface SchemaRelationship {
    /**
     * 
     * @type {string}
     * @memberof SchemaRelationship
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaRelationship
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaRelationship
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaRelationship
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Script
 */
export interface Script {
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    'language'?: ScriptLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    'type'?: ScriptTypeEnum;
    /**
     * 
     * @type {Trigger}
     * @memberof Script
     */
    'trigger'?: Trigger;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    'target'?: string;
}

export const ScriptLanguageEnum = {
    Python2: 'PYTHON2',
    Python: 'PYTHON'
} as const;

export type ScriptLanguageEnum = typeof ScriptLanguageEnum[keyof typeof ScriptLanguageEnum];
export const ScriptTypeEnum = {
    Condition: 'CONDITION',
    Report: 'REPORT'
} as const;

export type ScriptTypeEnum = typeof ScriptTypeEnum[keyof typeof ScriptTypeEnum];

/**
 * 
 * @export
 * @interface SelectedCondition
 */
export interface SelectedCondition {
    /**
     * 
     * @type {string}
     * @memberof SelectedCondition
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedCondition
     */
    'type'?: SelectedConditionTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SelectedCondition
     */
    'config'?: { [key: string]: object; };
}

export const SelectedConditionTypeEnum = {
    Catalog: 'CATALOG',
    Script: 'SCRIPT',
    View: 'VIEW'
} as const;

export type SelectedConditionTypeEnum = typeof SelectedConditionTypeEnum[keyof typeof SelectedConditionTypeEnum];

/**
 * 
 * @export
 * @interface SetSchemaLayout
 */
export interface SetSchemaLayout {
    /**
     * 
     * @type {{ [key: string]: Position; }}
     * @memberof SetSchemaLayout
     */
    'layout': { [key: string]: Position; };
}
/**
 * 
 * @export
 * @interface SimpleExpressionColumnTransform
 */
export interface SimpleExpressionColumnTransform {
    /**
     * 
     * @type {string}
     * @memberof SimpleExpressionColumnTransform
     */
    'operator'?: SimpleExpressionColumnTransformOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleExpressionColumnTransform
     */
    'operatorValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleExpressionColumnTransform
     */
    'resultValue'?: string;
}

export const SimpleExpressionColumnTransformOperatorEnum = {
    Contains: 'CONTAINS',
    Equals: 'EQUALS',
    GreaterThan: 'GREATER_THAN',
    GreaterOrEqualThan: 'GREATER_OR_EQUAL_THAN',
    SmallerThan: 'SMALLER_THAN',
    SmallerOrEqualThan: 'SMALLER_OR_EQUAL_THAN'
} as const;

export type SimpleExpressionColumnTransformOperatorEnum = typeof SimpleExpressionColumnTransformOperatorEnum[keyof typeof SimpleExpressionColumnTransformOperatorEnum];

/**
 * 
 * @export
 * @interface SsoLogin
 */
export interface SsoLogin {
    /**
     * 
     * @type {string}
     * @memberof SsoLogin
     */
    'provider': SsoLoginProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof SsoLogin
     */
    'url': string;
}

export const SsoLoginProviderEnum = {
    Microsoft: 'MICROSOFT'
} as const;

export type SsoLoginProviderEnum = typeof SsoLoginProviderEnum[keyof typeof SsoLoginProviderEnum];

/**
 * 
 * @export
 * @interface SsoProvider
 */
export interface SsoProvider {
    /**
     * 
     * @type {string}
     * @memberof SsoProvider
     */
    'provider'?: SsoProviderProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof SsoProvider
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoProvider
     */
    'issuerUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SsoProvider
     */
    'autoCreateUsers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SsoProvider
     */
    'enabled': boolean;
}

export const SsoProviderProviderEnum = {
    Microsoft: 'MICROSOFT'
} as const;

export type SsoProviderProviderEnum = typeof SsoProviderProviderEnum[keyof typeof SsoProviderProviderEnum];

/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'numberOfProjects'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'numberOfUsers'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'createdDate'?: string;
}
/**
 * 
 * @export
 * @interface TenantExistsResponse
 */
export interface TenantExistsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TenantExistsResponse
     */
    'exists': boolean;
}
/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'ticketNumber'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Ticket
     */
    'context'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'state'?: TicketStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Ticket
     */
    'synched'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'synchedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'synchedTicketNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'synchedService'?: TicketSynchedServiceEnum;
    /**
     * 
     * @type {PartialProject}
     * @memberof Ticket
     */
    'project'?: PartialProject;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'synchedUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Ticket
     */
    'labels': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'priority'?: string;
}

export const TicketStateEnum = {
    AwaitingApproval: 'AWAITING_APPROVAL',
    Approved: 'APPROVED',
    Declined: 'DECLINED'
} as const;

export type TicketStateEnum = typeof TicketStateEnum[keyof typeof TicketStateEnum];
export const TicketSynchedServiceEnum = {
    AzureDevops: 'AZURE_DEVOPS',
    Jira: 'JIRA'
} as const;

export type TicketSynchedServiceEnum = typeof TicketSynchedServiceEnum[keyof typeof TicketSynchedServiceEnum];

/**
 * 
 * @export
 * @interface TicketSettings
 */
export interface TicketSettings {
    /**
     * 
     * @type {string}
     * @memberof TicketSettings
     */
    'service'?: TicketSettingsServiceEnum;
    /**
     * 
     * @type {AzureDevopsSettings}
     * @memberof TicketSettings
     */
    'azureDevops'?: AzureDevopsSettings;
    /**
     * 
     * @type {JiraSettings}
     * @memberof TicketSettings
     */
    'jira'?: JiraSettings;
}

export const TicketSettingsServiceEnum = {
    AzureDevops: 'AZURE_DEVOPS',
    Jira: 'JIRA'
} as const;

export type TicketSettingsServiceEnum = typeof TicketSettingsServiceEnum[keyof typeof TicketSettingsServiceEnum];

/**
 * 
 * @export
 * @interface TraverseColumnTransform
 */
export interface TraverseColumnTransform {
    /**
     * 
     * @type {string}
     * @memberof TraverseColumnTransform
     */
    'relationship'?: string;
}
/**
 * 
 * @export
 * @interface Trigger
 */
export interface Trigger {
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'cron'?: string;
    /**
     * 
     * @type {number}
     * @memberof Trigger
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'period'?: TriggerPeriodEnum;
    /**
     * 
     * @type {Script}
     * @memberof Trigger
     */
    'script'?: Script;
    /**
     * 
     * @type {Workflow}
     * @memberof Trigger
     */
    'workflow'?: Workflow;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'lastExecuted'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'lastSuccess'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    'lastRunState'?: TriggerLastRunStateEnum;
}

export const TriggerPeriodEnum = {
    Day: 'DAY',
    Hour: 'HOUR'
} as const;

export type TriggerPeriodEnum = typeof TriggerPeriodEnum[keyof typeof TriggerPeriodEnum];
export const TriggerLastRunStateEnum = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type TriggerLastRunStateEnum = typeof TriggerLastRunStateEnum[keyof typeof TriggerLastRunStateEnum];

/**
 * 
 * @export
 * @interface UndoChange
 */
export interface UndoChange {
    /**
     * 
     * @type {string}
     * @memberof UndoChange
     */
    'elementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UndoChange
     */
    'target'?: UndoChangeTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof UndoChange
     */
    'type'?: UndoChangeTypeEnum;
}

export const UndoChangeTargetEnum = {
    Node: 'NODE',
    Edge: 'EDGE',
    Entity: 'ENTITY',
    Relationship: 'RELATIONSHIP',
    Graph: 'GRAPH'
} as const;

export type UndoChangeTargetEnum = typeof UndoChangeTargetEnum[keyof typeof UndoChangeTargetEnum];
export const UndoChangeTypeEnum = {
    Add: 'ADD',
    Update: 'UPDATE',
    Remove: 'REMOVE',
    Layout: 'LAYOUT',
    Move: 'MOVE',
    ClearAll: 'CLEAR_ALL'
} as const;

export type UndoChangeTypeEnum = typeof UndoChangeTypeEnum[keyof typeof UndoChangeTypeEnum];

/**
 * 
 * @export
 * @interface UpdateCatalog
 */
export interface UpdateCatalog {
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalog
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalog
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalog
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UpdateEdge
 */
export interface UpdateEdge {
    /**
     * 
     * @type {RawDataEdge}
     * @memberof UpdateEdge
     */
    'edge': RawDataEdge;
}
/**
 * 
 * @export
 * @interface UpdateEntity
 */
export interface UpdateEntity {
    /**
     * 
     * @type {SchemaEntity}
     * @memberof UpdateEntity
     */
    'entity': SchemaEntity;
    /**
     * 
     * @type {{ [key: string]: FieldChange; }}
     * @memberof UpdateEntity
     */
    'fields': { [key: string]: FieldChange; };
}
/**
 * 
 * @export
 * @interface UpdateInstalledIntegrationDto
 */
export interface UpdateInstalledIntegrationDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInstalledIntegrationDto
     */
    'devMode'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInstalledIntegrationDto
     */
    'allowInsecureHttps'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateInstalledIntegrationDto
     */
    'parameters'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface UpdateNode
 */
export interface UpdateNode {
    /**
     * 
     * @type {RawDataNode}
     * @memberof UpdateNode
     */
    'node': RawDataNode;
}
/**
 * 
 * @export
 * @interface UpdateProject
 */
export interface UpdateProject {
    /**
     * 
     * @type {string}
     * @memberof UpdateProject
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateRelationship
 */
export interface UpdateRelationship {
    /**
     * 
     * @type {SchemaRelationship}
     * @memberof UpdateRelationship
     */
    'relationship': SchemaRelationship;
}
/**
 * 
 * @export
 * @interface UpdateScanTask
 */
export interface UpdateScanTask {
    /**
     * 
     * @type {string}
     * @memberof UpdateScanTask
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateScanTask
     */
    'rank'?: number;
    /**
     * 
     * @type {Array<SelectedCondition>}
     * @memberof UpdateScanTask
     */
    'selectedConditions'?: Array<SelectedCondition>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateScanTask
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {ScanTicketConfig}
     * @memberof UpdateScanTask
     */
    'ticketConfig'?: ScanTicketConfig;
    /**
     * 
     * @type {ScanSlaConfig}
     * @memberof UpdateScanTask
     */
    'slaConfig'?: ScanSlaConfig;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateScanTask
     */
    'parameters'?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScanTask
     */
    'aiEnhancementEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateScript
 */
export interface UpdateScript {
    /**
     * 
     * @type {string}
     * @memberof UpdateScript
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScript
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScript
     */
    'language': UpdateScriptLanguageEnum;
    /**
     * 
     * @type {SaveTrigger}
     * @memberof UpdateScript
     */
    'trigger'?: SaveTrigger;
    /**
     * 
     * @type {string}
     * @memberof UpdateScript
     */
    'target'?: string;
}

export const UpdateScriptLanguageEnum = {
    Python2: 'PYTHON2',
    Python: 'PYTHON'
} as const;

export type UpdateScriptLanguageEnum = typeof UpdateScriptLanguageEnum[keyof typeof UpdateScriptLanguageEnum];

/**
 * 
 * @export
 * @interface UpdateVersionDto
 */
export interface UpdateVersionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateVersionDto
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: UserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'provider'?: UserProviderEnum;
    /**
     * 
     * @type {PartialTenant}
     * @memberof User
     */
    'tenant'?: PartialTenant;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'approved'?: boolean;
}

export const UserRoleEnum = {
    Operator: 'OPERATOR',
    Admin: 'ADMIN',
    User: 'USER',
    ReadOnly: 'READ_ONLY'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];
export const UserProviderEnum = {
    Local: 'LOCAL',
    Sso: 'SSO'
} as const;

export type UserProviderEnum = typeof UserProviderEnum[keyof typeof UserProviderEnum];

/**
 * 
 * @export
 * @interface ValidationErrorResponse
 */
export interface ValidationErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationErrorResponse
     */
    'errors': Array<string>;
}
/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'graphId'?: string;
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'query'?: string;
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'updatedDate'?: string;
}
/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'name'?: string;
    /**
     * 
     * @type {WorkflowDefinition}
     * @memberof Workflow
     */
    'definition'?: WorkflowDefinition;
}
/**
 * 
 * @export
 * @interface WorkflowDefinition
 */
export interface WorkflowDefinition {
    /**
     * 
     * @type {Array<WorkflowNode>}
     * @memberof WorkflowDefinition
     */
    'nodes'?: Array<WorkflowNode>;
    /**
     * 
     * @type {Array<WorkflowEdge>}
     * @memberof WorkflowDefinition
     */
    'edges'?: Array<WorkflowEdge>;
}
/**
 * 
 * @export
 * @interface WorkflowEdge
 */
export interface WorkflowEdge {
    /**
     * 
     * @type {string}
     * @memberof WorkflowEdge
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowEdge
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowEdge
     */
    'targetId'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowNode
 */
export interface WorkflowNode {
    /**
     * 
     * @type {string}
     * @memberof WorkflowNode
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowNode
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowNode
     */
    'descriptorId'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkflowNode
     */
    'config'?: { [key: string]: object; };
    /**
     * 
     * @type {Position}
     * @memberof WorkflowNode
     */
    'position'?: Position;
}

/**
 * AlertsApi - axios parameter creator
 * @export
 */
export const AlertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alert: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('alert', 'id', id)
            const localVarPath = `/alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alerts: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertsApi - functional programming interface
 * @export
 */
export const AlertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alert(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Alert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alert(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alerts(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alerts(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlertsApi - factory interface
 * @export
 */
export const AlertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alert(id: string, options?: any): AxiosPromise<Alert> {
            return localVarFp.alert(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alerts(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoAlert> {
            return localVarFp.alerts(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertsApi - object-oriented interface
 * @export
 * @class AlertsApi
 * @extends {BaseAPI}
 */
export class AlertsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alert(id: string, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alert(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public alerts(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).alerts(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditEventApi - axios parameter creator
 * @export
 */
export const AuditEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditEvent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('auditEvent', 'id', id)
            const localVarPath = `/audit_events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditEvents: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit_events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditEventApi - functional programming interface
 * @export
 */
export const AuditEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditEvent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditEvents(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoAuditEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditEvents(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditEventApi - factory interface
 * @export
 */
export const AuditEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditEventApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditEvent(id: string, options?: any): AxiosPromise<AuditEvent> {
            return localVarFp.auditEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditEvents(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoAuditEvent> {
            return localVarFp.auditEvents(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditEventApi - object-oriented interface
 * @export
 * @class AuditEventApi
 * @extends {BaseAPI}
 */
export class AuditEventApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditEventApi
     */
    public auditEvent(id: string, options?: AxiosRequestConfig) {
        return AuditEventApiFp(this.configuration).auditEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditEventApi
     */
    public auditEvents(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AuditEventApiFp(this.configuration).auditEvents(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): AxiosPromise<boolean> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<boolean> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(login: Login, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BucketsApi - axios parameter creator
 * @export
 */
export const BucketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bucket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bucket', 'id', id)
            const localVarPath = `/buckets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Pageable} pageable 
         * @param {'SCHEMA' | 'DATA'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buckets: async (projectId: string, pageable: Pageable, type?: 'SCHEMA' | 'DATA', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('buckets', 'projectId', projectId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('buckets', 'pageable', pageable)
            const localVarPath = `/projects/{projectId}/buckets`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BucketsApi - functional programming interface
 * @export
 */
export const BucketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BucketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bucket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bucket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bucket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Pageable} pageable 
         * @param {'SCHEMA' | 'DATA'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buckets(projectId: string, pageable: Pageable, type?: 'SCHEMA' | 'DATA', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoBucket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buckets(projectId, pageable, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BucketsApi - factory interface
 * @export
 */
export const BucketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BucketsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bucket(id: string, options?: any): AxiosPromise<Bucket> {
            return localVarFp.bucket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Pageable} pageable 
         * @param {'SCHEMA' | 'DATA'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buckets(projectId: string, pageable: Pageable, type?: 'SCHEMA' | 'DATA', options?: any): AxiosPromise<PageDtoBucket> {
            return localVarFp.buckets(projectId, pageable, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BucketsApi - object-oriented interface
 * @export
 * @class BucketsApi
 * @extends {BaseAPI}
 */
export class BucketsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    public bucket(id: string, options?: AxiosRequestConfig) {
        return BucketsApiFp(this.configuration).bucket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Pageable} pageable 
     * @param {'SCHEMA' | 'DATA'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketsApi
     */
    public buckets(projectId: string, pageable: Pageable, type?: 'SCHEMA' | 'DATA', options?: AxiosRequestConfig) {
        return BucketsApiFp(this.configuration).buckets(projectId, pageable, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CatalogsApi - axios parameter creator
 * @export
 */
export const CatalogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalog: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalog', 'id', id)
            const localVarPath = `/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogIntegrations: async (id: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogIntegrations', 'id', id)
            const localVarPath = `/catalogs/{id}/integrations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogs: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCatalog} createCatalog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCatalog: async (createCatalog: CreateCatalog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCatalog' is not null or undefined
            assertParamExists('createCatalog', 'createCatalog', createCatalog)
            const localVarPath = `/catalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCatalog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCatalog: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCatalog', 'id', id)
            const localVarPath = `/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resyncCatalog: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resyncCatalog', 'id', id)
            const localVarPath = `/catalogs/{id}/resync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCatalog} updateCatalog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalog: async (id: string, updateCatalog: UpdateCatalog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCatalog', 'id', id)
            // verify required parameter 'updateCatalog' is not null or undefined
            assertParamExists('updateCatalog', 'updateCatalog', updateCatalog)
            const localVarPath = `/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCatalog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogsApi - functional programming interface
 * @export
 */
export const CatalogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalog(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalog(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogIntegrations(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogIntegrations(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogs(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoCatalog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogs(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCatalog} createCatalog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCatalog(createCatalog: CreateCatalog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCatalog(createCatalog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCatalog(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCatalog(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resyncCatalog(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resyncCatalog(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCatalog} updateCatalog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCatalog(id: string, updateCatalog: UpdateCatalog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCatalog(id, updateCatalog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogsApi - factory interface
 * @export
 */
export const CatalogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalog(id: string, options?: any): AxiosPromise<Catalog> {
            return localVarFp.catalog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogIntegrations(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoIntegration> {
            return localVarFp.catalogIntegrations(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogs(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoCatalog> {
            return localVarFp.catalogs(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCatalog} createCatalog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCatalog(createCatalog: CreateCatalog, options?: any): AxiosPromise<void> {
            return localVarFp.createCatalog(createCatalog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCatalog(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCatalog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resyncCatalog(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.resyncCatalog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCatalog} updateCatalog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalog(id: string, updateCatalog: UpdateCatalog, options?: any): AxiosPromise<void> {
            return localVarFp.updateCatalog(id, updateCatalog, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogsApi - object-oriented interface
 * @export
 * @class CatalogsApi
 * @extends {BaseAPI}
 */
export class CatalogsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public catalog(id: string, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).catalog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public catalogIntegrations(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).catalogIntegrations(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public catalogs(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).catalogs(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCatalog} createCatalog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public createCatalog(createCatalog: CreateCatalog, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).createCatalog(createCatalog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public deleteCatalog(id: string, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).deleteCatalog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public resyncCatalog(id: string, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).resyncCatalog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCatalog} updateCatalog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogsApi
     */
    public updateCatalog(id: string, updateCatalog: UpdateCatalog, options?: AxiosRequestConfig) {
        return CatalogsApiFp(this.configuration).updateCatalog(id, updateCatalog, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientCredentialsApi - axios parameter creator
 * @export
 */
export const ClientCredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveClientCredential} clientCred 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCredential: async (clientCred: SaveClientCredential, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCred' is not null or undefined
            assertParamExists('createCredential', 'clientCred', clientCred)
            const localVarPath = `/auth/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (clientCred !== undefined) {
                localVarQueryParameter['clientCred'] = clientCred;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credential: async (credentialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentialId' is not null or undefined
            assertParamExists('credential', 'credentialId', credentialId)
            const localVarPath = `/auth/clients/{credentialId}`
                .replace(`{${"credentialId"}}`, encodeURIComponent(String(credentialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentials: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('credentials', 'pageable', pageable)
            const localVarPath = `/auth/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientCredentialsApi - functional programming interface
 * @export
 */
export const ClientCredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientCredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SaveClientCredential} clientCred 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCredential(clientCred: SaveClientCredential, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCredential>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCredential(clientCred, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credential(credentialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCredential>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credential(credentialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentials(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoClientCredential>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentials(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientCredentialsApi - factory interface
 * @export
 */
export const ClientCredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientCredentialsApiFp(configuration)
    return {
        /**
         * 
         * @param {SaveClientCredential} clientCred 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCredential(clientCred: SaveClientCredential, options?: any): AxiosPromise<ClientCredential> {
            return localVarFp.createCredential(clientCred, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credential(credentialId: string, options?: any): AxiosPromise<ClientCredential> {
            return localVarFp.credential(credentialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentials(pageable: Pageable, options?: any): AxiosPromise<PageDtoClientCredential> {
            return localVarFp.credentials(pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientCredentialsApi - object-oriented interface
 * @export
 * @class ClientCredentialsApi
 * @extends {BaseAPI}
 */
export class ClientCredentialsApi extends BaseAPI {
    /**
     * 
     * @param {SaveClientCredential} clientCred 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCredentialsApi
     */
    public createCredential(clientCred: SaveClientCredential, options?: AxiosRequestConfig) {
        return ClientCredentialsApiFp(this.configuration).createCredential(clientCred, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} credentialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCredentialsApi
     */
    public credential(credentialId: string, options?: AxiosRequestConfig) {
        return ClientCredentialsApiFp(this.configuration).credential(credentialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCredentialsApi
     */
    public credentials(pageable: Pageable, options?: AxiosRequestConfig) {
        return ClientCredentialsApiFp(this.configuration).credentials(pageable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommitsApi - axios parameter creator
 * @export
 */
export const CommitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} commitId 
         * @param {Array<AddChange>} addChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChanges: async (commitId: string, addChange: Array<AddChange>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('addChanges', 'commitId', commitId)
            // verify required parameter 'addChange' is not null or undefined
            assertParamExists('addChanges', 'addChange', addChange)
            const localVarPath = `/commits/{commitId}/changes`
                .replace(`{${"commitId"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commitId 
         * @param {ApproveCommit} approveCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCommit: async (commitId: string, approveCommit: ApproveCommit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('approveCommit', 'commitId', commitId)
            // verify required parameter 'approveCommit' is not null or undefined
            assertParamExists('approveCommit', 'approveCommit', approveCommit)
            const localVarPath = `/commits/{commitId}/approve`
                .replace(`{${"commitId"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveCommit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commit: async (commitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('commit', 'commitId', commitId)
            const localVarPath = `/commits/{commitId}`
                .replace(`{${"commitId"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardCommit: async (commitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('discardCommit', 'commitId', commitId)
            const localVarPath = `/commits/{commitId}/discard`
                .replace(`{${"commitId"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<string>} bucketIds 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitsForBuckets: async (projectId: string, bucketIds: Array<string>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listCommitsForBuckets', 'projectId', projectId)
            // verify required parameter 'bucketIds' is not null or undefined
            assertParamExists('listCommitsForBuckets', 'bucketIds', bucketIds)
            const localVarPath = `/projects/{projectId}/commits/buckets`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (bucketIds) {
                localVarQueryParameter['bucketIds'] = bucketIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'SCHEMA' | 'DATA'} graphType 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitsForGraph: async (projectId: string, graphType: 'SCHEMA' | 'DATA', page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listCommitsForGraph', 'projectId', projectId)
            // verify required parameter 'graphType' is not null or undefined
            assertParamExists('listCommitsForGraph', 'graphType', graphType)
            const localVarPath = `/projects/{projectId}/commits`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (graphType !== undefined) {
                localVarQueryParameter['graphType'] = graphType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {OpenCommit} openCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openCommit: async (projectId: string, openCommit: OpenCommit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('openCommit', 'projectId', projectId)
            // verify required parameter 'openCommit' is not null or undefined
            assertParamExists('openCommit', 'openCommit', openCommit)
            const localVarPath = `/projects/{projectId}/commits/open`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openCommit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commitId 
         * @param {UndoChange} undoChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoChange: async (commitId: string, undoChange: UndoChange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('undoChange', 'commitId', commitId)
            // verify required parameter 'undoChange' is not null or undefined
            assertParamExists('undoChange', 'undoChange', undoChange)
            const localVarPath = `/commits/{commitId}/changes/undo`
                .replace(`{${"commitId"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(undoChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommitsApi - functional programming interface
 * @export
 */
export const CommitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} commitId 
         * @param {Array<AddChange>} addChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChanges(commitId: string, addChange: Array<AddChange>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChangeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addChanges(commitId, addChange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commitId 
         * @param {ApproveCommit} approveCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveCommit(commitId: string, approveCommit: ApproveCommit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Commit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveCommit(commitId, approveCommit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commit(commitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Commit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commit(commitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discardCommit(commitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discardCommit(commitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<string>} bucketIds 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommitsForBuckets(projectId: string, bucketIds: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoCommit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommitsForBuckets(projectId, bucketIds, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'SCHEMA' | 'DATA'} graphType 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommitsForGraph(projectId: string, graphType: 'SCHEMA' | 'DATA', page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoCommit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommitsForGraph(projectId, graphType, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {OpenCommit} openCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openCommit(projectId: string, openCommit: OpenCommit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Commit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openCommit(projectId, openCommit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commitId 
         * @param {UndoChange} undoChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoChange(commitId: string, undoChange: UndoChange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChangeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undoChange(commitId, undoChange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommitsApi - factory interface
 * @export
 */
export const CommitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommitsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} commitId 
         * @param {Array<AddChange>} addChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChanges(commitId: string, addChange: Array<AddChange>, options?: any): AxiosPromise<Array<ChangeData>> {
            return localVarFp.addChanges(commitId, addChange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commitId 
         * @param {ApproveCommit} approveCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCommit(commitId: string, approveCommit: ApproveCommit, options?: any): AxiosPromise<Commit> {
            return localVarFp.approveCommit(commitId, approveCommit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commit(commitId: string, options?: any): AxiosPromise<Commit> {
            return localVarFp.commit(commitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardCommit(commitId: string, options?: any): AxiosPromise<void> {
            return localVarFp.discardCommit(commitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Array<string>} bucketIds 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitsForBuckets(projectId: string, bucketIds: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoCommit> {
            return localVarFp.listCommitsForBuckets(projectId, bucketIds, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'SCHEMA' | 'DATA'} graphType 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitsForGraph(projectId: string, graphType: 'SCHEMA' | 'DATA', page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoCommit> {
            return localVarFp.listCommitsForGraph(projectId, graphType, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {OpenCommit} openCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openCommit(projectId: string, openCommit: OpenCommit, options?: any): AxiosPromise<Commit> {
            return localVarFp.openCommit(projectId, openCommit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commitId 
         * @param {UndoChange} undoChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoChange(commitId: string, undoChange: UndoChange, options?: any): AxiosPromise<Array<ChangeData>> {
            return localVarFp.undoChange(commitId, undoChange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommitsApi - object-oriented interface
 * @export
 * @class CommitsApi
 * @extends {BaseAPI}
 */
export class CommitsApi extends BaseAPI {
    /**
     * 
     * @param {string} commitId 
     * @param {Array<AddChange>} addChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public addChanges(commitId: string, addChange: Array<AddChange>, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).addChanges(commitId, addChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commitId 
     * @param {ApproveCommit} approveCommit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public approveCommit(commitId: string, approveCommit: ApproveCommit, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).approveCommit(commitId, approveCommit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public commit(commitId: string, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).commit(commitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public discardCommit(commitId: string, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).discardCommit(commitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Array<string>} bucketIds 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public listCommitsForBuckets(projectId: string, bucketIds: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).listCommitsForBuckets(projectId, bucketIds, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {'SCHEMA' | 'DATA'} graphType 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public listCommitsForGraph(projectId: string, graphType: 'SCHEMA' | 'DATA', page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).listCommitsForGraph(projectId, graphType, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {OpenCommit} openCommit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public openCommit(projectId: string, openCommit: OpenCommit, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).openCommit(projectId, openCommit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commitId 
     * @param {UndoChange} undoChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public undoChange(commitId: string, undoChange: UndoChange, options?: AxiosRequestConfig) {
        return CommitsApiFp(this.configuration).undoChange(commitId, undoChange, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContextApi - axios parameter creator
 * @export
 */
export const ContextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginContext: async (tenant?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/context/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meContext: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/context/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantExists: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('tenantExists', 'name', name)
            const localVarPath = `/context/tenants/{name}/exists`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContextApi - functional programming interface
 * @export
 */
export const ContextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContextApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginContext(tenant?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginContext(tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meContext(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meContext(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantExists(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantExistsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantExists(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContextApi - factory interface
 * @export
 */
export const ContextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContextApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginContext(tenant?: string, options?: any): AxiosPromise<LoginContext> {
            return localVarFp.loginContext(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meContext(options?: any): AxiosPromise<MeContext> {
            return localVarFp.meContext(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantExists(name: string, options?: any): AxiosPromise<TenantExistsResponse> {
            return localVarFp.tenantExists(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContextApi - object-oriented interface
 * @export
 * @class ContextApi
 * @extends {BaseAPI}
 */
export class ContextApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextApi
     */
    public loginContext(tenant?: string, options?: AxiosRequestConfig) {
        return ContextApiFp(this.configuration).loginContext(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextApi
     */
    public meContext(options?: AxiosRequestConfig) {
        return ContextApiFp(this.configuration).meContext(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextApi
     */
    public tenantExists(name: string, options?: AxiosRequestConfig) {
        return ContextApiFp(this.configuration).tenantExists(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeploymentsApi - axios parameter creator
 * @export
 */
export const DeploymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableVersions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('availableVersions', 'id', id)
            const localVarPath = `/saas/deployments/{id}/available_versions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveDeployment} saveDeployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeployment: async (saveDeployment: SaveDeployment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveDeployment' is not null or undefined
            assertParamExists('createDeployment', 'saveDeployment', saveDeployment)
            const localVarPath = `/saas/deployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDeployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeploymentSecret: async (id: string, expirationDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDeploymentSecret', 'id', id)
            const localVarPath = `/saas/deployments/{id}/secrets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (expirationDate !== undefined) {
                localVarQueryParameter['expirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString() :
                    expirationDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeployment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeployment', 'id', id)
            const localVarPath = `/saas/deployments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeploymentSecret: async (id: string, secretId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeploymentSecret', 'id', id)
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('deleteDeploymentSecret', 'secretId', secretId)
            const localVarPath = `/saas/deployments/{id}/secrets/{secretId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"secretId"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deployment', 'id', id)
            const localVarPath = `/saas/deployments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployments: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/saas/deployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeployDeployment: async (id: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('redeployDeployment', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('redeployDeployment', 'version', version)
            const localVarPath = `/saas/deployments/{id}/redeploy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeDeployment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revokeDeployment', 'id', id)
            const localVarPath = `/saas/deployments/{id}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveDeployment} saveDeployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeployment: async (id: string, saveDeployment: SaveDeployment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeployment', 'id', id)
            // verify required parameter 'saveDeployment' is not null or undefined
            assertParamExists('updateDeployment', 'saveDeployment', saveDeployment)
            const localVarPath = `/saas/deployments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDeployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentsApi - functional programming interface
 * @export
 */
export const DeploymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeploymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availableVersions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availableVersions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveDeployment} saveDeployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeployment(saveDeployment: SaveDeployment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeployment(saveDeployment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeploymentSecret(id: string, expirationDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedDeploymentSecretKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeploymentSecret(id, expirationDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeployment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeployment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeploymentSecret(id: string, secretId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeploymentSecret(id, secretId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deployment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deployment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deployments(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoDeployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deployments(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeployDeployment(id: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeployDeployment(id, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeDeployment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeDeployment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveDeployment} saveDeployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeployment(id: string, saveDeployment: SaveDeployment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeployment(id, saveDeployment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeploymentsApi - factory interface
 * @export
 */
export const DeploymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeploymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableVersions(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.availableVersions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveDeployment} saveDeployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeployment(saveDeployment: SaveDeployment, options?: any): AxiosPromise<void> {
            return localVarFp.createDeployment(saveDeployment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [expirationDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeploymentSecret(id: string, expirationDate?: string, options?: any): AxiosPromise<CreatedDeploymentSecretKey> {
            return localVarFp.createDeploymentSecret(id, expirationDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeployment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeployment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeploymentSecret(id: string, secretId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeploymentSecret(id, secretId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployment(id: string, options?: any): AxiosPromise<Deployment> {
            return localVarFp.deployment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployments(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoDeployment> {
            return localVarFp.deployments(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeployDeployment(id: string, version: string, options?: any): AxiosPromise<void> {
            return localVarFp.redeployDeployment(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeDeployment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.revokeDeployment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveDeployment} saveDeployment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeployment(id: string, saveDeployment: SaveDeployment, options?: any): AxiosPromise<void> {
            return localVarFp.updateDeployment(id, saveDeployment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeploymentsApi - object-oriented interface
 * @export
 * @class DeploymentsApi
 * @extends {BaseAPI}
 */
export class DeploymentsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public availableVersions(id: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).availableVersions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveDeployment} saveDeployment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public createDeployment(saveDeployment: SaveDeployment, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).createDeployment(saveDeployment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [expirationDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public createDeploymentSecret(id: string, expirationDate?: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).createDeploymentSecret(id, expirationDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deleteDeployment(id: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).deleteDeployment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} secretId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deleteDeploymentSecret(id: string, secretId: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).deleteDeploymentSecret(id, secretId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deployment(id: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).deployment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deployments(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).deployments(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public redeployDeployment(id: string, version: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).redeployDeployment(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public revokeDeployment(id: string, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).revokeDeployment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveDeployment} saveDeployment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public updateDeployment(id: string, saveDeployment: SaveDeployment, options?: AxiosRequestConfig) {
        return DeploymentsApiFp(this.configuration).updateDeployment(id, saveDeployment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GraphsApi - axios parameter creator
 * @export
 */
export const GraphsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} [query] 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraph: async (projectId: string, query?: string, installedIntegration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('dataGraph', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/graphs/data`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (installedIntegration !== undefined) {
                localVarQueryParameter['installedIntegration'] = installedIntegration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AiRequest} aiRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraphAiAssitant: async (projectId: string, aiRequest: AiRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('dataGraphAiAssitant', 'projectId', projectId)
            // verify required parameter 'aiRequest' is not null or undefined
            assertParamExists('dataGraphAiAssitant', 'aiRequest', aiRequest)
            const localVarPath = `/projects/{projectId}/graphs/data/ai`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraphMeta: async (projectId: string, installedIntegration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('dataGraphMeta', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/graphs/data/meta`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (installedIntegration !== undefined) {
                localVarQueryParameter['installedIntegration'] = installedIntegration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraphRefreshMeta: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('dataGraphRefreshMeta', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/graphs/data/refresh_meta`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} file 
         * @param {string} extension 
         * @param {string} [installIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSchema: async (projectId: string, file: string, extension: string, installIntegration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('exportSchema', 'projectId', projectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('exportSchema', 'file', file)
            // verify required parameter 'extension' is not null or undefined
            assertParamExists('exportSchema', 'extension', extension)
            const localVarPath = `/projects/{projectId}/graphs/schema/export/{file}.{extension}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"file"}}`, encodeURIComponent(String(file)))
                .replace(`{${"extension"}}`, encodeURIComponent(String(extension)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (installIntegration !== undefined) {
                localVarQueryParameter['install_integration'] = installIntegration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FetchedDataRequest} fetchedDataRequest 
         * @param {number} [v] 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchData: async (projectId: string, fetchedDataRequest: FetchedDataRequest, v?: number, installedIntegration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('fetchData', 'projectId', projectId)
            // verify required parameter 'fetchedDataRequest' is not null or undefined
            assertParamExists('fetchData', 'fetchedDataRequest', fetchedDataRequest)
            const localVarPath = `/projects/{projectId}/graphs/data/fetch`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (v !== undefined) {
                localVarQueryParameter['v'] = v;
            }

            if (installedIntegration !== undefined) {
                localVarQueryParameter['installed_integration'] = installedIntegration;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchedDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [installIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaGraph: async (projectId: string, installIntegration?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('schemaGraph', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/graphs/schema`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (installIntegration !== undefined) {
                localVarQueryParameter['installIntegration'] = installIntegration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaGraphOverview: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('schemaGraphOverview', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/graphs/schema/stats`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GraphsApi - functional programming interface
 * @export
 */
export const GraphsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GraphsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} [query] 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataGraph(projectId: string, query?: string, installedIntegration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataGraph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataGraph(projectId, query, installedIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AiRequest} aiRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataGraphAiAssitant(projectId: string, aiRequest: AiRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataGraphAiAssitant(projectId, aiRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataGraphMeta(projectId: string, installedIntegration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataGraphMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataGraphMeta(projectId, installedIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataGraphRefreshMeta(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataGraphRefreshMeta(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} file 
         * @param {string} extension 
         * @param {string} [installIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportSchema(projectId: string, file: string, extension: string, installIntegration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportSchema(projectId, file, extension, installIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FetchedDataRequest} fetchedDataRequest 
         * @param {number} [v] 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchData(projectId: string, fetchedDataRequest: FetchedDataRequest, v?: number, installedIntegration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchedData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchData(projectId, fetchedDataRequest, v, installedIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [installIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schemaGraph(projectId: string, installIntegration?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemaGraph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schemaGraph(projectId, installIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schemaGraphOverview(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schemaGraphOverview(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GraphsApi - factory interface
 * @export
 */
export const GraphsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GraphsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} [query] 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraph(projectId: string, query?: string, installedIntegration?: string, options?: any): AxiosPromise<DataGraph> {
            return localVarFp.dataGraph(projectId, query, installedIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {AiRequest} aiRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraphAiAssitant(projectId: string, aiRequest: AiRequest, options?: any): AxiosPromise<QueryResponse> {
            return localVarFp.dataGraphAiAssitant(projectId, aiRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraphMeta(projectId: string, installedIntegration?: string, options?: any): AxiosPromise<DataGraphMeta> {
            return localVarFp.dataGraphMeta(projectId, installedIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataGraphRefreshMeta(projectId: string, options?: any): AxiosPromise<GraphStats> {
            return localVarFp.dataGraphRefreshMeta(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} file 
         * @param {string} extension 
         * @param {string} [installIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSchema(projectId: string, file: string, extension: string, installIntegration?: string, options?: any): AxiosPromise<object> {
            return localVarFp.exportSchema(projectId, file, extension, installIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FetchedDataRequest} fetchedDataRequest 
         * @param {number} [v] 
         * @param {string} [installedIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchData(projectId: string, fetchedDataRequest: FetchedDataRequest, v?: number, installedIntegration?: string, options?: any): AxiosPromise<FetchedData> {
            return localVarFp.fetchData(projectId, fetchedDataRequest, v, installedIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [installIntegration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaGraph(projectId: string, installIntegration?: string, options?: any): AxiosPromise<SchemaGraph> {
            return localVarFp.schemaGraph(projectId, installIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaGraphOverview(projectId: string, options?: any): AxiosPromise<GraphStats> {
            return localVarFp.schemaGraphOverview(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GraphsApi - object-oriented interface
 * @export
 * @class GraphsApi
 * @extends {BaseAPI}
 */
export class GraphsApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {string} [query] 
     * @param {string} [installedIntegration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public dataGraph(projectId: string, query?: string, installedIntegration?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).dataGraph(projectId, query, installedIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {AiRequest} aiRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public dataGraphAiAssitant(projectId: string, aiRequest: AiRequest, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).dataGraphAiAssitant(projectId, aiRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} [installedIntegration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public dataGraphMeta(projectId: string, installedIntegration?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).dataGraphMeta(projectId, installedIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public dataGraphRefreshMeta(projectId: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).dataGraphRefreshMeta(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} file 
     * @param {string} extension 
     * @param {string} [installIntegration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public exportSchema(projectId: string, file: string, extension: string, installIntegration?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).exportSchema(projectId, file, extension, installIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {FetchedDataRequest} fetchedDataRequest 
     * @param {number} [v] 
     * @param {string} [installedIntegration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public fetchData(projectId: string, fetchedDataRequest: FetchedDataRequest, v?: number, installedIntegration?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).fetchData(projectId, fetchedDataRequest, v, installedIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} [installIntegration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public schemaGraph(projectId: string, installIntegration?: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).schemaGraph(projectId, installIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphsApi
     */
    public schemaGraphOverview(projectId: string, options?: AxiosRequestConfig) {
        return GraphsApiFp(this.configuration).schemaGraphOverview(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async info(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.info(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info(options?: any): AxiosPromise<Info> {
            return localVarFp.info(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public info(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).info(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} installedId 
         * @param {ApproveCommit} approveCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveUploadToIntegration: async (installedId: string, approveCommit: ApproveCommit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('approveUploadToIntegration', 'installedId', installedId)
            // verify required parameter 'approveCommit' is not null or undefined
            assertParamExists('approveUploadToIntegration', 'approveCommit', approveCommit)
            const localVarPath = `/integrations/installed/{installedId}/upload/approve`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveCommit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SaveCustomIntegration} saveCustomIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomIntegration: async (projectId: string, saveCustomIntegration: SaveCustomIntegration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createCustomIntegration', 'projectId', projectId)
            // verify required parameter 'saveCustomIntegration' is not null or undefined
            assertParamExists('createCustomIntegration', 'saveCustomIntegration', saveCustomIntegration)
            const localVarPath = `/projects/{projectId}/integrations/installed`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCustomIntegration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {SaveMapping} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMapping: async (installedId: string, saveMapping: SaveMapping, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('createMapping', 'installedId', installedId)
            // verify required parameter 'saveMapping' is not null or undefined
            assertParamExists('createMapping', 'saveMapping', saveMapping)
            const localVarPath = `/integrations/installed/{installedId}/mappings`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveMapping, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMapping: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMapping', 'id', id)
            const localVarPath = `/mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapping: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMapping', 'id', id)
            const localVarPath = `/mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {boolean} replace 
         * @param {Array<SaveMapping>} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMappings: async (installedId: string, replace: boolean, saveMapping: Array<SaveMapping>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('importMappings', 'installedId', installedId)
            // verify required parameter 'replace' is not null or undefined
            assertParamExists('importMappings', 'replace', replace)
            // verify required parameter 'saveMapping' is not null or undefined
            assertParamExists('importMappings', 'saveMapping', saveMapping)
            const localVarPath = `/integrations/installed/{installedId}/import`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (replace !== undefined) {
                localVarQueryParameter['replace'] = replace;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveMapping, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InstallIntegrationDto} installIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        install: async (id: string, installIntegrationDto: InstallIntegrationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('install', 'id', id)
            // verify required parameter 'installIntegrationDto' is not null or undefined
            assertParamExists('install', 'installIntegrationDto', installIntegrationDto)
            const localVarPath = `/integrations/{id}/install`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(installIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedIntegration: async (installedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('installedIntegration', 'installedId', installedId)
            const localVarPath = `/integrations/installed/{installedId}`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Pageable} pageable 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedIntegrations: async (projectId: string, pageable: Pageable, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('installedIntegrations', 'projectId', projectId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('installedIntegrations', 'pageable', pageable)
            const localVarPath = `/projects/{projectId}/integrations/installed`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedRunLogs: async (runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('installedRunLogs', 'runId', runId)
            const localVarPath = `/integrations/installed/runs/{runId}/logs`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedRuns: async (installedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('installedRuns', 'installedId', installedId)
            const localVarPath = `/integrations/installed/{installedId}/runs`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integration: async (idOrName: string, projectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOrName' is not null or undefined
            assertParamExists('integration', 'idOrName', idOrName)
            const localVarPath = `/integrations/{idOrName}`
                .replace(`{${"idOrName"}}`, encodeURIComponent(String(idOrName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationLogo: async (idOrName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOrName' is not null or undefined
            assertParamExists('integrationLogo', 'idOrName', idOrName)
            const localVarPath = `/integrations/{idOrName}/logo.svg`
                .replace(`{${"idOrName"}}`, encodeURIComponent(String(idOrName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationParameters: async (idOrName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOrName' is not null or undefined
            assertParamExists('integrationParameters', 'idOrName', idOrName)
            const localVarPath = `/integrations/{idOrName}/parameters`
                .replace(`{${"idOrName"}}`, encodeURIComponent(String(idOrName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationSchema: async (idOrName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOrName' is not null or undefined
            assertParamExists('integrationSchema', 'idOrName', idOrName)
            const localVarPath = `/integrations/{idOrName}/schema`
                .replace(`{${"idOrName"}}`, encodeURIComponent(String(idOrName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrations: async (projectId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('integrations', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/integrations`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappings: async (installedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('listMappings', 'installedId', installedId)
            const localVarPath = `/integrations/installed/{installedId}/mappings`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runInstalledIntegration: async (installedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('runInstalledIntegration', 'installedId', installedId)
            const localVarPath = `/integrations/installed/{installedId}/runs`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstall: async (installedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('uninstall', 'installedId', installedId)
            const localVarPath = `/integrations/installed/{installedId}`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {UpdateInstalledIntegrationDto} updateInstalledIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstall: async (installedId: string, updateInstalledIntegrationDto: UpdateInstalledIntegrationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('updateInstall', 'installedId', installedId)
            // verify required parameter 'updateInstalledIntegrationDto' is not null or undefined
            assertParamExists('updateInstall', 'updateInstalledIntegrationDto', updateInstalledIntegrationDto)
            const localVarPath = `/integrations/installed/{installedId}`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInstalledIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveMapping} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMapping: async (id: string, saveMapping: SaveMapping, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMapping', 'id', id)
            // verify required parameter 'saveMapping' is not null or undefined
            assertParamExists('updateMapping', 'saveMapping', saveMapping)
            const localVarPath = `/mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveMapping, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {UpdateVersionDto} updateVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion: async (installedId: string, updateVersionDto: UpdateVersionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('updateVersion', 'installedId', installedId)
            // verify required parameter 'updateVersionDto' is not null or undefined
            assertParamExists('updateVersion', 'updateVersionDto', updateVersionDto)
            const localVarPath = `/integrations/installed/{installedId}/update_version`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedId 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToIntegration: async (installedId: string, files: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedId' is not null or undefined
            assertParamExists('uploadToIntegration', 'installedId', installedId)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadToIntegration', 'files', files)
            const localVarPath = `/integrations/installed/{installedId}/upload`
                .replace(`{${"installedId"}}`, encodeURIComponent(String(installedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} installedId 
         * @param {ApproveCommit} approveCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveUploadToIntegration(installedId: string, approveCommit: ApproveCommit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveUploadToIntegration(installedId, approveCommit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SaveCustomIntegration} saveCustomIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomIntegration(projectId: string, saveCustomIntegration: SaveCustomIntegration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomIntegration(projectId, saveCustomIntegration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {SaveMapping} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMapping(installedId: string, saveMapping: SaveMapping, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMapping(installedId, saveMapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMapping(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMapping(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapping(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapping(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {boolean} replace 
         * @param {Array<SaveMapping>} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importMappings(installedId: string, replace: boolean, saveMapping: Array<SaveMapping>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importMappings(installedId, replace, saveMapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {InstallIntegrationDto} installIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async install(id: string, installIntegrationDto: InstallIntegrationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.install(id, installIntegrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedIntegration(installedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstalledIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedIntegration(installedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Pageable} pageable 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedIntegrations(projectId: string, pageable: Pageable, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoInstalledIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedIntegrations(projectId, pageable, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedRunLogs(runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedRunLogs(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedRuns(installedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationRunDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedRuns(installedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integration(idOrName: string, projectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Integration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integration(idOrName, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationLogo(idOrName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationLogo(idOrName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationParameters(idOrName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DescribeParam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationParameters(idOrName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationSchema(idOrName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemaGraph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationSchema(idOrName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrations(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrations(projectId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMappings(installedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMappings(installedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runInstalledIntegration(installedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runInstalledIntegration(installedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uninstall(installedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uninstall(installedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {UpdateInstalledIntegrationDto} updateInstalledIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInstall(installedId: string, updateInstalledIntegrationDto: UpdateInstalledIntegrationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInstall(installedId, updateInstalledIntegrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveMapping} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMapping(id: string, saveMapping: SaveMapping, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMapping(id, saveMapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {UpdateVersionDto} updateVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVersion(installedId: string, updateVersionDto: UpdateVersionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVersion(installedId, updateVersionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedId 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadToIntegration(installedId: string, files: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Change>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadToIntegration(installedId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} installedId 
         * @param {ApproveCommit} approveCommit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveUploadToIntegration(installedId: string, approveCommit: ApproveCommit, options?: any): AxiosPromise<void> {
            return localVarFp.approveUploadToIntegration(installedId, approveCommit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SaveCustomIntegration} saveCustomIntegration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomIntegration(projectId: string, saveCustomIntegration: SaveCustomIntegration, options?: any): AxiosPromise<void> {
            return localVarFp.createCustomIntegration(projectId, saveCustomIntegration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {SaveMapping} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMapping(installedId: string, saveMapping: SaveMapping, options?: any): AxiosPromise<void> {
            return localVarFp.createMapping(installedId, saveMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMapping(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMapping(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapping(id: string, options?: any): AxiosPromise<Mapping> {
            return localVarFp.getMapping(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {boolean} replace 
         * @param {Array<SaveMapping>} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMappings(installedId: string, replace: boolean, saveMapping: Array<SaveMapping>, options?: any): AxiosPromise<void> {
            return localVarFp.importMappings(installedId, replace, saveMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InstallIntegrationDto} installIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        install(id: string, installIntegrationDto: InstallIntegrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.install(id, installIntegrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedIntegration(installedId: string, options?: any): AxiosPromise<InstalledIntegration> {
            return localVarFp.installedIntegration(installedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Pageable} pageable 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedIntegrations(projectId: string, pageable: Pageable, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoInstalledIntegration> {
            return localVarFp.installedIntegrations(projectId, pageable, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedRunLogs(runId: string, options?: any): AxiosPromise<string> {
            return localVarFp.installedRunLogs(runId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedRuns(installedId: string, options?: any): AxiosPromise<Array<IntegrationRunDto>> {
            return localVarFp.installedRuns(installedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integration(idOrName: string, projectId?: string, options?: any): AxiosPromise<Integration> {
            return localVarFp.integration(idOrName, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationLogo(idOrName: string, options?: any): AxiosPromise<string> {
            return localVarFp.integrationLogo(idOrName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationParameters(idOrName: string, options?: any): AxiosPromise<Array<DescribeParam>> {
            return localVarFp.integrationParameters(idOrName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOrName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationSchema(idOrName: string, options?: any): AxiosPromise<SchemaGraph> {
            return localVarFp.integrationSchema(idOrName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrations(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoIntegration> {
            return localVarFp.integrations(projectId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappings(installedId: string, options?: any): AxiosPromise<Array<Mapping>> {
            return localVarFp.listMappings(installedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runInstalledIntegration(installedId: string, options?: any): AxiosPromise<void> {
            return localVarFp.runInstalledIntegration(installedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstall(installedId: string, options?: any): AxiosPromise<void> {
            return localVarFp.uninstall(installedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {UpdateInstalledIntegrationDto} updateInstalledIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstall(installedId: string, updateInstalledIntegrationDto: UpdateInstalledIntegrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateInstall(installedId, updateInstalledIntegrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveMapping} saveMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMapping(id: string, saveMapping: SaveMapping, options?: any): AxiosPromise<void> {
            return localVarFp.updateMapping(id, saveMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {UpdateVersionDto} updateVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(installedId: string, updateVersionDto: UpdateVersionDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVersion(installedId, updateVersionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedId 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToIntegration(installedId: string, files: Array<File>, options?: any): AxiosPromise<Array<Change>> {
            return localVarFp.uploadToIntegration(installedId, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @param {string} installedId 
     * @param {ApproveCommit} approveCommit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public approveUploadToIntegration(installedId: string, approveCommit: ApproveCommit, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).approveUploadToIntegration(installedId, approveCommit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {SaveCustomIntegration} saveCustomIntegration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public createCustomIntegration(projectId: string, saveCustomIntegration: SaveCustomIntegration, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).createCustomIntegration(projectId, saveCustomIntegration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {SaveMapping} saveMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public createMapping(installedId: string, saveMapping: SaveMapping, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).createMapping(installedId, saveMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public deleteMapping(id: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).deleteMapping(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getMapping(id: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getMapping(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {boolean} replace 
     * @param {Array<SaveMapping>} saveMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public importMappings(installedId: string, replace: boolean, saveMapping: Array<SaveMapping>, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).importMappings(installedId, replace, saveMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {InstallIntegrationDto} installIntegrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public install(id: string, installIntegrationDto: InstallIntegrationDto, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).install(id, installIntegrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public installedIntegration(installedId: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).installedIntegration(installedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Pageable} pageable 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public installedIntegrations(projectId: string, pageable: Pageable, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).installedIntegrations(projectId, pageable, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public installedRunLogs(runId: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).installedRunLogs(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public installedRuns(installedId: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).installedRuns(installedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOrName 
     * @param {string} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integration(idOrName: string, projectId?: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integration(idOrName, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOrName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrationLogo(idOrName: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrationLogo(idOrName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOrName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrationParameters(idOrName: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrationParameters(idOrName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOrName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrationSchema(idOrName: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrationSchema(idOrName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrations(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrations(projectId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public listMappings(installedId: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).listMappings(installedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public runInstalledIntegration(installedId: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).runInstalledIntegration(installedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public uninstall(installedId: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).uninstall(installedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {UpdateInstalledIntegrationDto} updateInstalledIntegrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public updateInstall(installedId: string, updateInstalledIntegrationDto: UpdateInstalledIntegrationDto, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).updateInstall(installedId, updateInstalledIntegrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveMapping} saveMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public updateMapping(id: string, saveMapping: SaveMapping, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).updateMapping(id, saveMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {UpdateVersionDto} updateVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public updateVersion(installedId: string, updateVersionDto: UpdateVersionDto, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).updateVersion(installedId, updateVersionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedId 
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public uploadToIntegration(installedId: string, files: Array<File>, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).uploadToIntegration(installedId, files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IssuesApi - axios parameter creator
 * @export
 */
export const IssuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssue: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteIssue', 'id', id)
            const localVarPath = `/issues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issue: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issue', 'id', id)
            const localVarPath = `/issues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueFilters: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issueFilters', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/issues/filters`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'OPEN' | 'CLOSED'} [state] 
         * @param {string} [shortCode] 
         * @param {string} [priority] 
         * @param {Array<string>} [entities] 
         * @param {Array<string>} [labels] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issues: async (projectId: string, state?: 'OPEN' | 'CLOSED', shortCode?: string, priority?: string, entities?: Array<string>, labels?: Array<string>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('issues', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/issues/`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (shortCode !== undefined) {
                localVarQueryParameter['shortCode'] = shortCode;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (entities) {
                localVarQueryParameter['entities'] = entities;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueMetadata: async (id: string, requestBody: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIssueMetadata', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateIssueMetadata', 'requestBody', requestBody)
            const localVarPath = `/issues/{id}/user_defined_metadata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuesApi - functional programming interface
 * @export
 */
export const IssuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIssue(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIssue(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issue(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Issue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issue(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueFilters(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IssueFilterSectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issueFilters(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'OPEN' | 'CLOSED'} [state] 
         * @param {string} [shortCode] 
         * @param {string} [priority] 
         * @param {Array<string>} [entities] 
         * @param {Array<string>} [labels] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issues(projectId: string, state?: 'OPEN' | 'CLOSED', shortCode?: string, priority?: string, entities?: Array<string>, labels?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoIssue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issues(projectId, state, shortCode, priority, entities, labels, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssueMetadata(id: string, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssueMetadata(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuesApi - factory interface
 * @export
 */
export const IssuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssue(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIssue(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issue(id: string, options?: any): AxiosPromise<Issue> {
            return localVarFp.issue(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueFilters(projectId: string, options?: any): AxiosPromise<Array<IssueFilterSectionDto>> {
            return localVarFp.issueFilters(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'OPEN' | 'CLOSED'} [state] 
         * @param {string} [shortCode] 
         * @param {string} [priority] 
         * @param {Array<string>} [entities] 
         * @param {Array<string>} [labels] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issues(projectId: string, state?: 'OPEN' | 'CLOSED', shortCode?: string, priority?: string, entities?: Array<string>, labels?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoIssue> {
            return localVarFp.issues(projectId, state, shortCode, priority, entities, labels, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueMetadata(id: string, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<void> {
            return localVarFp.updateIssueMetadata(id, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IssuesApi - object-oriented interface
 * @export
 * @class IssuesApi
 * @extends {BaseAPI}
 */
export class IssuesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesApi
     */
    public deleteIssue(id: string, options?: AxiosRequestConfig) {
        return IssuesApiFp(this.configuration).deleteIssue(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesApi
     */
    public issue(id: string, options?: AxiosRequestConfig) {
        return IssuesApiFp(this.configuration).issue(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesApi
     */
    public issueFilters(projectId: string, options?: AxiosRequestConfig) {
        return IssuesApiFp(this.configuration).issueFilters(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {'OPEN' | 'CLOSED'} [state] 
     * @param {string} [shortCode] 
     * @param {string} [priority] 
     * @param {Array<string>} [entities] 
     * @param {Array<string>} [labels] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesApi
     */
    public issues(projectId: string, state?: 'OPEN' | 'CLOSED', shortCode?: string, priority?: string, entities?: Array<string>, labels?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return IssuesApiFp(this.configuration).issues(projectId, state, shortCode, priority, entities, labels, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesApi
     */
    public updateIssueMetadata(id: string, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return IssuesApiFp(this.configuration).updateIssueMetadata(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpsIntegrationsApi - axios parameter creator
 * @export
 */
export const OpsIntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openIntegrationCommit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('openIntegrationCommit', 'id', id)
            const localVarPath = `/ops/integrations/{id}/commits/open`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpsIntegrationsApi - functional programming interface
 * @export
 */
export const OpsIntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpsIntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openIntegrationCommit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Commit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openIntegrationCommit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpsIntegrationsApi - factory interface
 * @export
 */
export const OpsIntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpsIntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openIntegrationCommit(id: string, options?: any): AxiosPromise<Commit> {
            return localVarFp.openIntegrationCommit(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpsIntegrationsApi - object-oriented interface
 * @export
 * @class OpsIntegrationsApi
 * @extends {BaseAPI}
 */
export class OpsIntegrationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsIntegrationsApi
     */
    public openIntegrationCommit(id: string, options?: AxiosRequestConfig) {
        return OpsIntegrationsApiFp(this.configuration).openIntegrationCommit(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpsSettingsApi - axios parameter creator
 * @export
 */
export const OpsSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmosDbSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ops/settings/cosmosdb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CosmosDbSettings} cosmosDbSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCosmosDbSettings: async (cosmosDbSettings: CosmosDbSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cosmosDbSettings' is not null or undefined
            assertParamExists('updateCosmosDbSettings', 'cosmosDbSettings', cosmosDbSettings)
            const localVarPath = `/ops/settings/cosmosdb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cosmosDbSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpsSettingsApi - functional programming interface
 * @export
 */
export const OpsSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpsSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cosmosDbSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CosmosDbSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cosmosDbSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CosmosDbSettings} cosmosDbSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCosmosDbSettings(cosmosDbSettings: CosmosDbSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCosmosDbSettings(cosmosDbSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpsSettingsApi - factory interface
 * @export
 */
export const OpsSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpsSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmosDbSettings(options?: any): AxiosPromise<CosmosDbSettings> {
            return localVarFp.cosmosDbSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CosmosDbSettings} cosmosDbSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCosmosDbSettings(cosmosDbSettings: CosmosDbSettings, options?: any): AxiosPromise<void> {
            return localVarFp.updateCosmosDbSettings(cosmosDbSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpsSettingsApi - object-oriented interface
 * @export
 * @class OpsSettingsApi
 * @extends {BaseAPI}
 */
export class OpsSettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsSettingsApi
     */
    public cosmosDbSettings(options?: AxiosRequestConfig) {
        return OpsSettingsApiFp(this.configuration).cosmosDbSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CosmosDbSettings} cosmosDbSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsSettingsApi
     */
    public updateCosmosDbSettings(cosmosDbSettings: CosmosDbSettings, options?: AxiosRequestConfig) {
        return OpsSettingsApiFp(this.configuration).updateCosmosDbSettings(cosmosDbSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpsTenantsApi - axios parameter creator
 * @export
 */
export const OpsTenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveTenantDto} saveTenantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (saveTenantDto: SaveTenantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveTenantDto' is not null or undefined
            assertParamExists('createTenant', 'saveTenantDto', saveTenantDto)
            const localVarPath = `/ops/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveTenantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTenant', 'id', id)
            const localVarPath = `/ops/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tenant by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantById', 'id', id)
            const localVarPath = `/ops/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenants: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ops/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpsTenantsApi - functional programming interface
 * @export
 */
export const OpsTenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpsTenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SaveTenantDto} saveTenantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(saveTenantDto: SaveTenantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(saveTenantDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tenant by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenants(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoTenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpsTenantsApi - factory interface
 * @export
 */
export const OpsTenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpsTenantsApiFp(configuration)
    return {
        /**
         * 
         * @param {SaveTenantDto} saveTenantDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(saveTenantDto: SaveTenantDto, options?: any): AxiosPromise<void> {
            return localVarFp.createTenant(saveTenantDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tenant by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantById(id: string, options?: any): AxiosPromise<Tenant> {
            return localVarFp.tenantById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenants(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoTenant> {
            return localVarFp.tenants(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpsTenantsApi - object-oriented interface
 * @export
 * @class OpsTenantsApi
 * @extends {BaseAPI}
 */
export class OpsTenantsApi extends BaseAPI {
    /**
     * 
     * @param {SaveTenantDto} saveTenantDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsTenantsApi
     */
    public createTenant(saveTenantDto: SaveTenantDto, options?: AxiosRequestConfig) {
        return OpsTenantsApiFp(this.configuration).createTenant(saveTenantDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsTenantsApi
     */
    public deleteTenant(id: string, options?: AxiosRequestConfig) {
        return OpsTenantsApiFp(this.configuration).deleteTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tenant by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsTenantsApi
     */
    public tenantById(id: string, options?: AxiosRequestConfig) {
        return OpsTenantsApiFp(this.configuration).tenantById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpsTenantsApi
     */
    public tenants(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return OpsTenantsApiFp(this.configuration).tenants(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (createProject: CreateProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProject' is not null or undefined
            assertParamExists('createProject', 'createProject', createProject)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        project: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('project', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projects: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: string, updateProject: UpdateProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'updateProject' is not null or undefined
            assertParamExists('updateProject', 'updateProject', updateProject)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(createProject: CreateProject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(createProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async project(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.project(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projects(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projects(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: string, updateProject: UpdateProject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, updateProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(createProject: CreateProject, options?: any): AxiosPromise<void> {
            return localVarFp.createProject(createProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        project(id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.project(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projects(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoProject> {
            return localVarFp.projects(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: string, updateProject: UpdateProject, options?: any): AxiosPromise<void> {
            return localVarFp.updateProject(id, updateProject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @param {CreateProject} createProject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(createProject: CreateProject, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(createProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public project(id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).project(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projects(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projects(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProject} updateProject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(id: string, updateProject: UpdateProject, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(id, updateProject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReportBuilderColumn: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addReportBuilderColumn', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addReportBuilderColumn', 'body', body)
            const localVarPath = `/reports/{id}/columns`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SaveReportDto} saveReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (projectId: string, saveReportDto: SaveReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createReport', 'projectId', projectId)
            // verify required parameter 'saveReportDto' is not null or undefined
            assertParamExists('createReport', 'saveReportDto', saveReportDto)
            const localVarPath = `/projects/{projectId}/reports`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeneratedReport: async (genReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genReportId' is not null or undefined
            assertParamExists('deleteGeneratedReport', 'genReportId', genReportId)
            const localVarPath = `/generated_reports/{genReportId}`
                .replace(`{${"genReportId"}}`, encodeURIComponent(String(genReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReport', 'id', id)
            const localVarPath = `/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportBuilderColumn: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReportBuilderColumn', 'id', id)
            const localVarPath = `/reports/columns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeneratedReport: async (genReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genReportId' is not null or undefined
            assertParamExists('downloadGeneratedReport', 'genReportId', genReportId)
            const localVarPath = `/generated_reports/{genReportId}/download`
                .replace(`{${"genReportId"}}`, encodeURIComponent(String(genReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatedReport: async (genReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genReportId' is not null or undefined
            assertParamExists('generatedReport', 'genReportId', genReportId)
            const localVarPath = `/generated_reports/{genReportId}`
                .replace(`{${"genReportId"}}`, encodeURIComponent(String(genReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatedReports: async (id: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generatedReports', 'id', id)
            const localVarPath = `/reports/{id}/generated_reports`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        report: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('report', 'id', id)
            const localVarPath = `/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBuilderColumns: async (id: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reportBuilderColumns', 'id', id)
            const localVarPath = `/reports/{id}/columns`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reports: async (projectId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('reports', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/reports`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runReport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runReport', 'id', id)
            const localVarPath = `/reports/{id}/run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveReportDto} saveReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (id: string, saveReportDto: SaveReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReport', 'id', id)
            // verify required parameter 'saveReportDto' is not null or undefined
            assertParamExists('updateReport', 'saveReportDto', saveReportDto)
            const localVarPath = `/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportBuilderColumn: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReportBuilderColumn', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateReportBuilderColumn', 'body', body)
            const localVarPath = `/reports/columns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReportBuilderColumn(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReportBuilderColumn(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SaveReportDto} saveReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(projectId: string, saveReportDto: SaveReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(projectId, saveReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGeneratedReport(genReportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGeneratedReport(genReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportBuilderColumn(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportBuilderColumn(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadGeneratedReport(genReportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadGeneratedReport(genReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatedReport(genReportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatedReport(genReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatedReports(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoGeneratedReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatedReports(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async report(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.report(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportBuilderColumns(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoReportBuilderColumnDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportBuilderColumns(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reports(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reports(projectId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runReport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveReportDto} saveReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(id: string, saveReportDto: SaveReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(id, saveReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportBuilderColumn(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportBuilderColumn(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReportBuilderColumn(id: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.addReportBuilderColumn(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {SaveReportDto} saveReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(projectId: string, saveReportDto: SaveReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.createReport(projectId, saveReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeneratedReport(genReportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGeneratedReport(genReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportBuilderColumn(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportBuilderColumn(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeneratedReport(genReportId: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadGeneratedReport(genReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} genReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatedReport(genReportId: string, options?: any): AxiosPromise<GeneratedReport> {
            return localVarFp.generatedReport(genReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatedReports(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoGeneratedReport> {
            return localVarFp.generatedReports(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        report(id: string, options?: any): AxiosPromise<Report> {
            return localVarFp.report(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBuilderColumns(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoReportBuilderColumnDto> {
            return localVarFp.reportBuilderColumns(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reports(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoReport> {
            return localVarFp.reports(projectId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runReport(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveReportDto} saveReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(id: string, saveReportDto: SaveReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateReport(id, saveReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportBuilderColumn(id: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.updateReportBuilderColumn(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public addReportBuilderColumn(id: string, body: object, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).addReportBuilderColumn(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {SaveReportDto} saveReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public createReport(projectId: string, saveReportDto: SaveReportDto, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).createReport(projectId, saveReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} genReportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteGeneratedReport(genReportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteGeneratedReport(genReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReport(id: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReportBuilderColumn(id: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReportBuilderColumn(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} genReportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadGeneratedReport(genReportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadGeneratedReport(genReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} genReportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public generatedReport(genReportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).generatedReport(genReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public generatedReports(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).generatedReports(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public report(id: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).report(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportBuilderColumns(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportBuilderColumns(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reports(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reports(projectId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public runReport(id: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).runReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveReportDto} saveReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateReport(id: string, saveReportDto: SaveReportDto, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).updateReport(id, saveReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateReportBuilderColumn(id: string, body: object, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).updateReportBuilderColumn(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScansApi - axios parameter creator
 * @export
 */
export const ScansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionDefinitions: async (id: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionDefinitions', 'id', id)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('conditionDefinitions', 'projectId', projectId)
            const localVarPath = `/scan_definitions/{id}/condition_definitions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {CreateScanTask} createScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScanTask: async (installedScanId: string, createScanTask: CreateScanTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedScanId' is not null or undefined
            assertParamExists('createScanTask', 'installedScanId', installedScanId)
            // verify required parameter 'createScanTask' is not null or undefined
            assertParamExists('createScanTask', 'createScanTask', createScanTask)
            const localVarPath = `/installed_scans/{installedScanId}/scan_tasks`
                .replace(`{${"installedScanId"}}`, encodeURIComponent(String(installedScanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScanTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScanTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScanTask', 'id', id)
            const localVarPath = `/scan_tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {boolean} replace 
         * @param {Array<CreateScanTask>} createScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importScanTasks: async (installedScanId: string, replace: boolean, createScanTask: Array<CreateScanTask>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedScanId' is not null or undefined
            assertParamExists('importScanTasks', 'installedScanId', installedScanId)
            // verify required parameter 'replace' is not null or undefined
            assertParamExists('importScanTasks', 'replace', replace)
            // verify required parameter 'createScanTask' is not null or undefined
            assertParamExists('importScanTasks', 'createScanTask', createScanTask)
            const localVarPath = `/installed_scans/{installedScanId}/scan_tasks/import`
                .replace(`{${"installedScanId"}}`, encodeURIComponent(String(installedScanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (replace !== undefined) {
                localVarQueryParameter['replace'] = replace;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScanTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InstallScan} installScan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installScan: async (id: string, installScan: InstallScan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('installScan', 'id', id)
            // verify required parameter 'installScan' is not null or undefined
            assertParamExists('installScan', 'installScan', installScan)
            const localVarPath = `/scan_definitions/{id}/install`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(installScan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedScan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('installedScan', 'id', id)
            const localVarPath = `/installed_scans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [category] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedScans: async (projectId: string, category?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('installedScans', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/installed_scans`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedScansCategories: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('installedScansCategories', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/installed_scans/categories`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runScan', 'id', id)
            const localVarPath = `/installed_scans/{id}/run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScanTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runScanTask', 'id', id)
            const localVarPath = `/scan_tasks/{id}/run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDefinition: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanDefinition', 'id', id)
            const localVarPath = `/scan_definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDefinitionParameters: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanDefinitionParameters', 'id', id)
            const localVarPath = `/scan_definitions/{id}/parameters`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDefinitions: async (projectId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scanDefinitions', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/scan_definitions`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanTask', 'id', id)
            const localVarPath = `/scan_tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanTasks: async (installedScanId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installedScanId' is not null or undefined
            assertParamExists('scanTasks', 'installedScanId', installedScanId)
            const localVarPath = `/installed_scans/{installedScanId}/scan_tasks`
                .replace(`{${"installedScanId"}}`, encodeURIComponent(String(installedScanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstallScan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uninstallScan', 'id', id)
            const localVarPath = `/installed_scans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateScanTask} updateScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScanTask: async (id: string, updateScanTask: UpdateScanTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScanTask', 'id', id)
            // verify required parameter 'updateScanTask' is not null or undefined
            assertParamExists('updateScanTask', 'updateScanTask', updateScanTask)
            const localVarPath = `/scan_tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScanTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScansApi - functional programming interface
 * @export
 */
export const ScansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionDefinitions(id: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScanDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionDefinitions(id, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {CreateScanTask} createScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScanTask(installedScanId: string, createScanTask: CreateScanTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScanTask(installedScanId, createScanTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScanTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScanTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {boolean} replace 
         * @param {Array<CreateScanTask>} createScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importScanTasks(installedScanId: string, replace: boolean, createScanTask: Array<CreateScanTask>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importScanTasks(installedScanId, replace, createScanTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {InstallScan} installScan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installScan(id: string, installScan: InstallScan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installScan(id, installScan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedScan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstalledScan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedScan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [category] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedScans(projectId: string, category?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoInstalledScan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedScans(projectId, category, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installedScansCategories(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScanCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installedScansCategories(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runScan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runScan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runScanTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runScanTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanDefinition(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScanDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanDefinition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanDefinitionParameters(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DescribeParam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanDefinitionParameters(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanDefinitions(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoScanDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanDefinitions(projectId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScanTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanTasks(installedScanId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoScanTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanTasks(installedScanId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uninstallScan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uninstallScan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateScanTask} updateScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScanTask(id: string, updateScanTask: UpdateScanTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScanTask(id, updateScanTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScansApi - factory interface
 * @export
 */
export const ScansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScansApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionDefinitions(id: string, projectId: string, options?: any): AxiosPromise<Array<ScanDefinition>> {
            return localVarFp.conditionDefinitions(id, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {CreateScanTask} createScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScanTask(installedScanId: string, createScanTask: CreateScanTask, options?: any): AxiosPromise<void> {
            return localVarFp.createScanTask(installedScanId, createScanTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScanTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScanTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {boolean} replace 
         * @param {Array<CreateScanTask>} createScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importScanTasks(installedScanId: string, replace: boolean, createScanTask: Array<CreateScanTask>, options?: any): AxiosPromise<void> {
            return localVarFp.importScanTasks(installedScanId, replace, createScanTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InstallScan} installScan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installScan(id: string, installScan: InstallScan, options?: any): AxiosPromise<void> {
            return localVarFp.installScan(id, installScan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedScan(id: string, options?: any): AxiosPromise<InstalledScan> {
            return localVarFp.installedScan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [category] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedScans(projectId: string, category?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoInstalledScan> {
            return localVarFp.installedScans(projectId, category, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installedScansCategories(projectId: string, options?: any): AxiosPromise<Array<ScanCategory>> {
            return localVarFp.installedScansCategories(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScan(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runScan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScanTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runScanTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDefinition(id: string, options?: any): AxiosPromise<ScanDefinition> {
            return localVarFp.scanDefinition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDefinitionParameters(id: string, options?: any): AxiosPromise<Array<DescribeParam>> {
            return localVarFp.scanDefinitionParameters(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDefinitions(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoScanDefinition> {
            return localVarFp.scanDefinitions(projectId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanTask(id: string, options?: any): AxiosPromise<ScanTask> {
            return localVarFp.scanTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} installedScanId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanTasks(installedScanId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoScanTask> {
            return localVarFp.scanTasks(installedScanId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstallScan(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.uninstallScan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateScanTask} updateScanTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScanTask(id: string, updateScanTask: UpdateScanTask, options?: any): AxiosPromise<void> {
            return localVarFp.updateScanTask(id, updateScanTask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScansApi - object-oriented interface
 * @export
 * @class ScansApi
 * @extends {BaseAPI}
 */
export class ScansApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public conditionDefinitions(id: string, projectId: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).conditionDefinitions(id, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedScanId 
     * @param {CreateScanTask} createScanTask 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public createScanTask(installedScanId: string, createScanTask: CreateScanTask, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).createScanTask(installedScanId, createScanTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public deleteScanTask(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).deleteScanTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedScanId 
     * @param {boolean} replace 
     * @param {Array<CreateScanTask>} createScanTask 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public importScanTasks(installedScanId: string, replace: boolean, createScanTask: Array<CreateScanTask>, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).importScanTasks(installedScanId, replace, createScanTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {InstallScan} installScan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public installScan(id: string, installScan: InstallScan, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).installScan(id, installScan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public installedScan(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).installedScan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} [category] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public installedScans(projectId: string, category?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).installedScans(projectId, category, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public installedScansCategories(projectId: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).installedScansCategories(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public runScan(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).runScan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public runScanTask(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).runScanTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public scanDefinition(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).scanDefinition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public scanDefinitionParameters(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).scanDefinitionParameters(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public scanDefinitions(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).scanDefinitions(projectId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public scanTask(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).scanTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} installedScanId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public scanTasks(installedScanId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).scanTasks(installedScanId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public uninstallScan(id: string, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).uninstallScan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateScanTask} updateScanTask 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScansApi
     */
    public updateScanTask(id: string, updateScanTask: UpdateScanTask, options?: AxiosRequestConfig) {
        return ScansApiFp(this.configuration).updateScanTask(id, updateScanTask, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScriptsApi - axios parameter creator
 * @export
 */
export const ScriptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateScript} createScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScript: async (projectId: string, createScript: CreateScript, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createScript', 'projectId', projectId)
            // verify required parameter 'createScript' is not null or undefined
            assertParamExists('createScript', 'createScript', createScript)
            const localVarPath = `/projects/{projectId}/scripts`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScript, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScript: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScript', 'id', id)
            const localVarPath = `/scripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScript: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runScript', 'id', id)
            const localVarPath = `/scripts/{id}/run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {RunTestDto} runTestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestScript: async (projectId: string, runTestDto: RunTestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('runTestScript', 'projectId', projectId)
            // verify required parameter 'runTestDto' is not null or undefined
            assertParamExists('runTestScript', 'runTestDto', runTestDto)
            const localVarPath = `/scripts/run_test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runTestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        script: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('script', 'id', id)
            const localVarPath = `/scripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'CONDITION' | 'REPORT'} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scripts: async (projectId: string, type?: 'CONDITION' | 'REPORT', page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('scripts', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/scripts`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateScript} updateScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScript: async (id: string, updateScript: UpdateScript, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScript', 'id', id)
            // verify required parameter 'updateScript' is not null or undefined
            assertParamExists('updateScript', 'updateScript', updateScript)
            const localVarPath = `/scripts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScript, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScriptsApi - functional programming interface
 * @export
 */
export const ScriptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScriptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateScript} createScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScript(projectId: string, createScript: CreateScript, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScript(projectId, createScript, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScript(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScript(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runScript(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runScript(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {RunTestDto} runTestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestScript(projectId: string, runTestDto: RunTestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestScript(projectId, runTestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async script(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Script>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.script(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'CONDITION' | 'REPORT'} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scripts(projectId: string, type?: 'CONDITION' | 'REPORT', page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoScript>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scripts(projectId, type, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateScript} updateScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScript(id: string, updateScript: UpdateScript, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScript(id, updateScript, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScriptsApi - factory interface
 * @export
 */
export const ScriptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScriptsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateScript} createScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScript(projectId: string, createScript: CreateScript, options?: any): AxiosPromise<void> {
            return localVarFp.createScript(projectId, createScript, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScript(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScript(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScript(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runScript(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {RunTestDto} runTestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestScript(projectId: string, runTestDto: RunTestDto, options?: any): AxiosPromise<string> {
            return localVarFp.runTestScript(projectId, runTestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        script(id: string, options?: any): AxiosPromise<Script> {
            return localVarFp.script(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'CONDITION' | 'REPORT'} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scripts(projectId: string, type?: 'CONDITION' | 'REPORT', page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoScript> {
            return localVarFp.scripts(projectId, type, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateScript} updateScript 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScript(id: string, updateScript: UpdateScript, options?: any): AxiosPromise<void> {
            return localVarFp.updateScript(id, updateScript, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScriptsApi - object-oriented interface
 * @export
 * @class ScriptsApi
 * @extends {BaseAPI}
 */
export class ScriptsApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {CreateScript} createScript 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public createScript(projectId: string, createScript: CreateScript, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).createScript(projectId, createScript, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public deleteScript(id: string, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).deleteScript(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public runScript(id: string, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).runScript(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {RunTestDto} runTestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public runTestScript(projectId: string, runTestDto: RunTestDto, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).runTestScript(projectId, runTestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public script(id: string, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).script(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {'CONDITION' | 'REPORT'} [type] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public scripts(projectId: string, type?: 'CONDITION' | 'REPORT', page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).scripts(projectId, type, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateScript} updateScript 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public updateScript(id: string, updateScript: UpdateScript, options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).updateScript(id, updateScript, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SsoProvidersApi - axios parameter creator
 * @export
 */
export const SsoProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableSsoProvider: async (provider: 'MICROSOFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('disableSsoProvider', 'provider', provider)
            const localVarPath = `/sso_providers/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {SaveSsoProvider} saveSsoProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSsoProvider: async (provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('enableSsoProvider', 'provider', provider)
            // verify required parameter 'saveSsoProvider' is not null or undefined
            assertParamExists('enableSsoProvider', 'saveSsoProvider', saveSsoProvider)
            const localVarPath = `/sso_providers/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSsoProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoProvider: async (provider: 'MICROSOFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('ssoProvider', 'provider', provider)
            const localVarPath = `/sso_providers/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoProviders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sso_providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {SaveSsoProvider} saveSsoProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSsoProvider: async (provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('updateSsoProvider', 'provider', provider)
            // verify required parameter 'saveSsoProvider' is not null or undefined
            assertParamExists('updateSsoProvider', 'saveSsoProvider', saveSsoProvider)
            const localVarPath = `/sso_providers/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSsoProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoProvidersApi - functional programming interface
 * @export
 */
export const SsoProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableSsoProvider(provider: 'MICROSOFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableSsoProvider(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {SaveSsoProvider} saveSsoProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableSsoProvider(provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableSsoProvider(provider, saveSsoProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoProvider(provider: 'MICROSOFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoProvider(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoProviders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SsoProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoProviders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {SaveSsoProvider} saveSsoProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSsoProvider(provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSsoProvider(provider, saveSsoProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SsoProvidersApi - factory interface
 * @export
 */
export const SsoProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableSsoProvider(provider: 'MICROSOFT', options?: any): AxiosPromise<void> {
            return localVarFp.disableSsoProvider(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {SaveSsoProvider} saveSsoProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSsoProvider(provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options?: any): AxiosPromise<void> {
            return localVarFp.enableSsoProvider(provider, saveSsoProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoProvider(provider: 'MICROSOFT', options?: any): AxiosPromise<SsoProvider> {
            return localVarFp.ssoProvider(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoProviders(options?: any): AxiosPromise<Array<SsoProvider>> {
            return localVarFp.ssoProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'MICROSOFT'} provider 
         * @param {SaveSsoProvider} saveSsoProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSsoProvider(provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options?: any): AxiosPromise<void> {
            return localVarFp.updateSsoProvider(provider, saveSsoProvider, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoProvidersApi - object-oriented interface
 * @export
 * @class SsoProvidersApi
 * @extends {BaseAPI}
 */
export class SsoProvidersApi extends BaseAPI {
    /**
     * 
     * @param {'MICROSOFT'} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoProvidersApi
     */
    public disableSsoProvider(provider: 'MICROSOFT', options?: AxiosRequestConfig) {
        return SsoProvidersApiFp(this.configuration).disableSsoProvider(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'MICROSOFT'} provider 
     * @param {SaveSsoProvider} saveSsoProvider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoProvidersApi
     */
    public enableSsoProvider(provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options?: AxiosRequestConfig) {
        return SsoProvidersApiFp(this.configuration).enableSsoProvider(provider, saveSsoProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'MICROSOFT'} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoProvidersApi
     */
    public ssoProvider(provider: 'MICROSOFT', options?: AxiosRequestConfig) {
        return SsoProvidersApiFp(this.configuration).ssoProvider(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoProvidersApi
     */
    public ssoProviders(options?: AxiosRequestConfig) {
        return SsoProvidersApiFp(this.configuration).ssoProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'MICROSOFT'} provider 
     * @param {SaveSsoProvider} saveSsoProvider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoProvidersApi
     */
    public updateSsoProvider(provider: 'MICROSOFT', saveSsoProvider: SaveSsoProvider, options?: AxiosRequestConfig) {
        return SsoProvidersApiFp(this.configuration).updateSsoProvider(provider, saveSsoProvider, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantSettingsApi - axios parameter creator
 * @export
 */
export const TenantSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketSettings: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('ticketSettings', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/settings/tickets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {TicketSettings} ticketSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketSettings: async (tenantId: string, ticketSettings: TicketSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateTicketSettings', 'tenantId', tenantId)
            // verify required parameter 'ticketSettings' is not null or undefined
            assertParamExists('updateTicketSettings', 'ticketSettings', ticketSettings)
            const localVarPath = `/tenants/{tenantId}/settings/tickets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantSettingsApi - functional programming interface
 * @export
 */
export const TenantSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketSettings(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketSettings(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {TicketSettings} ticketSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketSettings(tenantId: string, ticketSettings: TicketSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketSettings(tenantId, ticketSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantSettingsApi - factory interface
 * @export
 */
export const TenantSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketSettings(tenantId: string, options?: any): AxiosPromise<TicketSettings> {
            return localVarFp.ticketSettings(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {TicketSettings} ticketSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketSettings(tenantId: string, ticketSettings: TicketSettings, options?: any): AxiosPromise<void> {
            return localVarFp.updateTicketSettings(tenantId, ticketSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantSettingsApi - object-oriented interface
 * @export
 * @class TenantSettingsApi
 * @extends {BaseAPI}
 */
export class TenantSettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantSettingsApi
     */
    public ticketSettings(tenantId: string, options?: AxiosRequestConfig) {
        return TenantSettingsApiFp(this.configuration).ticketSettings(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {TicketSettings} ticketSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantSettingsApi
     */
    public updateTicketSettings(tenantId: string, ticketSettings: TicketSettings, options?: AxiosRequestConfig) {
        return TenantSettingsApiFp(this.configuration).updateTicketSettings(tenantId, ticketSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveTicket', 'id', id)
            const localVarPath = `/tickets/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('declineTicket', 'id', id)
            const localVarPath = `/tickets/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTicket', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSyncTickets: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('forceSyncTickets', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/tickets/force_sync`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticket', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tickets: async (projectId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('tickets', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/tickets`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declineTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceSyncTickets(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceSyncTickets(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tickets(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tickets(projectId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTicket(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineTicket(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.declineTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSyncTickets(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.forceSyncTickets(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticket(id: string, options?: any): AxiosPromise<Ticket> {
            return localVarFp.ticket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tickets(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoTicket> {
            return localVarFp.tickets(projectId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public approveTicket(id: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).approveTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public declineTicket(id: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).declineTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public deleteTicket(id: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).deleteTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public forceSyncTickets(projectId: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).forceSyncTickets(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public ticket(id: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).ticket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public tickets(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).tickets(projectId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveUser} saveUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (saveUser: SaveUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveUser' is not null or undefined
            assertParamExists('createUser', 'saveUser', saveUser)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('user', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveUser} saveUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(saveUser: SaveUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(saveUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async users(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.users(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveUser} saveUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(saveUser: SaveUser, options?: any): AxiosPromise<void> {
            return localVarFp.createUser(saveUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any): AxiosPromise<User> {
            return localVarFp.me(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.user(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoUser> {
            return localVarFp.users(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public approveUser(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).approveUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveUser} saveUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(saveUser: SaveUser, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(saveUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public me(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public user(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).user(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public users(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).users(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViewsApi - axios parameter creator
 * @export
 */
export const ViewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {SaveView} saveView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addView: async (projectId: string, saveView: SaveView, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addView', 'projectId', projectId)
            // verify required parameter 'saveView' is not null or undefined
            assertParamExists('addView', 'saveView', saveView)
            const localVarPath = `/projects/{projectId}/views`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveView, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteView: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteView', 'id', id)
            const localVarPath = `/views/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveView} saveView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatedView: async (id: string, saveView: SaveView, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatedView', 'id', id)
            // verify required parameter 'saveView' is not null or undefined
            assertParamExists('updatedView', 'saveView', saveView)
            const localVarPath = `/views/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveView, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('view', 'id', id)
            const localVarPath = `/views/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        views: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('views', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/views`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViewsApi - functional programming interface
 * @export
 */
export const ViewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {SaveView} saveView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addView(projectId: string, saveView: SaveView, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addView(projectId, saveView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteView(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteView(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveView} saveView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatedView(id: string, saveView: SaveView, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatedView(id, saveView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async view(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<View>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.view(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async views(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<View>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.views(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViewsApi - factory interface
 * @export
 */
export const ViewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViewsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {SaveView} saveView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addView(projectId: string, saveView: SaveView, options?: any): AxiosPromise<void> {
            return localVarFp.addView(projectId, saveView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteView(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteView(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveView} saveView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatedView(id: string, saveView: SaveView, options?: any): AxiosPromise<void> {
            return localVarFp.updatedView(id, saveView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view(id: string, options?: any): AxiosPromise<View> {
            return localVarFp.view(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        views(projectId: string, options?: any): AxiosPromise<Array<View>> {
            return localVarFp.views(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViewsApi - object-oriented interface
 * @export
 * @class ViewsApi
 * @extends {BaseAPI}
 */
export class ViewsApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {SaveView} saveView 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewsApi
     */
    public addView(projectId: string, saveView: SaveView, options?: AxiosRequestConfig) {
        return ViewsApiFp(this.configuration).addView(projectId, saveView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewsApi
     */
    public deleteView(id: string, options?: AxiosRequestConfig) {
        return ViewsApiFp(this.configuration).deleteView(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveView} saveView 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewsApi
     */
    public updatedView(id: string, saveView: SaveView, options?: AxiosRequestConfig) {
        return ViewsApiFp(this.configuration).updatedView(id, saveView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewsApi
     */
    public view(id: string, options?: AxiosRequestConfig) {
        return ViewsApiFp(this.configuration).view(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViewsApi
     */
    public views(projectId: string, options?: AxiosRequestConfig) {
        return ViewsApiFp(this.configuration).views(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowsApi - axios parameter creator
 * @export
 */
export const WorkflowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {SaveWorkflow} saveWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow: async (projectId: string, saveWorkflow: SaveWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createWorkflow', 'projectId', projectId)
            // verify required parameter 'saveWorkflow' is not null or undefined
            assertParamExists('createWorkflow', 'saveWorkflow', saveWorkflow)
            const localVarPath = `/projects/{projectId}/workflows`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkflow', 'id', id)
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflows: async (projectId: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listWorkflows', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/workflows`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runWorkflow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runWorkflow', 'id', id)
            const localVarPath = `/workflows/{id}/runs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveWorkflow} saveWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow: async (id: string, saveWorkflow: SaveWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkflow', 'id', id)
            // verify required parameter 'saveWorkflow' is not null or undefined
            assertParamExists('updateWorkflow', 'saveWorkflow', saveWorkflow)
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflow', 'id', id)
            const localVarPath = `/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowRunLogs: async (id: string, runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflowRunLogs', 'id', id)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('workflowRunLogs', 'runId', runId)
            const localVarPath = `/workflows/{id}/runs/{runId}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowRuns: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workflowRuns', 'id', id)
            const localVarPath = `/workflows/{id}/runs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "api_key", configuration)

            // authentication session_id required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowsApi - functional programming interface
 * @export
 */
export const WorkflowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {SaveWorkflow} saveWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflow(projectId: string, saveWorkflow: SaveWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflow(projectId, saveWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkflows(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDtoWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkflows(projectId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runWorkflow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runWorkflow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveWorkflow} saveWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflow(id: string, saveWorkflow: SaveWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflow(id, saveWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowRunLogs(id: string, runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowRunLogs(id, runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowRuns(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowRuns(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowsApi - factory interface
 * @export
 */
export const WorkflowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {SaveWorkflow} saveWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(projectId: string, saveWorkflow: SaveWorkflow, options?: any): AxiosPromise<void> {
            return localVarFp.createWorkflow(projectId, saveWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflows(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDtoWorkflow> {
            return localVarFp.listWorkflows(projectId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runWorkflow(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runWorkflow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveWorkflow} saveWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(id: string, saveWorkflow: SaveWorkflow, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkflow(id, saveWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflow(id: string, options?: any): AxiosPromise<Workflow> {
            return localVarFp.workflow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowRunLogs(id: string, runId: string, options?: any): AxiosPromise<void> {
            return localVarFp.workflowRunLogs(id, runId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowRuns(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.workflowRuns(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowsApi - object-oriented interface
 * @export
 * @class WorkflowsApi
 * @extends {BaseAPI}
 */
export class WorkflowsApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {SaveWorkflow} saveWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public createWorkflow(projectId: string, saveWorkflow: SaveWorkflow, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).createWorkflow(projectId, saveWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public deleteWorkflow(id: string, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).deleteWorkflow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public listWorkflows(projectId: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).listWorkflows(projectId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public runWorkflow(id: string, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).runWorkflow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveWorkflow} saveWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public updateWorkflow(id: string, saveWorkflow: SaveWorkflow, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).updateWorkflow(id, saveWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public workflow(id: string, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).workflow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} runId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public workflowRunLogs(id: string, runId: string, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).workflowRunLogs(id, runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsApi
     */
    public workflowRuns(id: string, options?: AxiosRequestConfig) {
        return WorkflowsApiFp(this.configuration).workflowRuns(id, options).then((request) => request(this.axios, this.basePath));
    }
}


