import { StepContentProps } from "@/components/form/MultiStepForm"
import FormSelect from "@/components/form/inputs/FormSelect"
import FormTextInput from "@/components/form/inputs/FormTextInput"
import { MapperDot } from "@/components/mapper/MapperDot"
import { MapperProvider } from "@/components/mapper/MapperProvider"
import { EdgeMapping, SaveMapping, SchemaGraph, SchemaRelationship } from "@/libs/client"
import { sortBy } from "@/libs/utils/sort"
import { ActionIcon, Button, Group, Popover, SimpleGrid, Stack } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo } from "react"
import { FaPlus, FaTimes } from "react-icons/fa"
import { transformMappedType } from "../../utils/mapping.utils"
import classes from './MappingFieldsForm.module.css';

type MappingFieldsFormProps = {
    schema: SchemaGraph
    saveMapping: SaveMapping
} & StepContentProps


function MappingSection({ children, style }: { children: ReactNode, style?: CSSProperties}) {
    return <div style={{...style, width: '100%'}}>
        <Stack gap={3}>
           {children} 
        </Stack>
    </div>
}

export default function MappingFieldsForm({ form, saveMapping, schema } : MappingFieldsFormProps) {
    return <form>
        <MapperProvider
            mapperType={form.values.elementType === 'NODE' ? "manyToOne" : 'oneToOne'}
            values={form.values.rawMappings || []}
            onValues={(vs) => {
                form.setFieldValue('rawMappings', vs)
            }}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15}}>
            <Group gap={4}>
                <div className={classes.entityLabel}>mapping</div>
                <div>{transformMappedType(saveMapping)}</div>
            </Group>
            </div>
            <SimpleGrid cols={3} style={{marginBottom: 15, marginTop: 15}}>
                <div className={classes.label}>File Fields</div>
                <div></div>
                <div className={classes.label}>Schema Fields</div>
            </SimpleGrid>
            <SimpleGrid cols={3} style={{ alignItems: 'flex-start'}}>
                <MappingLhs form={form}></MappingLhs>
                <div></div>
                <MappingRhs form={form} schema={schema}></MappingRhs>
            </SimpleGrid>
        </MapperProvider>
    </form>
}


type MappingLhsProps = {
} & StepContentProps

function MappingLhs({ form } : MappingLhsProps) {
    const setFileFields = useCallback((ff: string[]) => {
        form.setFieldValue('fileFields', ff)
    }, [])
    useEffect(() => {
        if (!form.values.fileFields?.length) {
            setFileFields([''])
        }
    }, [])
    return <div style={{display: 'flex', justifyContent: 'center'}}>
        <MappingSection>
            { form.values.fileFields.map((ff: string, idx: number) => <Group key={idx}>
                {form.values.fileFields.length > 1 && <ActionIcon variant="subtle" color="red" size="xs"
                    onClick={() => {
                        const newFileFields = form.values.fileFields.filter((_: any, index: number) => index !== idx)
                        form.setFieldValue('fileFields', newFileFields);
                        const newRawMappings = form.values.rawMappings
                            .filter((rm: string[]) => !rm[0].endsWith(`:${idx}`))
                            .sort(sortBy((e: string[]) => {
                                const s = e[0].split(':')
                                return Number(s[s.length - 1])
                            })).map((rm: string[]) => {
                                const oldStart = Number.parseInt(rm[0].split(":")[1])
                                return [`start:${oldStart < idx ? oldStart : oldStart - 1}`, rm[1]]
                            })
                        form.setFieldValue('rawMappings', newRawMappings)
                    }}
                ><FaTimes></FaTimes></ActionIcon> }
                <div style={{flex: 1, paddingLeft: 20}}>
                    <FormTextInput placeholder="Field name" 
                        value={ff}
                        onInput={e => {
                            const copy = [...form.values.fileFields]
                            copy[idx] = e.currentTarget.value
                            setFileFields(copy)
                        }}
                        asField></FormTextInput>
                </div>
                <MapperDot
                    value={`start:${idx}`}
                    type="start"
                ></MapperDot>
            </Group>) }
            <Group gap={3} style={{marginTop: 10}}>
                <Button size="xs" variant="subtle" leftSection={<FaPlus></FaPlus>}
                onClick={() => setFileFields([...form.values.fileFields, ''])}
                >Add File Field</Button>
            </Group>
        </MappingSection>
    </div>
}

type MappingRhsProps = {
    schema: SchemaGraph
} & StepContentProps

function MappingRhs({ schema, form } : MappingRhsProps) {
    return <div>
        { form.values.elementType === 'NODE' ? 
            <MappingNodeRhs schema={schema} form={form}></MappingNodeRhs> : <MappingEdgeRhs schema={schema} form={form}></MappingEdgeRhs>}
    </div>

}

function MappingNodeRhs({ schema, form } : MappingRhsProps) {
    const entityFields = useMemo(() => {
        return Object.keys(schema.entities[form.values.mappedType].fields || {}).sort()
    }, [])
    return <div style={{display: 'flex', justifyContent: 'center'}}>
        <MappingSection style={{justifySelf: 'flex-start'}}>
            <Group>
                <MapperDot
                    value="end:field:oid"
                    type="end"
                ></MapperDot>
                <div style={{flex: 1}}>
                    <FormTextInput value="Object ID" readOnly asField></FormTextInput>
                </div>
            </Group>
            {
                entityFields.map(ef => <Group key={`end:field:${ef}`}>
                    <MapperDot
                        value={`end:field:${ef}`}
                        type="end"
                    ></MapperDot>
                    <div style={{flex: 1}}>
                        <FormTextInput value={ef} readOnly asField></FormTextInput>
                    </div>
                </Group>)
            }
            <NodeEdgeMappingsBlock form={form} schema={schema}></NodeEdgeMappingsBlock>
            <Group gap={3} style={{marginTop: 20}}>
                <div style={{flex: 1}}>
                    <AddEdgeButton type={form.values.mappedType} schema={schema} onAdd={e => form.setFieldValue('edgeFields', [...form.values.edgeFields, e])}></AddEdgeButton>
                </div>
            </Group>
        </MappingSection>
    </div>

}

type EdgeMappingsBlockProps =  {
    schema: SchemaGraph
} & StepContentProps


function NodeEdgeMappingsBlock({ form, schema }: EdgeMappingsBlockProps) {
    return <>
    <div className={classes.label} style={{marginTop: 15, marginBottom: 10}}>Edge Mappings</div>
        {
            form.values.edgeFields.map((me: EdgeMapping, idx: number) => <Group>
                <MapperDot
                    value={`end:edge:${idx}`}
                    type="end"
                ></MapperDot>
                <Stack gap={0} style={{flex: 1, fontSize: '0.9rem'}}>
                    { me.sourceSelector ? 
                        <>
                            <div> {schema.relationships[me.relationshipId!]?.name} <i>&larr;</i> {schema.relationships[me.relationshipId!]?.source}</div> 
                            <div className={classes.detail}>{me.sourceSelector}</div>
                        </> :
                        <>
                            <div> {schema.relationships[me.relationshipId!]?.name} <i>&rarr;</i> {schema.relationships[me.relationshipId!]?.target}</div>
                            <div className={classes.detail}>{me.targetSelector}</div>
                        </>}
                </Stack>
                <ActionIcon variant="subtle" color="red" size="xs"
                    onClick={() => {
                        form.setFieldValue('edgeFields', form.values.edgeFields.filter((_: any, index: number) => index !== idx));
                        const newEdgeMappings = form.values.rawMappings
                            .filter((rm: string[]) => rm[1].startsWith(`end:edge:`))
                            .filter((rm: string[]) => rm[1] !== `end:edge:${idx}`)
                            .sort(sortBy((e: string[]) => {
                                const s = e[1].split(':')
                                return Number(s[s.length - 1])
                            })).map((rm: string[], nrmIdx: number) => [rm[0], `end:edge:${nrmIdx}`])
                        const newFieldMappings = form.values.rawMappings
                            .filter((rm: string[]) => !rm[1].startsWith(`end:edge:`))
                        form.setFieldValue('rawMappings', newFieldMappings.concat(newEdgeMappings))
                    }}
                    style={{alignSelf: 'flex-start', marginTop: 3}}
                ><FaTimes></FaTimes></ActionIcon>
            </Group>)
        }
    </>
}
function MappingEdgeRhs({ schema, form } : MappingRhsProps) {
    return  <div style={{display: 'flex', justifyContent: 'center'}}>
        <MappingSection style={{justifySelf: 'flex-start'}}>
            <div className={classes.label} style={{marginTop: 15}}>Source Selector</div>
            <div className={classes.label} style={{marginTop: 4, marginBottom: 10}}>({form.values.source})</div>
            <Group>
                <MapperDot
                    value={`end:source`}
                    type="end"
                ></MapperDot>
                <FormSelect value={form.values.sourceSelector} onChange={e => { 
                    form.setFieldValue('sourceSelector', e || '') 
                }} data={[
                    {value: 'oid', label: 'Object ID'},
                    ...Object.values(schema.entities[form.values.source].fields || {}).map(f => ({
                        value: f.name || '',
                        label: f.name || '',
                    }))
                ]}></FormSelect>
            </Group>
            <div className={classes.label} style={{marginTop: 15}}>Target Selector</div>
            <div className={classes.label} style={{marginTop: 4, marginBottom: 10}}>({form.values.target})</div>
            <Group>
                <MapperDot
                    value={`end:target`}
                    type="end"
                ></MapperDot>
                <FormSelect value={form.values.targetSelector} onChange={e => { 
                    form.setFieldValue('targetSelector', e || '')
                }} data={[
                    {value: 'oid', label: 'Object ID'},
                    ...Object.values(schema.entities[form.values.target].fields || {}).map(f => ({
                        value: f.name || '',
                        label: f.name || '',
                    }))
                ]}></FormSelect>
            </Group>
        </MappingSection>
    </div>
}

type AddEdgeButton = {
    type: string
    schema: SchemaGraph
    onAdd: (e: EdgeMapping) => void
}

function AddEdgeButton({ onAdd, type, schema } : AddEdgeButton) {
    const edgeForm = useForm({
        initialValues: {
            direction: 'In',
            otherType: '',

            relationshipId: '',
            sourceSelector: '',
            targetSelector: '',
        },
        validate: {
            relationshipId: r => !r ? 'Error' : undefined,
            sourceSelector: (f, vs) => !f && vs.direction === 'In' ? 'Error' : undefined,
            targetSelector: (f, vs) => !f && vs.direction === 'Out' ? 'Error' : undefined,
        }
    })
    const [opened, { close, open }] = useDisclosure(false)
    const targetNode = useMemo(() => Object.values(schema.entities)
        .find(e => e.type === edgeForm.values.otherType), [edgeForm.values])
    const relationshipFilter = (r: SchemaRelationship) => {
        if (edgeForm.values.direction === 'In') {
            return r.target === type && r.source === edgeForm.values.otherType
        }
        return r.source === type && r.target === edgeForm.values.otherType
    }
    const hasRelationshipsMap = useMemo(() => {
        const t = Object.entries(schema.relationships)
            .reduce((acc, cur) => {
                if (edgeForm.values.direction === 'In' 
                    && cur[1].target === type) {
                    acc[cur[1].source!] = true
                } else if (edgeForm.values.direction === 'Out' 
                    && cur[1].source === type) {
                    acc[cur[1].target!] = true  
                }
                return acc;
            }, {} as {[key: string]: boolean})
            console.log(t)
        return t;
    }, [schema.relationships, edgeForm.values.direction])
    useEffect(() => {
        edgeForm.setFieldValue('otherType', '')
    }, [edgeForm.values.direction])
    useEffect(() => {
        edgeForm.setFieldValue('relationshipId', '')
    }, [edgeForm.values.otherType])
    useEffect(() => {
        edgeForm.setFieldValue('sourceSelector', '')
        edgeForm.setFieldValue('targetSelector', '')
    }, [edgeForm.values.relationshipId])
    return <Popover position="top" withArrow shadow="md" opened={opened} onClose={close} closeOnClickOutside={false}>
        <Popover.Target>
            <Button size="xs" variant="subtle" leftSection={<FaPlus></FaPlus>} onClick={open}>Add Edge Mapping</Button>
        </Popover.Target>
        <Popover.Dropdown>
            <Stack gap={5} >
                <Group align="top">
                    <FormSelect size="xs" label="Direction" data={[
                        'In', 'Out'
                    ]} {...edgeForm.getInputProps('direction')} allowDeselect={false}></FormSelect>
                    <FormSelect size="xs" label={edgeForm.values.direction === 'In' ? "Source" : "Target"} data={
                        Object.values(schema.entities)
                            .filter(e => type !== e.type)
                            .filter(e => hasRelationshipsMap[e.type!])
                            .sort(sortBy(e => e.type))
                            .map(e => ({ value: e.type!, label: e.type! }))
                    } {...edgeForm.getInputProps('otherType')}></FormSelect>
                </Group>
                <Group align="top">
                    <FormSelect size="xs" label="Relationship" data={
                            Object.values(schema.relationships)
                                .filter(relationshipFilter)
                                .map(r => ({ value: r.type!, label: r.name!  }))
                        } {...edgeForm.getInputProps('relationshipId')} allowDeselect={false}></FormSelect>
                    <FormSelect size="xs" label="Selector" data={[
                        'oid',
                        ...Object.values(targetNode?.fields || {})
                            .map(f => ({ value: f.name!, label: f.name! }))
                    ]}  {...edgeForm.getInputProps(edgeForm.values.direction === 'In' ? 'sourceSelector' : 'targetSelector')} allowDeselect={false}></FormSelect>
                </Group>
                <Group gap={0} justify="flex-end">
                    <Button size="xs" color="red" variant="subtle" leftSection={<FaTimes></FaTimes>} onClick={() => {
                        edgeForm.reset()
                        close()
                    }}>Cancel</Button>
                    <Button size="xs" variant="subtle" leftSection={<FaPlus></FaPlus>} onClick={() => {
                        const v = edgeForm.validate()
                        if (v.hasErrors) {
                            return;
                        }
                        onAdd({ ...edgeForm.values })
                        edgeForm.reset()
                        close()
                    }}>Add</Button>
                </Group>
            </Stack>
        </Popover.Dropdown>
    </Popover>
}
