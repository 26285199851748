import { createContext, useContext, useEffect, useState } from 'react'
import { Info, InfoApi, InfoDeploymentTypeEnum } from '../client'

type ContextProps = {
    isSaas(): boolean;
    isOnPremise(): boolean;
    hasValidLicense(): boolean;
    isAiConfigured(): boolean;
    isExperimental(): boolean;
}

const InfoProviderContext = createContext({} as ContextProps)

export function InfoProvider({ children }: { children: JSX.Element | JSX.Element[]}) {
    const [info, setInfo] = useState<Info>()
    const init = async () => {
       const resp = await new InfoApi().info()
       setInfo(resp.data)
    }
    useEffect(() => {
        init()
    }, []);
    return (
        <InfoProviderContext.Provider value={{ 
            isSaas: () => info?.deploymentType === InfoDeploymentTypeEnum.Saas,
            isOnPremise: () => info?.deploymentType === InfoDeploymentTypeEnum.OnPremise,
            isAiConfigured: () => !!info?.aiConfigured,
            isExperimental: () => !!info?.experimental,
            hasValidLicense: () => true,
         }}>
            { info && children }
        </InfoProviderContext.Provider>
    )
}

export const useInfo = () => {
  return useContext(InfoProviderContext)
}